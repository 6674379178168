import { createAPIActions } from '../../lib/redux';

const {
  fetchListDispatcher,
  fetchItemDispatcher,
  createItemDispatcher,
  updateItemDispatcher,
  deleteItemDispatcher,
} = createAPIActions('companies');

export const fetchCompanies = fetchListDispatcher;
export const fetchCompany = fetchItemDispatcher;
export const updateCompany = updateItemDispatcher;
export const deleteCompany = deleteItemDispatcher;
export const createCompany = createItemDispatcher;
