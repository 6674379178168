import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormGrid from '../generic/FormGrid';
import { ACCOUNT_STATUS_READY } from '../../reducers/api/account';
import { resetPassword } from '../../actions/api/account';


class ResetPasswordForm extends Component {
  onSubmit(state) {
    this.props.resetPassword({ ...state, Username: this.props.username, Code: this.props.code });
  }

  render() {
    return (
      <FormGrid
      onSubmit={this.onSubmit.bind(this)}
      fields={[{
                Password: {
                  label: 'password',
                  type: 'password',
                  required: true,
                },
                ConfirmPassword: {
                  label: 'confirm_password',
                  type: 'password',
                  required: true,
                },
      }]}
      status={this.props.status}
      statusReady={ACCOUNT_STATUS_READY}
      messages={this.props.messages}
      />
    );
  }
}

ResetPasswordForm.propTypes = {
  // From Url
  username: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  // Methods
  resetPassword: PropTypes.func.isRequired,
  // State
  status: PropTypes.string,
  messages: PropTypes.array,
};

const mapStateToProps = state => ({
  status: state.api.account.status,
  messages: state.api.account.messages,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  resetPassword,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
