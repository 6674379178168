import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { Popup, Button } from 'semantic-ui-react';

import DrawingCanvas, { COMMENT_RADIUS } from './DrawingCanvas';
import { updateViewer, zoomTo } from '../../../actions/ui/viewer';
import { fetchDrawings } from '../../../actions/api/drawings';
import Simulator from '../../../lib/Simulator';
import { getStartPoint, getEndPoint, CommentPermissions } from '../../../lib/helpers';

const ITEM_TYPES = {
  COMMENT: 'COMMENT',
  TAG: 'TAG',
  LINE: 'LINE',
  NODE: 'NODE',
};
const RECTANGLE_AREA_OFFSET = 5;
const RECTANGLE_OFFSET = 50;

class DrawingViewBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedItem: null,
      graphLines: (this.props.drawingVersion || {}).Lines || [],
      graphNodes: (this.props.drawingVersion || {}).Nodes || [],
      isHover: false,
    };
  }

  static isItemClicked(item, clickPoint, canvasMatrix, offset = RECTANGLE_AREA_OFFSET) {
    const radius = COMMENT_RADIUS / canvasMatrix[0];
    if ((item.Points || []).length > 0) {
      return item.Points.reduce(
        (acc, point) =>
          acc || (
            clickPoint.x >= (point.x - (radius * 2)) &&
            clickPoint.x <= (point.x + (radius * 2)) &&
            clickPoint.y >= (point.y - (radius * 2)) &&
            clickPoint.y <= (point.y + (radius * 2)))
        , false,
      );
    }

    const startPointX = Math.min(item.PointX, item.EndPointX || Infinity);
    const startPointY = Math.min(item.PointY, item.EndPointY || Infinity);
    const endPointX = Math.max(item.PointX, item.EndPointX || -Infinity);
    const endPointY = Math.max(item.PointY, item.EndPointY || -Infinity);
    return clickPoint.x >= startPointX - offset &&
        clickPoint.x <= endPointX + offset &&
        clickPoint.y >= startPointY - offset &&
        clickPoint.y <= endPointY + offset;
  }

  static isLineClicked(item, clickPoint, canvasMatrix) {
    const {
      StartPointX,
      StartPointY,
      EndPointX,
      EndPointY,
    } = item;

    const offset = 1 / canvasMatrix[0];

    return Math.abs((
      DrawingViewBox.getDistance(StartPointX, StartPointY, clickPoint.x, clickPoint.y) +
      DrawingViewBox.getDistance(clickPoint.x, clickPoint.y, EndPointX, EndPointY)) -
      DrawingViewBox.getDistance(StartPointX, StartPointY, EndPointX, EndPointY)) <= offset;
  }

  static getDistance(x1, y1, x2, y2) {
    return Math.sqrt(((x1 - x2) ** 2) + ((y1 - y2) ** 2));
  }

  componentDidMount() {
    this.mousePosListener = (e) => {
      this.mousePos = { x: e.clientX, y: e.clientY };
    };
    window.addEventListener('mousemove', this.mousePosListener);
  }

  componentWillUnmount() {
    if (this.mousePosListener) {
      window.removeEventListener('mousemove', this.mousePosListener);
    }
  }

  componentDidUpdate(props) {
    if (this.props.drawingVersion !== props.drawingVersion && this.props.drawingVersion) {
      this.setState({
        graphLines: [...((this.props.drawingVersion || {}).Lines || [])],
        graphNodes: [...((this.props.drawingVersion || {}).Nodes || [])],
      });
    }

    if (
      (this.props.tags.length !== props.tags.length && this.props.tagId) ||
      (this.props.tags.length && props.tagId !== this.props.tagId)
    ) {
      const tag = this.props.tags.find(t => t.Id === this.props.tagId);
      this.zoomToItem(tag);
    }

    if (this.props.graphResetTimestamp !== props.graphResetTimestamp) {
      this.resetGraphState();
    }
  }

  onLineButtonClick(state) {
    const simulator = new Simulator(
      this.state.graphLines,
      this.state.graphNodes,
    );
    const { graphLines } = simulator.simulate(state, this.props.clickedItem);
    this.setState({ graphLines });
    this.props.updateViewer({ clickedItem: undefined });
  }

  static mapWithType(items, type) {
    return (items || []).map(item => ({ ...item, type }));
  }

  async onMapDoubleClick(point, matrix) {
    const comments = DrawingViewBox.mapWithType(
      this.props.showComments && this.props.comments,
      ITEM_TYPES.COMMENT,
    );
    const tags = DrawingViewBox.mapWithType(
      this.props.tags.filter(t => this.getTagType(t)),
      ITEM_TYPES.TAG,
    );
    const lines = DrawingViewBox.mapWithType(this.state.graphLines, ITEM_TYPES.LINE);

    const clickedItem = comments.find(c => DrawingViewBox.isItemClicked(c, point, matrix)) ||
        tags.find(t => DrawingViewBox.isItemClicked(t, point, matrix)) ||
        lines.find(l => DrawingViewBox.isLineClicked(l, point, matrix));

    this.props.updateViewer({ clickedItem });
  }

  // Go to clicked tags's other end
  async onMapClick(point, matrix) {
    const clickedTag = (this.props.tags || []).find(tag => (
      DrawingViewBox.isItemClicked(
        tag,
        point,
        matrix,
        Math.min(Math.abs(tag.EndPointX - tag.PointX), Math.abs(tag.EndPointY - tag.PointY)),
      )));

    if (!clickedTag) {
      return;
    }

    if (this.getTagType(clickedTag)) {
      const link = await this.getLinkedDrawing(clickedTag);
      if (link) {
        this.props.goto(`/view/${link.Id}/${link.ActiveVersionId}`);
      }

      this.zoomToItem(clickedTag, true);
      return;
    }

    // Sarch other link with the same name
    const internalLink = this.props.tags
      .find(t => t.Name === clickedTag.Name && t.Id !== clickedTag.Id);

    if (internalLink) {
      this.zoomToItem(internalLink, true);
    }
  }

  onItemHover(point, matrix) {
    const hoveredItem = (this.props.tags || []).find(tag => (
      DrawingViewBox.isItemClicked(
        tag,
        point,
        matrix,
        Math.min(Math.abs(tag.EndPointX - tag.PointX), Math.abs(tag.EndPointY - tag.PointY)),
      )));
    if (hoveredItem && this.getTagType(hoveredItem)) {
      return true;
    }
    return false;
  }

  onDrawingMapClicked(point) {
    if (this.props.addingCommentDot) {
      const commentDot = { startPoint: point };
      this.props.updateViewer({ commentDot });
    }
    const addingItem = { ...this.props.addingItem };
    addingItem.points = [...addingItem.points, point];
    this.props.updateViewer({ addingItem });
  }

  getLinkedDrawing(clickedItem) {
    return this.props.fetchDrawings({
      Filter: clickedItem.Name,
    }).then(res => res.result.Items && res.result.Items[0]);
  }

  resetGraphState() {
    const graphLines = this.state.graphLines.map(line => ({ ...line, state: undefined }));
    this.setState({ graphLines });
    this.props.updateViewer({ clickedItem: undefined });
  }


  getTagType(tag) {
    return ((this.props.drawing || {}).TagTypes || [])
      .find(type => tag.Name && tag.Name.trim().match(new RegExp(type.Regex)));
  }

  getCanvasRectangle() {
    return [
      (this.props.drawingVersion || {}).StartPointX || 0,
      (this.props.drawingVersion || {}).StartPointY || 0,
      (this.props.drawingVersion || {}).EndPointX || 0,
      (this.props.drawingVersion || {}).EndPointY || 0,
    ];
  }

  zoomToItem(item, focus = true) {
    if (!item || item.PointX == null || item.PointY == null) {
      return;
    }

    const isComment = item.CreatorId && !item.EndPointX && !item.EndPointY;
    const { x: startPointX, y: startPointY } = getStartPoint(item);
    const { x: endPointX, y: endPointY } = getEndPoint(item);

    this.props.updateViewer({ showComments: !!isComment || this.props.showComments });
    this.props.zoomTo({
      a: startPointX - RECTANGLE_AREA_OFFSET,
      b: startPointY - RECTANGLE_AREA_OFFSET,
      c: endPointX + RECTANGLE_AREA_OFFSET,
      d: endPointY + RECTANGLE_AREA_OFFSET,
      focus,
      offset: RECTANGLE_OFFSET,
    });
  }

  static filterComments(comments, showComments) {
    const { permissions, sharedUsers } = showComments;
    let results = comments;

    if (permissions) {
      results = comments.filter(c => c.Permissions === permissions);
    }
    if (sharedUsers && sharedUsers.length && permissions === CommentPermissions.Shared) {
      results = comments.filter(c => sharedUsers.includes(c.CreatorId)
        && c.Permissions === CommentPermissions.Shared);
    }
    return results;
  }

  renderCanvas() {
    return (
      <DrawingCanvas
        key="canvas"
        ref={(map) => { this.map = map; }}
        lines={this.state.graphLines}
        comments={
          this.props.showComments ?
            DrawingViewBox.filterComments(this.props.comments, this.props.showComments)
            : []
        }
        addingRect={this.props.addingCommentRect}
        addingDot={this.props.addingCommentDot}
        addingFree={this.props.addingCommentFree}
        commentColor={this.props.commentColor}
        hasMergedLayers={(this.props.drawingVersion || {}).HasMergedLayers}
        hasJpgTiles={(this.props.drawingVersion || {}).HasJpgTiles}
        hasSvgTiles={(this.props.drawingVersion || {}).HasSvgTiles}
        drawingScale={(this.props.drawingVersion || {}).Scale || 1}
        drawingId={this.props.drawingId}
        versionNumber={(this.props.drawingVersion || {}).VersionNumber || 0}
        versionId={(this.props.drawingVersion || {}).Id || 0}
        versionName={
          ((this.props.drawingVersion || {}).FileName || '')
            .replace(/\.[a-z]*$/, '')
        }
        versionDate={
          (this.props.drawingVersion || {}).ModifiedAt
            ? new Date((this.props.drawingVersion || {}).ModifiedAt)
            : null
        }
        rectangle={this.getCanvasRectangle()}
        onClick={
          this.props.addingItem.type
            ? this.onDrawingMapClicked.bind(this)
            : this.onMapClick.bind(this)
        }
        onDoubleClick={this.onMapDoubleClick.bind(this)}
        onItemHover={this.onItemHover.bind(this)}
        backgroundColor={this.props.backgroundColor}
      />
    );
  }

  renderSignalSelector() {
    if (!this.props.clickedItem || this.props.clickedItem.type !== ITEM_TYPES.LINE) {
      return null;
    }

    return (
      <Popup
        key="selector"
        className="signal-selector"
        open={!!this.props.clickedItem}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        style={{
          position: 'absolute',
          left: (this.mousePos ? this.mousePos.x : 0),
          top: (this.mousePos ? this.mousePos.y : 0),
          width: 140,
      }}>
        <div ref={this.setWrapperRef}>
          <Button color='green' content='1' onClick={this.onLineButtonClick.bind(this, 1)}/>
          <Button color='red' content='0' onClick={this.onLineButtonClick.bind(this, 0)}/>
          <Button color='grey' content='-' onClick={this.onLineButtonClick.bind(this, undefined)}/>
        </div>
      </Popup>
    );
  }

  render() {
    return [
      this.renderCanvas(),
      this.renderSignalSelector(),
    ];
  }
}

DrawingViewBox.propTypes = {
  drawingVersionId: PropTypes.number,
  drawingId: PropTypes.number,
  drawing: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  ...state.ui.viewer,
  drawingVersion: state.api.drawingversions.index[props.drawingVersionId],
  comments: state.api.comments.list.items.map(id => (state.api.comments.index[id])),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateViewer,
  zoomTo,
  fetchDrawings,
  goto: page => push(page),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DrawingViewBox);
