import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGrid from '../../generic/FormGrid';

class GroupInfoPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props.group };
  }

  renderForm() {
    return (
      <FormGrid
        item={this.props.group}
        onSubmit={this.props.updateGroup}
        messages={this.props.messages}
        status={this.props.status}
        statusReady={this.props.statusReady}
        fields={[
          {
            Name: { label: 'name', type: 'text', required: true },
            Description: { label: 'description', type: 'textarea' },
          },
        ]}
      />
    );
  }

  render() {
    return (
      <div className="GroupInfoPanel">
        {this.renderForm()}
      </div>
    );
  }
}

GroupInfoPanel.propTypes = {
  updateGroup: PropTypes.func.isRequired,
  group: PropTypes.object,
  status: PropTypes.string,
  statusReady: PropTypes.string.isRequired,
  messages: PropTypes.array,
};

export default GroupInfoPanel;
