import { createAPIActions } from '../../lib/redux';

const {
  fetchListDispatcher,
  fetchItemDispatcher,
  createItemDispatcher,
  updateItemDispatcher,
  deleteItemDispatcher,
} = createAPIActions('drawingSystems');

export const fetchSystems = fetchListDispatcher;
export const fetchSystem = fetchItemDispatcher;
export const updateSystem = updateItemDispatcher;
export const createSystem = createItemDispatcher;
export const deleteSystem = deleteItemDispatcher;
