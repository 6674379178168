import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Modal } from 'semantic-ui-react';
import DataTable from '../../generic/DataTable';
import DataTableActions from '../../generic/DataTableActions';
import MutableInput from '../../generic/MutableInput';
import MessageList from '../../generic/MessageList';
import FormGrid from '../../generic/FormGrid';
import { DRAWINGS_STATUS_READY } from '../../../reducers/api/drawings';
import { updateDrawing } from '../../../actions/api/drawings';

class DrawingTagTypes extends Component {
  // Component Methods --------------------
  constructor(props) {
    super(props);

    this.state = {
      newItem: null,
    };
  }

  // Implementation --------------------
  getItems() {
    return this.props.drawing.TagTypes || [];
  }

  // Ui Callbacks --------------------
  addNewItem() {
    if (this.state.newItem) return;
    this.setState({ newItem: {} });
  }

  createTagType(tagType) {
    this.props.drawing.TagTypes = (this.props.drawing.TagTypes || []).concat(tagType);
    this.props.updateDrawing(this.props.drawing).then((response) => {
      if (!response || !response.result || !response.result.Success) {
        this.props.drawing.TagTypes.splice(-1, 1);
      }
      this.setState({ newItem: null });
    });
  }

  onItemChanged(tagType, newItem) {
    Object.assign(tagType, newItem);
    this.props.updateDrawing(this.props.drawing);
  }

  deleteItem(item) {
    const index = this.props.drawing.TagTypes.indexOf(item);
    this.props.drawing.TagTypes.splice(index, 1);

    this.props.updateDrawing(this.props.drawing);
  }

  // Ui --------------------
  render() {
    return (
      <div className="DrawingTagTypes">
        <DataTable
          items={this.getItems()}
          totalItems={this.getItems().size}
          pageSize={this.getItems().size}
          currentPage={1}
          disablePageSizeSelection={true}
          status={this.props.status}
          statusReady={DRAWINGS_STATUS_READY}
          idField="Id"
          messages={[]}
          addNewItem={this.addNewItem.bind(this)}
          fields={{
            name: zl => (
              <MutableInput item={zl}
                property={'Name'}
                onChange={this.onItemChanged.bind(this, zl)}
              />
            ),
            regex: zl => (
              <MutableInput item={zl} property={'Regex'}
                onChange={this.onItemChanged.bind(this, zl)}
              />
            ),
            url: zl => (
              <MutableInput item={zl} property={'Url'}
                onChange={this.onItemChanged.bind(this, zl)}
              />
            ),
            title: zl => (
              <MutableInput item={zl} property={'UrlTitle'}
                onChange={this.onItemChanged.bind(this, zl)}
              />
            ),
            actions: zl => (
              <DataTableActions
                item={zl}
                onDelete={this.deleteItem.bind(this)}
                deletionLabel="delete"
                deletionMessage="confirm_tag_type_deletion"
              />
            ),
          }}
        />

        {this.renderAddModal()}
      </div>
    );
  }

  renderAddModal() {
    return (
    <Modal
    open={!!this.state.newItem}
    onClose={() => this.setState({ newItem: null })}
    closeOnDimmerClick={false}
    closeIcon={true}
  >
    <Modal.Header>
      {t('add_item')} {t('tag_type')}
    </Modal.Header>
    <Modal.Content>
      <MessageList messages={this.props.messages} />
      <FormGrid
        item={this.state.newItem}
        onSubmit={this.createTagType.bind(this)}
        onCancel={() => this.setState({ newItem: null })}
        status={this.props.status}
        statusReady={DRAWINGS_STATUS_READY}
        fields={[
          {
            Name: {
              label: 'name', type: 'text', required: true,
            },
            Regex: {
              label: 'regex', type: 'text', required: true,
            },
            Url: {
              label: 'url', type: 'text', required: true,
            },
          },
        ]}
        />
      </Modal.Content>
    </Modal>
    );
  }
}

DrawingTagTypes.propTypes = {
  drawing: PropTypes.object.isRequired,
  // State Props
  status: PropTypes.string.isRequired,
  messages: PropTypes.array,
};

const mapStateToProps = state => ({
  status: state.api.drawings.status,
  messages: state.api.drawings.messages,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateDrawing,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DrawingTagTypes);
