import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Grid, Segment } from 'semantic-ui-react';

import DataTable from '../../generic/DataTable';


class ViewerInfo extends Component {
  // Ui --------------------------
  render() {
    return (
      <Segment vertical className="ViewerInfo">
        <h2>{t('info')}</h2>
        {this.renderDrawingInfo()}
      </Segment>
    );
  }

  renderDrawingInfo() {
    if (!this.props.drawingVersion) {
      return null;
    }

    return (
      <Grid columns={1} className="ViewerInfo">
        <Grid.Row>
          <Grid.Column>
            <label style={{ fontWeight: 'bold' }}>{t('modified_on')}</label>
            :<label className="label">
              {
                DataTable.format(this.props.drawingVersion.ModifiedAt
                  || this.props.drawingVersion.CreatedAt)
              }
            </label>
          </Grid.Column>
          <Grid.Column>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label style={{ fontWeight: 'bold' }}>{t('version')}</label>
            :<label className="label">{this.props.drawingVersion.VersionNumber}</label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <label style={{ fontWeight: 'bold' }}>{t('support')}</label>
            :<a className="label" href={`mailto:${this.props.config.Contact}`}>{t('click_here')}</a>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column>
          <label style={{ fontWeight: 'bold' }}>{t('title')}</label>
          :<label className="label">{this.props.drawing.Name}</label>
        </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column dangerouslySetInnerHTML={{ __html: this.props.drawing.Description }} />
        </Grid.Row>
      </Grid>
    );
  }
}

ViewerInfo.propTypes = {
  drawingVersion: PropTypes.object.isRequired,
  drawing: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  config: (state.api.configuration.index || {}).Information || {},
});

export default connect(mapStateToProps)(ViewerInfo);
