import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { t } from "i18next";
import url from "url";
import { Loader } from "semantic-ui-react";
import { updateViewer } from "../../../actions/ui/viewer";
import Cancelable from "../../../lib/Cancelable";
import tilesizes from "./tilesizes.json";
import { ZOOM_STEP as ZOOM_LEVEL_STEP } from "../panels/DrawingZoomLevelPanel";
import configData from "../../../config.json";
export const DBCLICK_DELAY = 300;
export const MAX_ZOOM_LEVEL = 200;
export const ZOOM_STEP = 0.1;
export const AREA_PADDING = 18;
export const COMMENT_COLOR = "rgb(219, 40, 40)";
export const COMMENT_FILL_COLOR = "rgba(219, 40, 40, .3)";
export const COMMENT_RADIUS = 10;
export const FOCUS_COLOR = "#002b5c";
export const FOCUS_FILL_COLOR = "rgba(0, 100, 255, .3)";

const SCREEN_OFFSET = [0, 0];
const TILE_OVERLAP = navigator.userAgent.match(/trident|firefox|ipad|iphone/gi)
  ? 0.175
  : 0.45;
const HOVER_LINK_INTERVAL = 333;

const wait = (ms) => new Cancelable((resolve) => setTimeout(resolve, ms));
const getEvent = (e) => (e.changeTouches || [])[0] || (e.touches || [])[0] || e;
const listToString = (list = []) =>
  list
    .map((l) => l.Id)
    .sort()
    .join(",");
const roundMatrix = ([m0, m1, m2, m3, m4, m5]) => [
  m0,
  m1,
  m2,
  m3,
  Math.round(m4),
  Math.round(m5),
];

class DrawingCanvas extends Component {
  constructor(props) {
    super(props);
    this.active = true;
    this.dragging = false;
    this.mousePosition = {};
    this.minX = 0;
    this.minY = 0;
    this.maxX = window.innerWidth - SCREEN_OFFSET[0];
    this.maxY = window.innerHeight - SCREEN_OFFSET[1];
    this.ctxOpts = {
      alpha: true,
      antialias: false,
      preserveDrawingBuffer: false,
    };
    this.foregroundCanvas = document.createElement("canvas");
    this.foregroundCanvas.width = this.maxX - this.minX;
    this.foregroundCanvas.height = this.maxY - this.minY;
    this.ctx = this.foregroundCanvas.getContext("2d", this.ctxOpts);
    this.ctx.fillStyle = "rgba(0, 0, 0, 0)";
    this.ctx.fillRect(
      0,
      0,
      this.foregroundCanvas.width,
      this.foregroundCanvas.height
    );
    this.backgroundCanvas = document.createElement("canvas");
    this.backgroundCanvas.width = this.maxX - this.minX;
    this.backgroundCanvas.height = this.maxY - this.minY;
    this.backgroundCtx = this.backgroundCanvas.getContext("2d", {
      ...this.ctxOpts,
      alpha: false,
    });
    this.backgroundCtx.fillStyle = "rgba(255, 255, 255, 255)";
    this.backgroundCtx.fillRect(
      0,
      0,
      this.backgroundCanvas.width,
      this.backgroundCanvas.height
    );
    this.infoCanvas = document.createElement("canvas");
    this.infoCanvas.width = this.maxX - this.minX;
    this.infoCanvas.height = this.maxY - this.minY;
    this.infoCtx = this.infoCanvas.getContext("2d", this.ctxOpts);
    this.infoCtx.fillStyle = "rgba(0, 0, 0, 0)";
    this.infoCtx.fillRect(0, 0, this.infoCanvas.width, this.infoCanvas.height);
    this.graphCanvas = document.createElement("canvas");
    this.graphCanvas.width = this.maxX - this.minX;
    this.graphCanvas.height = this.maxY - this.minY;
    this.graphCtx = this.graphCanvas.getContext("2d", this.ctxOpts);
    this.graphCtx.fillStyle = "rgba(0, 0, 0, 0)";
    this.graphCtx.fillRect(
      0,
      0,
      this.graphCanvas.width,
      this.graphCanvas.height
    );
    this.markingCanvas = document.createElement("canvas");
    this.markingCanvas.width = this.maxX - this.minX;
    this.markingCanvas.height = this.maxY - this.minY;
    this.markingCtx = this.markingCanvas.getContext("2d", this.ctxOpts);
    this.markingCtx.fillStyle = "rgba(0, 0, 0, 0)";
    this.markingCtx.fillRect(
      0,
      0,
      this.markingCanvas.width,
      this.markingCanvas.height
    );
    this.drawPromise = new Cancelable(() => false);
    this.state = {
      loading: false,
      matrix: this.getRectangleMatrix(...this.props.rectangle),
      freeComment: [],
      matrices: {},
      hoversLink: false,
    };
    this.previewRectComment.bind(this);
    this.previewFreeComment.bind(this);
  }

  setLoadingState(loading = false) {
    return new Cancelable((resolve) => this.setState({ loading }, resolve));
  }

  setMatrix(matrix) {
    const matrices = this.props.versionId
      ? { ...this.state.matrices, [this.props.versionId]: matrix }
      : this.state.matrices;
    return new Cancelable((resolve) =>
      this.setState({ matrix, matrices }, resolve)
    );
  }

  getPadding() {
    return AREA_PADDING / (this.props.drawingScale * this.props.drawingScale);
  }

  getTileSizes() {
    const { hasMergedLayers, hasJpgTiles, hasSvgTiles } = this.props;
    if (hasMergedLayers || (hasJpgTiles && hasSvgTiles)) {
      return tilesizes.merged;
    }

    if (hasJpgTiles) {
      return tilesizes.merged
        .filter((size) => size.type === "jpg")
        .map((size, idx, sizes) => ({
          ...size,
          zoomFrom: idx === 0 ? 0 : size.zoomFrom,
          zoomTo: idx === sizes.length - 1 ? undefined : size.zoomTo,
        }));
    }

    if (hasSvgTiles) {
      return tilesizes.merged
        .filter((size) => size.type === "svg")
        .map((size, idx, sizes) => ({
          ...size,
          zoomFrom: idx === 0 ? 0 : size.zoomFrom,
          zoomTo: idx === sizes.length - 1 ? undefined : size.zoomTo,
        }));
    }

    return tilesizes.pending;
  }

  getVisibleTiles(matrix, tileMatrix, tileSize = 1) {
    const bBox = this.getVisibleBoundingBox(matrix);
    const tileSizeX = this.props.rectangle[2] / (tileMatrix[0] || 1) / tileSize;
    const tileSizeY = this.props.rectangle[3] / (tileMatrix[1] || 1) / tileSize;

    const tiles = [];
    for (let x = 0; x < (tileMatrix[0] || 1); x += 1) {
      for (let y = 0; y < (tileMatrix[1] || 1); y += 1) {
        const span = {
          minX: tileSizeX * x,
          maxX: tileSizeX * (x + 1),
          minY: tileSizeY * y,
          maxY: tileSizeY * (y + 1),
        };
        const extent = {
          x: Math.min(span.maxX, bBox.maxX) - Math.max(span.minX, bBox.minX),
          y: Math.min(span.maxY, bBox.maxY) - Math.max(span.minY, bBox.minY),
        };
        if (extent.x > 0 && extent.y > 0) {
          tiles.push({ x, y, extent });
        }
      }
    }

    return tiles.sort(
      (a, b) => b.extent.x * b.extent.y - a.extent.x * a.extent.y
    );
  }

  getTileImages(
    matrix,
    {
      zoomFrom,
      zoomTo,
      matrix: tileMatrix = [0, 0],
      size: tileSize = 1,
      type = "png",
    }
  ) {
    if (zoomFrom > matrix[0] || (zoomTo && zoomTo <= matrix[0])) {
      return [];
    }

    const tiles = this.getVisibleTiles(matrix, tileMatrix, tileSize);
    const tileSizeX = this.props.rectangle[2] / (tileMatrix[0] || 1) / tileSize;
    const tileSizeY = this.props.rectangle[3] / (tileMatrix[1] || 1) / tileSize;
    const layers = [{ Name: this.props.versionName }];
    return tiles.reduce(
      (images, tile) => [
        ...images,
        ...layers.map((layer, idx) => ({
          src: `${configData.BackendUrl}/api/drawings/${
            this.props.drawingId
          }/tile?MatrixX=${tileMatrix[0]}&MatrixY=${tileMatrix[1]}&x=${
            tile.x
          }&y=${tile.y}&Version=${this.props.versionNumber}&VersionId=${
            this.props.versionId
          }&Layer=${encodeURIComponent(layer.Name)}&Type=${type}&Date=${
            this.props.versionDate ? this.props.versionDate.getTime() : ""
          }`,
          width: Math.round(tileSizeX),
          height: Math.round(tileSizeY),
          clear: idx === 0,
          clearBackground: idx === layers.length - 1,
          matrix: [
            matrix[0],
            matrix[1],
            matrix[2],
            matrix[3],
            matrix[4] + Math.round(matrix[0] * tile.x * tileSizeX),
            matrix[5] + Math.round(matrix[3] * tile.y * tileSizeY),
          ],
        })),
      ],
      []
    );
  }

  getVisibleBoundingBox(matrix) {
    const ratio = this.getCurrentRatio();
    return {
      minX: -matrix[4] / matrix[0],
      minY: -matrix[5] / matrix[3],
      maxX:
        (this.canvas.getBoundingClientRect().width / ratio.x - matrix[4]) /
        matrix[0],
      maxY:
        (this.canvas.getBoundingClientRect().height / ratio.y - matrix[5]) /
        matrix[0],
    };
  }

  getCurrentRatio() {
    return {
      x: this.canvas.getBoundingClientRect().width / (this.maxX - this.minX),
      y: this.canvas.getBoundingClientRect().height / (this.maxY - this.minY),
    };
  }

  getInitialMatrix() {
    return [1, 0, 0, 1, this.minX, this.minY];
  }

  toSvgCoords(x, y, matrix = this.state.matrix) {
    const dim = this.canvas.getBoundingClientRect();
    const ratio = this.getCurrentRatio();

    // adjust point based on pan
    let xSvg = (x - dim.left) / ratio.x - matrix[4];
    let ySvg = (y - dim.top) / ratio.y - matrix[5];

    // adjust point based on zoom
    xSvg /= matrix[0];
    ySvg /= matrix[0];

    return { x: xSvg, y: ySvg };
  }

  hasCommentsUpdate({ comments }) {
    return listToString(comments) !== listToString(this.props.comments);
  }

  hasDrawingUpdate({ versionId }) {
    return this.props.versionId !== versionId;
  }

  hasGraphUpdate({ lines }) {
    return (lines || []).some(
      (line, idx) => line !== (this.props.lines || [])[idx]
    );
  }

  componentDidMount() {
    this.renderFrame();
    this.updateCanvas(this.state.matrix);
    this.onMouseMoveListener = (e) => {
      this.mousePosition = { x: e.clientX, y: e.clientY };
    };
    window.addEventListener("mousemove", this.onMouseMoveListener);
    this.onMouseMoveInterval = setInterval(
      this.onCheckLinkOver.bind(this),
      HOVER_LINK_INTERVAL
    );
  }

  componentWillUnmount() {
    this.active = false;
    this.drawPromise.cancel();
    window.removeEventListener("mousemove", this.onMouseMoveListener);
    clearInterval(this.onMouseMoveInterval);
  }

  componentDidUpdate(props) {
    if (this.hasCommentsUpdate(props)) {
      this.drawInfo(this.state.matrix);
    }
    if (this.hasGraphUpdate(props)) {
      this.drawGraph(this.state.matrix);
    }
    if (this.hasDrawingUpdate(props)) {
      const matrix =
        this.state.matrices[this.props.versionId] ||
        this.getRectangleMatrix(...this.props.rectangle);
      this.setState({ matrix }, () => {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.infoCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.markingCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.graphCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.updateCanvas(this.state.matrix);
      });
    }
    if (this.props.zoomCords !== props.zoomCords) {
      const zoomTo = this.props.zoomCords;
      this.zoomToRect(
        zoomTo.a,
        zoomTo.b,
        zoomTo.c,
        zoomTo.d,
        zoomTo.focus,
        zoomTo.offset
      );
    }
    if (this.props.zoomIn !== props.zoomIn) this.zoomIn();
    if (this.props.zoomOut !== props.zoomOut) this.zoomOut();
    if (this.props.resetZoom !== props.resetZoom) this.resetZoom();
  }

  onCheckLinkOver() {
    const point = this.toSvgCoords(this.mousePosition.x, this.mousePosition.y);
    const hoversLink = this.props.onItemHover(point, this.state.matrix);
    if (hoversLink !== this.state.hoversLink) {
      this.setState({ hoversLink });
    }
  }

  onDragStart(e) {
    e.preventDefault();
    const ratio = this.getCurrentRatio();

    // Start position of drag event
    const event = getEvent(e);
    this.clientX = event.clientX;
    this.clientY = event.clientY;
    this.startX = this.clientX / ratio.x;
    this.startY = this.clientY / ratio.y;
    this.dragging = true;
    this.dragged = false;

    if (this.props.addingFree) {
      this.markingCtx.beginPath();
      this.markingCtx.moveTo(event.clientX, event.clientY);
    }
  }

  onDragMove(e) {
    e.preventDefault();
    if (!this.dragging) {
      return;
    }

    const event = getEvent(e);

    if (this.props.addingRect) {
      this.previewRectComment(event.clientX, event.clientY);
    } else if (this.props.addingFree) {
      this.previewFreeComment(event.clientX, event.clientY);
    } else {
      // New position of cursor
      const ratio = this.getCurrentRatio();

      const x = event.clientX / ratio.x;
      const y = event.clientY / ratio.y;

      // Position delta
      const dx = x - this.startX;
      const dy = y - this.startY;

      this.startX = x;
      this.startY = y;

      this.dragged = true;
      // Pan using deltas
      this.pan(dx, dy);
    }
  }

  onDragEnd(e) {
    if (this.props.addingRect || this.props.addingDot) {
      const event = getEvent(e);
      const startPoint = this.toSvgCoords(this.startX, this.startY);
      const endPoint = this.toSvgCoords(event.clientX, event.clientY);

      if (this.props.addingRect) {
        this.onCommentDragEnd(startPoint, endPoint, this.props.commentColor);
      }
      setTimeout(
        () =>
          this.markingCtx.clearRect(
            0,
            0,
            this.canvas.width,
            this.canvas.height
          ),
        500
      );
    }
    if (this.props.addingFree) {
      this.onFreeCommentDragEnd(this.state.freeComment);
      setTimeout(() => {
        this.markingCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.setState({ freeComment: [] });
      }, 500);
    }

    e.preventDefault();
    clearTimeout(this.clickTimeout);
    this.dragging = false;

    if (this.props.onClick && !this.dragged) {
      const point = this.toSvgCoords(this.clientX, this.clientY);
      this.clickTimeout = setTimeout(
        () => this.props.onClick(point, this.state.matrix),
        DBCLICK_DELAY
      );
    }

    const tapdiff = Date.now() - (this.dragEndTimestamp || 0);
    if (!this.dragged && tapdiff < DBCLICK_DELAY * 2) {
      clearTimeout(this.clickTimeout);
      this.onDoubleClick(e);
    }

    this.dragEndTimestamp = Date.now();
  }

  onCommentDragEnd(startPoint, endPoint, color) {
    const commentRect = color
      ? { startPoint, endPoint, color }
      : { startPoint, endPoint };
    this.props.updateViewer({ commentRect });
  }

  onFreeCommentDragEnd(freeComment) {
    if (freeComment.length > 1) {
      this.props.updateViewer({ freeComment });
    } else {
      this.onAddItemCancel();
    }
  }

  onTouchStart(e) {
    clearTimeout(this.clickTimeout);
    if (e.touches && e.touches.length > 1) {
      e.preventDefault();
      const ratio = this.getCurrentRatio();
      const x1 = e.touches[0].clientX / ratio.x;
      const x2 = e.touches[1].clientX / ratio.x;
      const y1 = e.touches[0].clientY / ratio.y;
      const y2 = e.touches[1].clientY / ratio.y;
      this.touchDistance = Math.sqrt(
        Math.abs(x1 - x2) ** 2 + Math.abs(y1 - y2) ** 2
      );
      this.dragged = false;
    } else {
      this.onDragStart(e);
    }
  }

  onTouchEnd(e) {
    this.onDragEnd(e);
  }

  onTouchMove(e) {
    if (e.touches && e.touches.length > 1) {
      e.preventDefault();
      const ratio = this.getCurrentRatio();
      const x1 = e.touches[0].clientX / ratio.x;
      const x2 = e.touches[1].clientX / ratio.x;
      const y1 = e.touches[0].clientY / ratio.y;
      const y2 = e.touches[1].clientY / ratio.y;
      const distance = Math.sqrt(
        Math.abs(x1 - x2) ** 2 + Math.abs(y1 - y2) ** 2
      );
      const zoomDif = distance / this.touchDistance;
      this.touchDistance = distance;
      const clientX = Math.min(x1, x2) + distance / 2;
      const clientY = Math.min(y1, y2) + distance / 2;
      this.zoomToPointer(clientX, clientY, zoomDif);
      this.dragged = true;
    } else {
      this.onDragMove(e);
    }
  }

  onWheel(e) {
    const zoomDif = Math.sign(e.deltaY) * ZOOM_STEP;
    const event = getEvent(e);
    this.zoomToPointer(event.clientX, event.clientY, 1 - zoomDif);
  }

  onDoubleClick(e) {
    e.preventDefault();
    if (this.props.onDoubleClick) {
      const event = getEvent(e);
      const clientX = event.clientX || this.clientX;
      const clientY = event.clientY || this.clientY;
      const point = this.toSvgCoords(clientX, clientY);
      this.props.onDoubleClick(point, this.state.matrix);
    }
  }

  zoomToPointer(clientX, clientY, scale) {
    const dim = this.canvas.getBoundingClientRect();
    const ratio = this.getCurrentRatio();
    const point = {
      x: (clientX - dim.left) / ratio.x,
      y: (clientY - dim.top) / ratio.y,
    };

    this.zoom(scale, point);
  }

  // point {x , y}, rect { minPoint , maxPoint }
  static isPointInRectangle(point, rect) {
    return (
      point.x >= rect.minPoint.x &&
      point.x <= rect.maxPoint.x &&
      point.y >= rect.minPoint.y &&
      point.y <= rect.maxPoint.y
    );
  }

  // Svg Navigation

  pan(dx, dy) {
    if (dx === 0 && dy === 0) {
      return;
    }
    const newMatrix = [...this.state.matrix];
    newMatrix[4] += dx;
    newMatrix[5] += dy;
    this.updateCanvas(roundMatrix(newMatrix));
  }

  centerTo(x, y) {
    const newMatrix = this.getInitialMatrix();

    const xMid = (this.maxX - this.minX) / 2;
    const yMid = (this.maxY - this.minY) / 2;

    newMatrix[4] += xMid - x;
    newMatrix[5] += yMid - y;

    const scale = this.state.matrix[0];
    this.zoom(scale, newMatrix);
  }

  zoom(scale, point) {
    const newMatrix = [...this.state.matrix];
    for (let i = 0; i < newMatrix.length; i += 1) {
      newMatrix[i] *= scale; // TODO optimize
    }
    newMatrix[4] += (1 - scale) * point.x;
    newMatrix[5] += (1 - scale) * point.y;

    if (newMatrix[0] <= 0 || newMatrix[3] <= 0) {
      return;
    }
    this.updateCanvas(roundMatrix(newMatrix));
  }

  getRectangleMatrix(xMin, yMin, xMax, yMax) {
    const newMatrix = this.getInitialMatrix();

    const canvasWidth = this.maxX - this.minX;
    const canvasHeight = this.maxY - this.minY;
    const width = xMax - xMin;
    const height = yMax - yMin;

    newMatrix[4] = -xMin + this.getPadding();
    newMatrix[5] = -yMin + this.getPadding();

    const xScaleToFill =
      canvasWidth / (newMatrix[4] + xMax + this.getPadding());
    const yScaleToFill =
      canvasHeight / (newMatrix[5] + yMax + this.getPadding());
    const scale = Math.min(xScaleToFill, yScaleToFill);

    for (let i = 0; i < newMatrix.length; i += 1) {
      newMatrix[i] *= scale;
    }

    newMatrix[4] += (canvasWidth - width * scale) / 2;
    newMatrix[5] += (canvasHeight - height * scale) / 2;

    return newMatrix;
  }

  zoomIn() {
    this.zoom(
      (this.state.matrix[0] + ZOOM_LEVEL_STEP / 100) / this.state.matrix[0],
      {
        x: this.minX + (this.maxX - this.minX) / 2,
        y: this.minY + (this.maxY - this.minY) / 2,
      }
    );
  }

  zoomOut() {
    this.zoom(
      (this.state.matrix[0] - ZOOM_LEVEL_STEP / 100) / this.state.matrix[0],
      {
        x: this.minX + (this.maxX - this.minX) / 2,
        y: this.minY + (this.maxY - this.minY) / 2,
      }
    );
  }

  resetZoom() {
    this.zoomToRect(
      this.props.rectangle[0],
      this.props.rectangle[1],
      this.props.rectangle[2],
      this.props.rectangle[3],
      false
    );
  }

  zoomToRect(x0, y0, x1, y1, focus = true, offset = 0) {
    const xMin = Math.min(x0, x1);
    const yMin = Math.min(y0, y1);
    const xMax = Math.max(x0, x1);
    const yMax = Math.max(y0, y1);
    const newMatrix = this.getRectangleMatrix(
      xMin - offset,
      yMin - offset,
      xMax + offset,
      yMax + offset
    );

    if (focus) {
      this.setState(
        {
          focus: {
            xMin,
            yMin,
            xMax,
            yMax,
          },
        },
        () => this.updateCanvas(roundMatrix(newMatrix))
      );
      return;
    }

    this.updateCanvas(roundMatrix(newMatrix));
  }

  renderFrame() {
    if (!this.active) {
      return;
    }
    requestAnimationFrame(() => {
      if (this.animationCtx) {
        this.animationCtx.drawImage(this.backgroundCanvas, 0, 0);
        this.animationCtx.drawImage(this.foregroundCanvas, 0, 0);
        this.animationCtx.drawImage(this.infoCanvas, 0, 0);
        this.animationCtx.drawImage(this.markingCanvas, 0, 0);
        this.animationCtx.drawImage(this.graphCanvas, 0, 0);
      }
      this.renderFrame();
    });
  }

  // Rendering

  clearCanvas() {
    this.backgroundCtx.fillRect(this.minX, this.minY, this.maxX, this.maxY);
    this.infoCtx.clearRect(this.minX, this.minY, this.maxX, this.maxY);
    this.ctx.clearRect(this.minX, this.minY, this.maxX, this.maxY);
  }

  updateCanvas(matrix) {
    this.drawPromise.cancel();
    this.drawPromise = this.setLoadingState(true)
      .then(() =>
        this.drawBackground(matrix).then(() => this.setMatrix(matrix))
      )
      .then(() => this.drawInfo(matrix))
      .then(() => this.drawGraph(matrix))
      .then(() => wait(192))
      .then(() => this.drawTiles(matrix))
      .then(() => this.setLoadingState(false));
  }

  drawBackground(matrix) {
    const canvasMatrix = this.getInitialMatrix();
    const scale = matrix[0] / this.state.matrix[0];
    canvasMatrix[0] *= scale;
    canvasMatrix[3] *= scale;
    canvasMatrix[4] = matrix[4] - scale * this.state.matrix[4];
    canvasMatrix[5] = matrix[5] - scale * this.state.matrix[5];

    return new Cancelable((resolve) => {
      this.backgroundCtx.fillRect(this.minX, this.minY, this.maxX, this.maxY);
      this.backgroundCtx.save();
      try {
        this.backgroundCtx.transform(...canvasMatrix);
        this.backgroundCtx.drawImage(this.foregroundCanvas, 0, 0);
      } finally {
        this.backgroundCtx.restore();
      }
      this.ctx.clearRect(this.minX, this.minY, this.maxX, this.maxY);
      this.ctx.drawImage(this.backgroundCanvas, 0, 0);
      resolve();
    });
  }

  drawInfo(matrix) {
    return this.clearInfo()
      .then(() => this.drawComments(matrix))
      .then(() => this.drawFocus(matrix))
      .then(() => this.clearFocus());
  }

  drawGraph(matrix) {
    return this.clearGraph().then(() => this.drawLines(matrix));
  }

  clearInfo() {
    return new Cancelable((resolve) => {
      this.infoCtx.clearRect(this.minX, this.minY, this.maxX, this.maxY);
      resolve();
    });
  }

  clearGraph() {
    return new Cancelable((resolve) => {
      this.graphCtx.clearRect(this.minX, this.minY, this.maxX, this.maxY);
      resolve();
    });
  }

  drawLines(matrix) {
    return this.props.lines.forEach((line) => this.drawLine(matrix, line));
  }

  drawLine(matrix, line) {
    return new Cancelable((resolve) => {
      if (line.state === undefined) {
        resolve();
        return;
      }
      this.graphCtx.save();
      this.graphCtx.transform(...matrix);
      this.graphCtx.beginPath();
      this.graphCtx.moveTo(line.StartPointX, line.StartPointY);
      this.graphCtx.lineTo(line.EndPointX, line.EndPointY);
      if (line.state !== undefined) {
        this.graphCtx.strokeStyle = line.state === 1 ? "green" : "red";
      }
      this.graphCtx.lineCap = "round";
      this.graphCtx.globalAlpha = 0.4;
      this.graphCtx.lineWidth = 12 / matrix[0];
      this.graphCtx.stroke();
      this.graphCtx.restore();
      resolve();
    });
  }

  drawComments(matrix) {
    return this.props.comments.reduce(
      (promise, comment) =>
        promise.then(() => this.drawComment(matrix, comment)),
      new Cancelable((resolve) => resolve())
    );
  }

  hexToRgbA(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      hex
    );
    return result
      ? `rgba(${parseInt(result[1], 16)}, ${parseInt(
          result[2],
          16
        )}, ${parseInt(result[3], 16)}, ${(
          parseInt(result[4], 16) / 255
        ).toFixed(2)})`
      : null;
  }

  drawComment(matrix, comment) {
    return new Cancelable((resolve) => {
      this.infoCtx.save();
      try {
        this.infoCtx.transform(...matrix);
        this.infoCtx.beginPath();
        if (comment.EndPointX && comment.EndPointY) {
          const width = comment.EndPointX - comment.PointX;
          const height = comment.EndPointY - comment.PointY;
          this.infoCtx.rect(comment.PointX, comment.PointY, width, height);
          this.infoCtx.strokeStyle = comment.Color
            ? comment.Color
            : COMMENT_COLOR;
          this.infoCtx.lineWidth = 1 / matrix[0];
          this.infoCtx.stroke();
          this.infoCtx.fillStyle = comment.Color
            ? this.hexToRgbA(comment.Color)
            : COMMENT_FILL_COLOR;
          this.infoCtx.fill();
        } else if (comment.Points.length > 1) {
          this.infoCtx.moveTo(comment.Points[0].x, comment.Points[0]);
          comment.Points.forEach((point) => {
            this.infoCtx.lineTo(point.x, point.y);
          });
          this.infoCtx.strokeStyle = COMMENT_COLOR;
          this.infoCtx.lineWidth = 2 / matrix[0];
          this.infoCtx.stroke();
        } else {
          const radius = COMMENT_RADIUS / matrix[0];
          this.infoCtx.arc(
            comment.PointX,
            comment.PointY,
            radius,
            0,
            2 * Math.PI
          );
          this.infoCtx.fillStyle = COMMENT_FILL_COLOR;
          this.infoCtx.fill();
        }
      } finally {
        this.infoCtx.restore();
      }
      resolve();
    });
  }

  previewRectComment(clientX, clientY) {
    this.markingCtx.clearRect(this.minX, this.minY, this.maxX, this.maxY);
    this.markingCtx.beginPath();
    const width = clientX - this.startX;
    const height = clientY - this.startY;
    this.markingCtx.rect(this.startX, this.startY, width, height);
    this.markingCtx.strokeStyle = this.props.commentColor;
    this.markingCtx.lineWidth = 2;
    this.markingCtx.stroke();
  }

  previewFreeComment(clientX, clientY) {
    const freeComment = [
      ...this.state.freeComment,
      this.toSvgCoords(clientX, clientY),
    ];
    this.setState({ freeComment });
    this.markingCtx.lineTo(clientX, clientY);
    this.markingCtx.strokeStyle = "red";
    this.markingCtx.lineWidth = 2;
    this.markingCtx.stroke();
  }

  drawFocus(matrix) {
    return new Cancelable((resolve) => {
      if (this.state.focus) {
        this.infoCtx.save();

        const width = this.state.focus.xMax - this.state.focus.xMin;
        const height = this.state.focus.yMax - this.state.focus.yMin;
        const offset = Math.min(width, height) / 4;
        try {
          this.infoCtx.transform(...matrix);
          this.infoCtx.beginPath();
          this.infoCtx.rect(
            this.state.focus.xMin - offset,
            this.state.focus.yMin - offset,
            width + 2 * offset,
            height + 2 * offset
          );
          this.infoCtx.strokeStyle = FOCUS_COLOR;
          this.infoCtx.lineWidth = 1 / matrix[0];
          this.infoCtx.stroke();
          this.infoCtx.fillStyle = FOCUS_FILL_COLOR;
          this.infoCtx.fill();
        } finally {
          this.infoCtx.restore();
        }
      }
      resolve();
    });
  }

  clearFocus() {
    return new Cancelable((resolve) => this.setState({ focus: null }, resolve));
  }

  drawTiles(matrix) {
    return this.getTileSizes()
      .reduce(
        (tiles, tileSize) => [
          ...tiles,
          ...this.getTileImages(matrix, tileSize),
        ],
        []
      )
      .reduce(
        (promise, tile) => promise.then(() => this.drawTile(tile)),
        new Cancelable((resolve) => resolve())
      );
  }

  drawTile(tile, fromCache = true) {
    const image = new Image();
    image.width = tile.width;
    image.height = tile.height;
    image.validate = "never";
    return new Cancelable((resolve) => {
      image.onload = () => setTimeout(resolve);
      image.onerror = () => {
        image.err = true;
        setTimeout(resolve);
      };

      if (
        fromCache &&
        this.props.hasMergedLayers &&
        window.tileRequest &&
        window.getVersionCached
      ) {
        window.getVersionCachedCallback = (isCached) => {
          if (!isCached) {
            image.src = tile.src;
            return;
          }
          window.tileRequestCallback = (dataURL) => {
            image.src = dataURL;
          };
          window.tileRequest(JSON.stringify(url.parse(tile.src, true).query));
        };
        window.getVersionCached(this.props.versionId);
        return;
      }

      image.src = tile.src;
    }).then(() =>
      this.drawImage(image, tile.matrix, tile.clear, tile.clearBackground)
    );
  }

  drawImage(image, matrix, clear, clearBackground) {
    return new Cancelable((resolve) => {
      const overlap = {
        x: Math.max(TILE_OVERLAP, TILE_OVERLAP / matrix[0]),
        y: Math.max(TILE_OVERLAP, TILE_OVERLAP / matrix[3]),
      };
      this.ctx.save();
      try {
        this.ctx.transform(...matrix);
        if (clear) {
          this.ctx.clearRect(0, 0, image.width, image.height);
        }
        if (!image.err) {
          this.ctx.drawImage(
            image,
            -overlap.x,
            -overlap.y,
            image.width + overlap.x,
            image.height + overlap.y
          );
        }
      } finally {
        this.ctx.restore();
      }
      if (clearBackground) {
        this.backgroundCtx.save();
        try {
          this.backgroundCtx.transform(...matrix);
          this.backgroundCtx.fillRect(
            -overlap.x,
            -overlap.y,
            image.width + overlap.x,
            image.height + overlap.y
          );
        } finally {
          this.backgroundCtx.restore();
        }
      }
      resolve();
    });
  }

  isAddingItem() {
    return (
      this.props.addingFree || this.props.addingRect || this.props.addingDot
    );
  }

  getCursor() {
    if (this.isAddingItem()) {
      return "crosshair";
    } else if (this.state.hoversLink) {
      return "pointer";
    }
    return "auto";
  }

  render() {
    return (
      <div
        className="DrawingCanvas"
        style={{
          backgroundColor: this.props.backgroundColor,
          cursor: this.getCursor(),
        }}
        onMouseDown={this.onDragStart.bind(this)}
        onTouchStart={this.onTouchStart.bind(this)}
        onMouseMove={this.onDragMove.bind(this)}
        onTouchMove={this.onTouchMove.bind(this)}
        onMouseUp={this.onDragEnd.bind(this)}
        onTouchEnd={this.onTouchEnd.bind(this)}
        onWheel={this.onWheel.bind(this)}
      >
        <canvas
          ref={(canvas) => {
            if (canvas) {
              this.canvas = canvas;
              this.animationCtx = canvas.getContext("2d", this.ctxOpts);
              this.animationCtx.fillStyle = this.props.backgroundColor;
            }
          }}
          width={this.maxX - this.minX}
          height={this.maxY - this.minY}
        />
        <div className="canvas-info">
          x: {-this.state.matrix[4].toFixed(0)}
          {" | "}
          y: {-this.state.matrix[5].toFixed(0)}
          {" | "}
          z: {(100 * this.state.matrix[0]).toFixed(0)} %
        </div>
        {this.state.loading && (
          <div className="canvas-loader">
            <Loader active inline size="mini" /> {t("loading")}
          </div>
        )}
      </div>
    );
  }
}

DrawingCanvas.propTypes = {
  maxZoom: PropTypes.number,
  isAuto: PropTypes.bool,
  hasMergedLayers: PropTypes.bool,
  hasJpgTiles: PropTypes.bool,
  hasSvgTiles: PropTypes.bool,
  comments: PropTypes.array.isRequired,
  lines: PropTypes.array.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  drawingScale: PropTypes.number.isRequired,
  rectangle: PropTypes.array.isRequired,
  drawingId: PropTypes.number,
  versionName: PropTypes.string.isRequired,
  versionNumber: PropTypes.number.isRequired,
  versionId: PropTypes.number.isRequired,
  versionDate: PropTypes.instanceOf(Date),
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onItemHover: PropTypes.func,
};

const mapStateToProps = (state) => ({ ...state.ui.viewer });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateViewer,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DrawingCanvas);
