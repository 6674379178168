const NODE_TYPES = {
  AND: 'AND',
  OR: 'OR',
  SPLIT: 'SPLIT',
  INVERTER: 'INVERTER',
};

export default class Simulator {
  constructor(graphLines, graphNodes) {
    this.graphLines = (graphLines || []).map(line => ({ ...line }));
    this.graphNodes = (graphNodes || []).map(node => ({ ...node }));
  }

  simulate(signal, clickedLine) {
    const { graphLines, graphNodes } = this;
    Simulator.simulateLine(
      signal,
      graphLines.find(line => line.ElementId === clickedLine.ElementId),
      graphLines,
      graphNodes,
    );
    return this;
  }

  static simulateLine(signal, clickedLine, graphLines, graphNodes) {
    if (!clickedLine || clickedLine.state === signal) {
      return;
    }
    clickedLine.state = signal;
    Simulator.simulateLine(
      signal,
      graphLines.find(line => line.ElementId === clickedLine.NextElementId),
      graphLines,
      graphNodes,
    );
    Simulator.simulateLine(
      signal,
      graphLines.find(line => line.ElementId === clickedLine.PreviousElementId),
      graphLines,
      graphNodes,
    );
    Simulator.simulateNode(
      graphNodes.find(node => node.ElementId === clickedLine.NextElementId),
      graphLines,
      graphNodes,
    );
  }

  static simulateNode(node, graphLines, graphNodes) {
    if (!node) {
      return;
    }
    const inputLines = Simulator.getConnectedLinesBeforeNode(node, graphLines);
    const outputLines = Simulator.getConnectedLinesAfterNode(node, graphLines);
    const signal = Simulator.operate(node, inputLines.map(line => line.state));
    outputLines.forEach(line => Simulator.simulateLine(signal, line, graphLines, graphNodes));
  }

  static operate(node, inputs) {
    switch (node.Type) {
      case NODE_TYPES.SPLIT:
      case NODE_TYPES.OR: {
        if (inputs.includes(1)) { return 1; }
        if (inputs.includes(undefined)) { return undefined; }
        return 0;
      }
      case NODE_TYPES.AND: {
        if (inputs.includes(0)) { return 0; }
        if (inputs.includes(undefined)) { return undefined; }
        return 1;
      }
      case NODE_TYPES.INVERTER: {
        if (inputs.includes(1)) { return 0; }
        if (inputs.includes(0)) { return 1; }
        return undefined;
      }
      default: {
        return undefined;
      }
    }
  }

  static getConnectedLinesAfterNode(node, graphLines) {
    return graphLines
      .filter(line => line.PreviousElementId === node.ElementId);
  }

  static getConnectedLinesBeforeNode(node, graphLines) {
    return graphLines
      .filter(line => line.NextElementId === node.ElementId);
  }
}

