import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Input, TextArea, Dropdown } from 'semantic-ui-react';

const SEARCH_TIMEOUT = 600;

export default class MutableInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      [this.props.property]: this.props.item[this.props.property],
    };
  }


  onInputChange(event, data) {
    clearTimeout(this.inputChangeTimeout);
    // update value
    const target = data || event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [target.name]: value });
    // Set timeout
    if (this.props.onChange) {
      this.inputChangeTimeout = setTimeout(() => {
        this.props.onChange(this.state);
      }, SEARCH_TIMEOUT);
    }
  }


  render() {
    if (this.props.inputType === 'select') {
      return (
        <Dropdown fluid search selection
          {...(this.props.inputProps || {})}
          multiple={!!this.props.multiple}
          name={this.props.property}
          placeholder={this.props.placeholder ? t(this.props.placeholder) : ''}
          options={this.props.options}
          value={this.state[this.props.property]}
          onChange={this.onInputChange.bind(this)}
        />
      );
    }

    if (this.props.inputType === 'textarea') {
      return (
        <TextArea fluid
          {...(this.props.inputProps || {})}
          name={this.props.property}
          placeholder={this.props.placeholder ? t(this.props.placeholder) : ''}
          onChange={this.onInputChange.bind(this)}
          value={this.state[this.props.property] || this.props.defaultValue || ''}
        />
      );
    }

    return (
      <Input fluid
        {...(this.props.inputProps || {})}
        type={this.props.inputType || ''}
        name={this.props.property}
        placeholder={this.props.placeholder ? t(this.props.placeholder) : ''}
        onChange={this.onInputChange.bind(this)}
        value={this.state[this.props.property] || this.props.defaultValue || ''}
      />
    );
  }
}

MutableInput.propTypes = {
  // Item - Value
  item: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  property: PropTypes.string.isRequired,
  // Callback
  onChange: PropTypes.func,
  // Ui
  inputType: PropTypes.string,
  inputProps: PropTypes.object,
  placeholder: PropTypes.string,
  // Ui select (dropdown)
  multiple: PropTypes.bool,
  options: PropTypes.array,
};
