import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Sidebar, Tab, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';

import ViewerComments from './ViewerComments';
import ViewerTags from './ViewerTags';
import ViewerInfo from './ViewerInfo';

import applyLogoSrc from '../../../styles/images/apply.png';

class ViewerSidebar extends Component {
  renderVersion() {
    return (
      <div className="version-info">
        <div className="version">
          {this.props.config.Name} ({t('package_version')}{this.props.config.Version})
        </div>
        <img src={applyLogoSrc} alt="Apply" />
      </div>
    );
  }

  render() {
    const closeButton = (
      <Icon size="large" color="black" name="close" onClick={this.props.onToggleClicked} />
    );
    const panes = [
      {
        menuItem: { key: 'info', name: t('info'), icon: 'info' },
        render: () =>
          (
          <Tab.Pane attached='top'>
            {closeButton}
            <ViewerInfo
              drawing={this.props.drawing}
              drawingVersion={this.props.drawingVersion}
            />
            {this.renderVersion()}
          </Tab.Pane>
          ),
      },
      (this.props.features.Comments ? {
        menuItem: { key: 'comments', name: t('comments'), icon: 'comment' },
        render: () =>
          (
          <Tab.Pane attached='top'>
            {closeButton}
            <ViewerComments
              comments={this.props.comments}
              onCommentClicked={this.props.onCommentClicked}
              updateComment={this.props.updateComment}
              onCommentBtnClicked={this.props.onCommentBtnClicked}
              isAddingComment={this.props.isAddingComment}
            />
            {this.renderVersion()}
          </Tab.Pane>
          ),
      } : {}),
      {
        menuItem: { key: 'tags', name: t('tags'), icon: 'tag' },
        render: () =>
          (
          <Tab.Pane attached='top'>
            {closeButton}
            <ViewerTags
              drawing={this.props.drawing}
              tags={this.props.tags}
              onTagClicked={this.props.onTagClicked}
            />
            {this.renderVersion()}
          </Tab.Pane>
          ),
      },
    ];

    return (
      <Sidebar
        animation='overlay'
        width='wide'
        direction='right'
        visible={this.props.isVisible}
        icon='labeled'
      >
        <Tab menu={{ attached: 'bottom', widths: panes.length }} panes={panes} />
    </Sidebar>
    );
  }
}

ViewerSidebar.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  drawing: PropTypes.object.isRequired,
  drawingVersion: PropTypes.object.isRequired,
  features: PropTypes.object,
  config: PropTypes.object,
  // Callback Functions
  onToggleClicked: PropTypes.func,
  // Comments
  comments: PropTypes.array,
  onCommentClicked: PropTypes.func,
  updateComment: PropTypes.func.isRequired,
  onCommentBtnClicked: PropTypes.func,
  isAddingComment: PropTypes.bool,
  // Tags
  tags: PropTypes.array,
  onTagClicked: PropTypes.func,
};


const mapStateToProps = state => ({
  features: (state.api.configuration.index || {}).Features || {},
  config: (state.api.configuration.index || {}).Information || {},
});

export default connect(mapStateToProps)(ViewerSidebar);
