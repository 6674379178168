import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { NavLink, withRouter } from 'react-router-dom';
import ContainedLayout from '../components/layout/ContainedLayout';
import UserEditor from '../components/users/UserEditor';

class UserProfile extends Component {
  static getUserName(user) {
    return user.FirstName ? `${user.FirstName} ${user.LastName || ''}` : user.Id;
  }

  render() {
    const user = this.props.match.params.userId ?
      (this.props.user || { Id: this.props.match.params.userId }) :
      (this.props.currentUser || {});

    if (!user.Id) {
      return null;
    }

    return (
      <ContainedLayout>
        <div className="UserProfile">
          <Container>
            <Segment vertical>
              {
                this.props.match.params && this.props.match.params.userId ?
                  <h1>
                    <NavLink to="/users">{t('users')}</NavLink>
                    {' / '}
                    {user.Id === 'create' ? t('add') : UserProfile.getUserName(user)}
                  </h1>
                :
                  <h1>{t('profile')}</h1>
              }
              <UserEditor userId={user.Id} />
            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

UserProfile.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  user: state.api.users.index[props.match.params.userId],
  currentUser: state.api.account.user,
});

export default withRouter(connect(mapStateToProps, null)(UserProfile));
