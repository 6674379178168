import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { t } from 'i18next';
import { Icon, Popup, Message, Dimmer, Loader } from 'semantic-ui-react';
import parse from '../../../lib/csv-parse';
import DataTable from '../../generic/DataTable';
import { createUser } from '../../../actions/api/users';
import { clearMessages } from '../../../actions/api/account';
import { COMPANIES_STATUS_READY } from '../../../reducers/api/companies';
import { fetchCompanies } from '../../../actions/api/companies';
import { GROUP_STATUS_READY } from '../../../reducers/api/groups';
import { fetchGroups } from '../../../actions/api/groups';

import sampleCsv from './UsersSample.csv';

const ignoredProps = ['Message', 'Success', 'ConfirmPassword', 'GroupIds', 'CompanyId', 'GroupId'];

class UsersExcelImport extends Component {
  // Component Methods --------------------
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      error: null,
      status: '',
    };
  }

  componentDidMount() {
    this.props.fetchCompanies();
    this.props.fetchGroups();
  }

  // Implementation --------------------
  onFileDrop(acceptedFiles) {
    if (!acceptedFiles || !acceptedFiles.length) {
      this.setState({ error: t('csv_parse_error') });
      return;
    }
    this.setState({ error: null });
    const file = acceptedFiles[0];

    const reader = new FileReader();
    reader.onloadend = (event) => {
      // Remove file from memory
      window.URL.revokeObjectURL(file.preview);
      // Get read content
      const fileContent = event.target.result;
      if (!fileContent) {
        this.setState({ error: t('csv_parse_error') });
        return;
      }
      // Parse csv content
      parse(fileContent, null, (err, rows) => {
        if (err) {
          this.setState({ error: t('csv_parse_error') });
          return;
        }

        this.generateUsers(rows);
      });
    };
    reader.readAsText(file);
  }


  generateUsers(rows) {
    const properties = rows[0]; // first row contains user properties
    const users = [];

    // Iterate rows of content
    for (let i = 1; i < rows.length; i += 1) {
      const newUser = {};

      for (let j = 0; j < properties.length; j += 1) {
        let propValue = '';
        if (j < rows[i].length) {
          propValue = rows[i][j];
        }

        newUser[properties[j]] = propValue || '';
      }

      users.push(newUser);
    }

    // Add the ConfirmPassword field
    users.forEach((u) => { u.ConfirmPassword = u.Password; });

    // Check for Company
    users.forEach((u) => {
      let matchingCompany;
      if (u.Company) {
        matchingCompany = this.props.companies
          .find(c => c.Name.toLowerCase() === u.Company.toLowerCase());
      }
      if (matchingCompany) {
        u.CompanyId = matchingCompany.Id;
        u.Company = matchingCompany.Name;
      }
    });

    // Check for Groups
    users.forEach((u) => {
      let matchingGroup;
      if (u.Group) {
        matchingGroup = this.props.groups
          .find(g => g.Name.toLowerCase() === u.Group.toLowerCase());
      }
      if (matchingGroup) {
        u.GroupIds = [matchingGroup.Id];
        u.Group = matchingGroup.Name;
      }
    });

    this.setState({ users }, this.registerUsers);
  }


  registerUsers() {
    this.setState({ status: t('registering') });
    const promises = [];
    this.state.users.forEach((user) => {
      const userPromise = this.props.createUser(user);
      promises.push(userPromise);
      userPromise.then((response) => {
        if (!response || !response.result) return;
        user.Success = response.result.Success;
        user.Message = response.result.Message;
      });
    });

    Promise.all(promises).then(() => {
      this.setState({ status: 'done' });
      this.props.clearMessages();
    });
  }

  // Ui --------------------
  render() {
    return (
      <div className="UsersExcelImport">
      {this.state.error && (
        <Message negative={true} icon={'x'} content={this.state.error}/>
      )}
      {this.state.users.length > 0 && this.renderUsers()}
      {this.state.users.length === 0 && (
        <div>
          <a href={sampleCsv}><Icon name='download'></Icon>{t('csv_sample')}</a>
          <Dropzone className="dropzone"
          onDrop={this.onFileDrop.bind(this)}
          >
            {t('drop_csv')}
          </Dropzone>
        </div>
      )}
      {this.props.companiesStatus !== COMPANIES_STATUS_READY &&
       this.props.groupsStatus !== GROUP_STATUS_READY && (
        <Dimmer active inverted>
          <Loader inverted/>
        </Dimmer>
      )}
      </div>
    );
  }

  renderUsers() {
    const fields = {};
    Object.keys(this.state.users[0])
      .filter(k => !ignoredProps.includes(k))
      .forEach((e) => { fields[e] = e; });

    // Upload Status Icon
    fields.status = user => UsersExcelImport.renderUserIcon(user);

    return (
      <DataTable
        items={this.state.users}
        totalItems={this.state.users.length}
        status={this.state.status}
        currentPage={1}
        pageSize={this.state.users.length}
        statusReady={'done'}
        idField="Email"
        fields={fields}
      />
    );
  }


  static renderUserIcon(user) {
    let userIconName = 'cloud upload';
    if (user.Success != null) {
      userIconName = user.Success ? 'check' : 'x';
    }

    if (user.Message) {
      return (
        <Popup
        trigger={<Icon name={userIconName}/>}
        content={user.Message}
        />
      );
    }

    return <Icon name={userIconName}/>;
  }
}

UsersExcelImport.propTypes = {
  createUser: PropTypes.func,
  clearMessages: PropTypes.func,
  companies: PropTypes.array.isRequired,
  companiesStatus: PropTypes.string,
};

const mapStateToProps = state => ({
  companies: state.api.companies.list.items.map(id => state.api.companies.index[id]),
  companiesStatus: state.api.companies.status,
  groups: state.api.groups.list.items.map(id => state.api.groups.index[id]),
  groupsStatus: state.api.groups.status,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  createUser,
  clearMessages,
  fetchCompanies,
  fetchGroups,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsersExcelImport);
