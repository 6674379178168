import React, { Component } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { NavLink, withRouter } from 'react-router-dom';
import ContainedLayout from '../components/layout/ContainedLayout';
import SystemEditor from '../components/systems/SystemEditor';

class SystemEdit extends Component {
  render() {
    const system = this.props.system || { Id: this.props.match.params.systemId };

    if (!system.Id) {
      return null;
    }

    return (
      <ContainedLayout>
        <div className="SystemEdit">
          <Container>
            <Segment vertical>
              {
                this.props.match.params && this.props.match.params.systemId ?
                  <h1>
                    <NavLink to="/systems">{t('system')}</NavLink>
                    {' / '}
                    {system.Id === 'create' ? t('add') : (system.Name || system.Id)}
                  </h1>
                :
                  <h1>{t('system')}</h1>
              }
              <SystemEditor systemId={String(system.Id)} />
            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

SystemEdit.propTypes = {
  match: PropTypes.object.isRequired,
  system: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  system: state.api.drawingSystems.index[props.match.params.systemId],
});

export default withRouter(connect(mapStateToProps, null)(SystemEdit));
