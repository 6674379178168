import React, { Component } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { NavLink, withRouter } from 'react-router-dom';
import ContainedLayout from '../components/layout/ContainedLayout';
import CompanyEditor from '../components/companies/CompanyEditor';
import PermissionsCheck, { ENTITY_NAMES, PERMISSION_VALUES } from '../components/generic/PermissionsCheck';

class CompanyEdit extends Component {
  render() {
    const company = this.props.company || { Id: this.props.match.params.companyId };

    if (!company.Id) {
      return null;
    }

    return (
      <ContainedLayout>
        <div className="CompanyEdit">
          <Container>
            <Segment vertical>
              {
                this.props.match.params && this.props.match.params.companyId ?
                  <h1>
                    <NavLink to="/companies">{t('companies')}</NavLink>
                    {' / '}
                    {company.Id === 'create' ? t('add') : (company.Name || company.Id)}
                  </h1>
                :
                  <h1>{t('company')}</h1>
              }
              <PermissionsCheck
              entityName={ENTITY_NAMES.COMPANY}
              requiredPermission={PERMISSION_VALUES.CONTRIBUTE}
              anyPropertyValue={true}
              showUnauthorized={true}
              >
                <CompanyEditor companyId={String(company.Id)} />
              </PermissionsCheck>

            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

CompanyEdit.propTypes = {
  match: PropTypes.object.isRequired,
  company: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  company: state.api.companies.index[props.match.params.companyId],
});

export default withRouter(connect(mapStateToProps, null)(CompanyEdit));
