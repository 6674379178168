import {
  API_STATUS_READY,
  API_STATUS_CREATING,
  createAPIReducer,
} from '../../lib/redux';

const reducer = createAPIReducer('configuration');

export default reducer;

export const CONFIGURATION_STATUS_READY = API_STATUS_READY;
export const CONFIGURATION_STATUS_CREATING = API_STATUS_CREATING;
