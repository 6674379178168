import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Dimmer, Loader, Table, Dropdown, Input } from 'semantic-ui-react';
import MessageList from '../generic/MessageList';
import { PERMISSION_STATUS_READY } from '../../reducers/api/permissions';
import { createPermission, updatePermission } from '../../actions/api/permissions';
import { GROUP_STATUS_READY } from '../../reducers/api/groups';
import { fetchGroups } from '../../actions/api/groups';
import { PERMISSION_VALUES } from '../generic/PermissionsCheck';


class GroupPermissions extends Component {
  // Component Methods --------------------
  constructor(props) {
    super(props);

    this.state = { searchTerm: null };
  }

  componentDidMount() {
    this.props.fetchGroups();
  }


  // Ui Callbacks --------------------
  onSearchChanged(event) {
    clearTimeout(this.searchTimeout);
    const searchTerm = event.target.value;
    this.searchTimeout = setTimeout(() => {
      this.setState({ searchTerm });
    }, 600);
  }

  onPermissionChanged(group, permission, entityName, event, target) {
    const newValue = target.value;

    if (permission) { // update
      if (permission.Grant === newValue) return; // no change

      this.props.updatePermission({ ...permission, Grant: newValue })
        .then(() => this.props.fetchGroups());
    } else {
      if (newValue === 0) return; // no change

      this.props.createPermission({
        EntityType: entityName,
        Grant: newValue,
        PropertyValue: (this.props.drawing || {}).Id,
        GroupId: group.Id,
      })
        .then(() => this.props.fetchGroups());
    }
  }

  // Ui --------------------
  render() {
    return (
      <div className="DrawingGroupsPanel">
        <MessageList messages={this.props.messages} />
        <Input icon="search"
              placeholder={t('search')}
              onChange={this.onSearchChanged.bind(this)}
            />
        {this.renderContent()}
        {this.renderDimmer()}
      </div>
    );
  }

  renderDimmer() {
    if (this.props.permissionsStatus !== PERMISSION_STATUS_READY) {
      return (
        <Dimmer active inverted>
          <Loader inverted>{t(this.props.permissionsStatus)}</Loader>
        </Dimmer>
      );
    } else if (this.props.groupsStatus !== GROUP_STATUS_READY) {
      return (
        <Dimmer active inverted>
          <Loader inverted>{t(this.props.groupsStatus)}</Loader>
        </Dimmer>
      );
    }
    return null;
  }

  renderContent() {
    const { searchTerm } = this.state;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            {this.props.showCompanyNames ? <Table.HeaderCell>{t('company')}</Table.HeaderCell> : null}
            <Table.HeaderCell>{t('name')}</Table.HeaderCell>
            <Table.HeaderCell>{t('drawings')}</Table.HeaderCell>
            <Table.HeaderCell>{t('comments')}</Table.HeaderCell>
            {this.props.showCompanyNames ? <Table.HeaderCell>{t('users')}</Table.HeaderCell> : null}
            {this.props.showCompanyNames ? <Table.HeaderCell>{t('companies')}</Table.HeaderCell> : null}
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {
          this.props.groups
          .filter(g =>
            !searchTerm // no filter
            || g.Name.toLowerCase().includes(searchTerm) // search contained in name
            || (this.props.showCompanyNames && g.CompanyName.toLowerCase().includes(searchTerm)))
          .map(g => (
            <Table.Row key={g.Id}>
              {this.props.showCompanyNames ? <Table.Cell>{g.CompanyName}</Table.Cell> : null}
              <Table.Cell>{g.Name}</Table.Cell>
              <Table.Cell>{this.renderDropdown(g, 'Drawing')}</Table.Cell>
              <Table.Cell>{this.renderDropdown(g, 'Comment')}</Table.Cell>
              {this.props.showCompanyNames ? <Table.Cell>{this.renderDropdown(g, 'ApplicationUser')}</Table.Cell> : null}
              {this.props.showCompanyNames ? <Table.Cell>{this.renderDropdown(g, 'Company')}</Table.Cell> : null}
            </Table.Row>
          ))
        }
        </Table.Body>
      </Table>
    );
  }

  renderDropdown(group, entityName) {
    const groupPermission = group.Permissions.find(p => p.EntityType === entityName
      && (this.props.drawing == null ? p.PropertyValue == null // generic permissions
        : p.PropertyValue === this.props.drawing.Id)); // specific drawing permissions
    const permissions = [
      { text: t('none'), value: 0 },
      { text: t('view'), value: PERMISSION_VALUES.VIEW },
      { text: t('edit'), value: PERMISSION_VALUES.CONTRIBUTE },
      { text: t('config'), value: PERMISSION_VALUES.MODERATE },
    ];
    return (
      <Dropdown selection fluid
        multiple={false}
        options={permissions.map(p => ({ key: p.value, text: p.text, value: p.value }))}
        value={groupPermission ? groupPermission.Grant : 0}
        onChange={this.onPermissionChanged.bind(this, group, groupPermission, entityName)}
      />
    );
  }
}

GroupPermissions.propTypes = {
  // Params
  drawing: PropTypes.object,
  showCompanyNames: PropTypes.bool,
  // State
  permissionsStatus: PropTypes.string,
  groupsStatus: PropTypes.string,

  groups: PropTypes.array,
  messages: PropTypes.array,
  // Functions
  fetchGroups: PropTypes.func,
  createPermission: PropTypes.func,
  updatePermission: PropTypes.func,
};

const mapStateToProps = state => ({
  permissionsStatus: state.api.permissions.status,
  groupsStatus: state.api.groups.status,
  messages: state.api.permissions.messages.concat(state.api.groups.messages),
  groups: state.api.groups.list.items.map(id => (state.api.groups.index[id])),
});

const mapDispatchToProps = (dispatch, props) => bindActionCreators({
  fetchGroups: () => fetchGroups({ CompanyId: (props.drawing || {}).CompanyId }),
  createPermission,
  updatePermission,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GroupPermissions);
