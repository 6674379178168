import { combineReducers } from 'redux';
import account from './account';
import users from './users';
import tags from './tags';
import drawings from './drawings';
import groups from './groups';
import comments from './comments';
import companies from './companies';
import drawingversions from './drawingversions';
import drawinglayers from './drawinglayers';
import permissions from './permissions';
import zoomlevels from './zoomlevels';
import drawingSystems from './systems';
import configuration from './configuration';

export default combineReducers({
  users,
  account,
  tags,
  companies,
  drawings,
  groups,
  comments,
  drawingversions,
  drawinglayers,
  permissions,
  zoomlevels,
  drawingSystems,
  configuration,
});
