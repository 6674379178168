import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Dropdown, Loader, Input, Form } from 'semantic-ui-react';
import DataTable from '../../generic/DataTable';
import MutableInput from '../../generic/MutableInput';
import { LAYERS_STATUS_READY } from '../../../reducers/api/drawinglayers';
import { fetchLayers, updateLayer } from '../../../actions/api/drawinglayers';
import { VERSIONS_STATUS_READY } from '../../../reducers/api/drawingversions';
import {
  fetchVersions, fetchVersion, mergeVersionLayers, tileVersionLayers,
} from '../../../actions/api/drawingversions';
import { ZOOMLEVEL_STATUS_READY } from '../../../reducers/api/zoomlevels';
import { fetchZoomLevels } from '../../../actions/api/zoomlevels';
import { MAX_ZOOM_LEVEL, ZOOM_STEP } from './DrawingZoomLevelPanel';

const PARSER_STATUS_READY = 1;
const PARSER_STATUS_ERROR = 2;

class DrawingLayers extends Component {
  // Component Methods --------------------
  constructor(props) {
    super(props);
    this.state = {
      // Version
      versionId: this.props.drawing.ActiveVersionId,
    };
  }

  componentDidMount() {
    this.loadList();
    this.props.fetchVersions({ DrawingId: this.props.drawing.Id });
    this.props.fetchZoomLevels({ DrawingId: this.props.drawing.Id });
  }

  componentDidUpdate(props) {
    if (this.props.drawingId !== props.drawingId) {
      this.setState({ versionId: this.props.drawing.ActiveVersionId }, () => {
        this.loadList();
        this.props.fetchVersions({ DrawingId: (this.props.drawing || {}).Id });
        this.props.fetchZoomLevels({ DrawingId: (this.props.drawing || {}).Id });
      });
    }
  }


  // Ui Callbacks --------------------
  onVersionChanged(event, data) {
    this.setState({ versionId: data.value }, this.loadList.bind(this));
  }

  onLayerChanged(layer, newLayer) {
    this.props.updateLayer({ ...layer, ...newLayer });
  }

  onSearchChanged(searchTerm) {
    this.setState({ searchTerm, currentPage: 1 }, this.loadList.bind(this));
  }

  mergeLayers() {
    this.setState({ loading: true }, () => {
      this.props.mergeVersionLayers(this.state.versionId, { format: 'image/jpeg' })
        .then(() => this.props.fetchVersion(this.state.versionId, {
          poll: item => [PARSER_STATUS_READY, PARSER_STATUS_ERROR].indexOf(item.Status) > -1,
        }))
        .then(() => this.setState({ loading: false }));
    });
  }

  tileLayers() {
    this.setState({ loading: true }, () => {
      this.props.tileVersionLayers(this.state.versionId, {})
        .then(() => this.props.fetchVersion(this.state.versionId, {
          poll: item => [PARSER_STATUS_READY, PARSER_STATUS_ERROR].indexOf(item.Status) > -1,
        }))
        .then(() => this.setState({ loading: false }));
    });
  }

  getCurrentVersion() {
    return (this.props.versions || []).find(v => v.Id === Number(this.state.versionId));
  }

  getZoomLevel(id) {
    return this.props.zoomlevels.find(zl => zl.Id === id) || {};
  }

  // Implementation --------------------
  loadList() {
    if (!this.state.versionId) {
      return;
    }
    this.props.fetchLayers({
      Filter: this.state.searchTerm,
      VersionId: this.state.versionId,
    });
  }

  // Ui --------------------
  render() {
    return (
    <div className="DrawingLayersList">

      {this.renderVersions()}

      <DataTable
        items={this.props.items}
        totalItems={this.props.totalItems}
        pageSize={this.props.totalItems}
        currentPage={1}
        disablePageSizeSelection={true}
        status={this.state.loading ? 'loading' : this.props.status}
        statusReady={LAYERS_STATUS_READY}
        onSearchChanged = {this.onSearchChanged.bind(this)}
        idField="Id"
        messages={this.props.listMessages.concat(this.props.versionMessages)}
        fields={{
          name: 'Name',
          alias: layer => (
            <MutableInput item={layer}
              property={'Alias'}
              onChange={this.onLayerChanged.bind(this, layer)}
            />
          ),
          zoom_from: layer => (layer.ZoomLevelId ? (
            <Input
              disabled
              type="number"
              value={this.getZoomLevel(layer.ZoomLevelId).ZoomFrom || 0}
            />
          ) : (
            <MutableInput item={layer} property={'ZoomFrom'}
              inputType={'number'}
              defaultValue="0"
              onChange={this.onLayerChanged.bind(this, layer)}
              inputProps={{ step: ZOOM_STEP, min: 0, max: MAX_ZOOM_LEVEL }}
            />
          )),
          zoom_to: layer => (layer.ZoomLevelId ? (
            <Input
              disabled
              type="number"
              value={this.getZoomLevel(layer.ZoomLevelId).ZoomTo || MAX_ZOOM_LEVEL}
            />
          ) : (
            <MutableInput item={layer} property={'ZoomTo'}
              inputType={'number'}
              defaultValue="0"
              inputProps={{ step: ZOOM_STEP, min: 0, max: MAX_ZOOM_LEVEL }}
              onChange={this.onLayerChanged.bind(this, layer)}
            />
          )),
          zoom_level: layer => (
            <MutableInput item={layer} property={'ZoomLevelId'}
              inputType={'select'}
              options={
                [{ key: null, value: null, text: '-' }]
                  .concat(this.props.zoomlevels.map(zl => ({
                    key: zl.Id, value: zl.Id, text: `${zl.Name}`,
                  })))
              }
              onChange={this.onLayerChanged.bind(this, layer)}
            />
          ),
        }}
        buttons={[
          {
            content: 'merge_layers',
            onClick: this.mergeLayers.bind(this),
            disabled: (this.getCurrentVersion() || {}).Status !== PARSER_STATUS_READY &&
              (this.getCurrentVersion() || {}).Status !== PARSER_STATUS_ERROR,
            icon: 'image',
          },
        ]}
      />

  </div>
    );
  }

  renderVersions() {
    return (
      <Form onSubmit={e => e.preventDefault()} className="form-selector">
        <Form.Group inline>
          <Form.Field>
            {
              (this.props.versionsStatus !== VERSIONS_STATUS_READY
                && this.props.zoomlevelsStatus !== ZOOMLEVEL_STATUS_READY) ?
                <Loader active inline size="small" /> :
                <Dropdown selection
                  multiple={false}
                  placeholder={t('select_version')}
                  options={this.props.versions.map(c => ({
                    key: c.Id,
                    value: c.Id,
                    text: `${t('version')} ${c.VersionNumber} (${t(`drawing_parser_status_${c.Status}`)})`,
                  }))}
                  value={this.state.versionId}
                  onChange={this.onVersionChanged.bind(this)}
                />
            }
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

DrawingLayers.propTypes = {
  drawing: PropTypes.object.isRequired,
  // State Props
  status: PropTypes.string.isRequired,
  listMessages: PropTypes.array,
  items: PropTypes.array,
  totalItems: PropTypes.number,
  // Version
  versions: PropTypes.array,
  versionsStatus: PropTypes.string,
  versionMessages: PropTypes.array,
  // Zoom Levels
  zoomlevels: PropTypes.array,
  zoomlevelsStatus: PropTypes.string,
  // State Methods
  fetchLayers: PropTypes.func,
  mergeLayers: PropTypes.func,
  tileLayers: PropTypes.func,
  updateLayer: PropTypes.func,
  fetchVersions: PropTypes.func,
  fetchZoomLevels: PropTypes.func,
};

const mapStateToProps = state => ({
  // LayersList
  status: state.api.drawinglayers.status,
  listMessages: state.api.drawinglayers.messages,
  items: state.api.drawinglayers.list.items.map(id => (state.api.drawinglayers.index[id])),
  totalItems: state.api.drawinglayers.list.total,
  // Versions List (for dropdown)
  versionsStatus: state.api.drawingversions.status,
  versions: state.api.drawingversions.list.items.map(id => (state.api.drawingversions.index[id])),
  versionMessages: state.api.drawingversions.messages,
  // Zoom Levels
  zoomlevelsStatus: state.api.zoomlevels.status,
  zoomlevels: state.api.zoomlevels.list.items.map(id => (state.api.zoomlevels.index[id])),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchLayers,
  updateLayer,
  fetchVersions,
  fetchVersion,
  mergeVersionLayers,
  tileVersionLayers,
  fetchZoomLevels,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DrawingLayers);
