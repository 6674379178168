// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/common',
    clientId: '00583092-2594-4ba7-b678-950422a062a5',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    navigateToLoginRequestUrl: false,
  },
};

const authenticationParameters = {
  scopes: ['user.read', 'profile'],
};


export default new MsalAuthProvider(config, authenticationParameters, LoginType.Popup);
