import React, { Component } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { t } from 'i18next';
import ContainedLayout from '../components/layout/ContainedLayout';
import DrawingList from '../components/drawings/DrawingsList';

class Drawings extends Component {
  render() {
    return (
      <ContainedLayout>
        <div className="Drawings">
          <Container>
            <Segment vertical>
              <h1>{t('drawings')}</h1>
              <DrawingList />
            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

export default Drawings;
