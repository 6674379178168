import { createAPIActions } from '../../lib/redux';

const {
  fetchListDispatcher,
  fetchItemDispatcher,
  createItemDispatcher,
  updateItemDispatcher,
  deleteItemDispatcher,
} = createAPIActions('users');

export const fetchUsers = fetchListDispatcher;
export const fetchUser = fetchItemDispatcher;
export const updateUser = updateItemDispatcher;
export const deleteUser = deleteItemDispatcher;
export const createUser = createItemDispatcher;
