import React, { Component } from 'react';
import { t } from 'i18next';
import { Segment, Container } from 'semantic-ui-react';
import ContainedLayout from '../components/layout/ContainedLayout';
import CompanyList from '../components/companies/CompanyList';
import PermissionsCheck, { ENTITY_NAMES, PERMISSION_VALUES } from '../components/generic/PermissionsCheck';

class Companies extends Component {
  render() {
    return (
      <ContainedLayout>
        <div className="Companies">
          <Container>
            <Segment vertical>
              <h1>{t('companies')}</h1>
              <PermissionsCheck
              entityName={ENTITY_NAMES.COMPANY}
              requiredPermission={PERMISSION_VALUES.CONTRIBUTE}
              anyPropertyValue={true}
              showUnauthorized={true}
              >
                <CompanyList />
              </PermissionsCheck>
            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

export default Companies;
