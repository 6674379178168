import React, { Component } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { t } from 'i18next';

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <Segment vertical>
          <Container className="center aligned">
            &copy; {t('copyright_info')}
          </Container>
        </Segment>
      </footer>
    );
  }
}
