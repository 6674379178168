import React, { Component } from "react";
import { Segment, Container } from "semantic-ui-react";
import { t } from "i18next";
import ContainedLayout from "../components/layout/ContainedLayout";
import LoginForm from "../components/users/LoginForm";

class Login extends Component {
  render() {
    return (
      <ContainedLayout>
        <div className="Login">
          <Container text>
            <Segment vertical>
              <h1>{t("login")}</h1>
              <LoginForm />
            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

export default Login;
