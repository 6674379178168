import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Segment } from 'semantic-ui-react';

import DataTable from '../../generic/DataTable';

export default class ViewerTags extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: 1, pageSize: 100 };
  }

  getType(tag) {
    return ((this.props.drawing || {}).TagTypes || [])
      .find(type => tag.Name && tag.Name.trim().match(new RegExp(type.Regex)));
  }

  // Ui callbacks --------------------------
  onTagClicked(tag) {
    if (this.props.onTagClicked) {
      this.props.onTagClicked(tag);
    }
  }

  onPagingChanged(currentPage, pageSize) {
    this.setState({ pageSize, currentPage });
  }

  // Ui --------------------------
  render() {
    const tags = this.props.tags.filter(tag => this.getType(tag));

    return (
      <Segment vertical className="ViewerTags">
        <h2>{t('tags')}</h2>
        <DataTable
          totalItems={tags.length}
          items={tags.slice(
            (this.state.currentPage - 1) * this.state.pageSize,
            this.state.currentPage * this.state.pageSize,
          )}
          pageSize={this.state.pageSize}
          onPagingChanged={this.onPagingChanged.bind(this)}
          disablePageSizeSelection={true}
          currentPage={this.state.currentPage}
          idField="Id"
          fields={{
            name: tag => (
              <a
                href={`?tag=${encodeURIComponent(tag.Name)}`}
                onClick={(e) => { e.preventDefault(); this.onTagClicked(tag); }}
              >
                {tag.Name}
              </a>
            ),
            type: tag => (this.getType(tag) || {}).Name,
          }}
        />
      </Segment>
    );
  }
}

ViewerTags.propTypes = {
  // Params
  tags: PropTypes.array,
  drawing: PropTypes.object,
  // callback func
  onTagClicked: PropTypes.func,
};
