import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { fetchTags } from '../../actions/api/tags';
import DrawingViewBox from './canvas/DrawingViewBox';
import DrawingPanels from './DrawingPanels';
import { DRAWINGS_STATUS_READY } from '../../reducers/api/drawings';
import { VERSIONS_STATUS_READY } from '../../reducers/api/drawingversions';
import { fetchVersion } from '../../actions/api/drawingversions';
import { fetchComments } from '../../actions/api/comments';
import { fetchDrawing } from '../../actions/api/drawings';

import '../../styles/DrawingViewer.css';

class DrawingViewer extends Component {
  componentDidMount() {
    this.props.fetchVersion();
    this.props.fetchTags();
    this.props.fetchDrawing();
    if (this.props.features.Comments) this.props.fetchComments();
  }

  componentDidUpdate(props) {
    if (this.props.drawingVersionId !== props.drawingVersionId) {
      this.props.fetchVersion();
      this.props.fetchTags();
      if (this.props.features.Comments) {
        this.props.fetchComments();
      }
    }
    if (this.props.drawingId !== props.drawingId) {
      this.props.fetchDrawing();
    }
  }

  getBackgroundColor() {
    return this.props.drawingVersion !== undefined &&
      Number.isFinite(this.props.drawingVersion.BackgroundColor) &&
      this.props.drawingVersion.BackgroundColor < 65535 ? '#000' : '#fff';
  }

  render() {
    return (
      <div className="DrawingViewer">
        <DrawingViewBox
          hasMergedLayers={(this.props.drawingVersion || {}).HasMergedLayers}
          hasJpgTiles={(this.props.drawingVersion || {}).HasJpgTiles}
          hasSvgTiles={(this.props.drawingVersion || {}).HasSvgTiles}
          drawingScale={(this.props.drawingVersion || {}).Scale || 1}
          drawingId={(this.props.drawing || {}).Id}
          drawing={this.props.drawing}
          drawingVersionId={(this.props.drawingVersion || {}).Id}
          backgroundColor={this.getBackgroundColor()}
          tagId={this.props.tagId}
          tags={this.props.tags}
        />
        <DrawingPanels
          drawingId={(this.props.drawing || {}).Id}
          drawingVersionId={(this.props.drawingVersion || {}).Id}
          drawing={this.props.drawing}
          comments={this.props.comments}
          isAddingComment={this.props.addingCommentRect
            || this.props.addingCommentDot
            || this.props.addingCommentFree}
          showComments={this.props.showComments}
          tags={this.props.tags}
        />
        <Dimmer active={!this.props.ready} inverted>
          <Loader inverted>{t(this.props.status)}</Loader>
        </Dimmer>
      </div>
    );
  }
}

DrawingViewer.propTypes = {
  drawingId: PropTypes.number.isRequired,
  drawingVersionId: PropTypes.number.isRequired,
  drawingVersion: PropTypes.object,
  ready: PropTypes.bool.isRequired,
  fetchVersion: PropTypes.func,
  fetchTags: PropTypes.func,
  tags: PropTypes.array,
  fetchComments: PropTypes.func,
  comments: PropTypes.array,
  features: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  drawingVersion: state.api.drawingversions.index[props.drawingVersionId],
  drawing: state.api.drawings.index[props.drawingId],
  ready: state.api.drawingversions.status === VERSIONS_STATUS_READY
    && state.api.drawings.status === DRAWINGS_STATUS_READY,
  tags: state.api.tags.list.items
    .map(id => (state.api.tags.index[id]))
    .sort((a, b) => a.Name.localeCompare(b.Name)),
  comments: state.api.comments.list.items.map(id => (state.api.comments.index[id])),
  features: (state.api.configuration.index || {}).Features || {},
  ...state.ui.viewer,
});

const mapDispatchToProps = (dispatch, props) => bindActionCreators({
  fetchVersion: () => fetchVersion(props.drawingVersionId),
  fetchTags: () => fetchTags({ VersionId: props.drawingVersionId }),
  fetchComments: () => fetchComments({ VersionId: props.drawingVersionId }),
  fetchDrawing: () => fetchDrawing(props.drawingId),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DrawingViewer);
