import {
  API_STATUS_READY,
  API_STATUS_CREATING,
  createAPIReducer,
} from '../../lib/redux';

export const DRAWINGS_STATUS_READY = API_STATUS_READY;
export const DRAWINGS_STATUS_CREATING = API_STATUS_CREATING;

const reducer = createAPIReducer('drawings');

export default reducer;
