import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { GROUP_STATUS_READY } from '../../reducers/api/groups';
import { fetchGroups, deleteGroup } from '../../actions/api/groups';

import DataTable, { DEFAULT_PAGE_SIZE } from '../generic/DataTable';
import DataTableActions from '../generic/DataTableActions';
import GroupEditor from './GroupEditor';

class GroupList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      isCreating: false,
    };
  }

  fetchGroups() {
    this.props.fetchGroups({
      Filter: this.state.searchTerm,
      PageSize: this.state.pageSize,
      PageNumber: this.state.currentPage,
      CompanyId: this.props.companyId || 0,
    });
  }

  componentDidMount() {
    this.fetchGroups();
    this.setFields();
  }

  setFields() {
    if (this.props.onRowChecked) {
      this.fields = { name: 'Name' };
    } else {
      this.fields = {
        name: 'Name',
        description: 'Description',
        actions: group => (
          <DataTableActions
            item={group}
            onDelete={this.deleteGroup.bind(this)}
            onEdit={this.editGroup.bind(this)}
            deletionLabel="group_deletion"
            deletionMessage="confirm_group_deletion"
          />
        ),
      };
    }
  }

  editGroup(group) {
    this.setState({ groupToEdit: group });
  }

  onEditorClose() {
    this.setState({ groupToEdit: null, isCreating: false });
    this.fetchGroups();
  }

  deleteGroup(group) {
    this.props.deleteGroup(group);
  }

  addGroup() {
    this.setState({ isCreating: true });
  }

  onPagingChanged(newPage, pageSize) {
    this.setState({
      pageSize,
      currentPage: newPage,
    }, this.fetchGroups.bind(this));
  }

  onSearchChanged(searchTerm) {
    this.setState({ searchTerm, currentPage: 1 }, this.fetchGroups.bind(this));
  }

  // Pass group from group id (users selection)
  onRowChecked(groupId, isChecked) {
    const group = this.props.groups.find(g => g.Id === parseInt(groupId, 10));
    this.props.onRowChecked(group, isChecked);
  }

  render() {
    return (
      <div className="GroupList">
        <DataTable
          items={this.props.groups}
          totalItems={this.props.totalItems}
          status={this.props.listStatus}
          statusReady={GROUP_STATUS_READY}
          onSearchChanged={this.onSearchChanged.bind(this)}
          onPagingChanged={this.onPagingChanged.bind(this)}
          pageSize={this.state.pageSize}
          currentPage={this.state.currentPage}
          addNewItem = {!this.props.onRowChecked ?
            this.addGroup.bind(this) :
            null
          }
          addItemLabel = {t('add_group')}
          idField="Id"
          messages={this.props.listMessages}
          onRowChecked = {this.props.onRowChecked ?
            this.onRowChecked.bind(this) :
            null
          }
          selectedItemIds = {this.props.selectedGroupIds}
          fields={this.fields}
        />

        <GroupEditor
          groupId={this.state.groupToEdit ? this.state.groupToEdit.Id : 0}
          isCreate={this.state.isCreating}
          companyId={this.props.companyId}
          onModalClose={this.onEditorClose.bind(this)}
        />
      </div>
    );
  }
}

GroupList.propTypes = {
  // Params
  companyId: PropTypes.number,
  // User's group Selection
  onRowChecked: PropTypes.func,
  selectedGroupIds: PropTypes.array,
  // State
  goto: PropTypes.func.isRequired,
  listStatus: PropTypes.string.isRequired,
  listMessages: PropTypes.array,
};

const mapStateToProps = state => ({
  listStatus: state.api.groups.status,
  listMessages: state.api.groups.messages,
  groups: state.api.groups.list.items.map(id => (state.api.groups.index[id])),
  totalItems: state.api.groups.list.total,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  goto: page => push(page),
  fetchGroups,
  deleteGroup,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GroupList);

