import {
  API_STATUS_READY,
  API_STATUS_CREATING,
  createAPIReducer,
} from '../../lib/redux';

export const VERSIONS_STATUS_READY = API_STATUS_READY;
export const VERSIONS_STATUS_CREATING = API_STATUS_CREATING;

const reducer = createAPIReducer('drawingversions');

export default reducer;
