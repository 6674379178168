import { t } from 'i18next';

import {
  ACTION_LOGIN,
  ACTION_LOGOUT,
  ACTION_FETCH_USER,
  ACTION_CLEAR_MESSAGES,
  ACTION_CHANGE_PASSWORD,
  ACTION_FORGOT_PASSWORD,
  ACTION_RESET_PASSWORD,
  ACTION_LOGIN_REQUEST,
  ACTION_LOGIN_FAILURE,
  ACTION_LOGOUT_REQUEST,
  ACTION_LOGOUT_FAILURE,
  ACTION_FETCH_USER_REQUEST,
  ACTION_FETCH_USER_FAILURE,
  ACTION_CHANGE_PASSWORD_REQUEST,
  ACTION_CHANGE_PASSWORD_FAILURE,
  ACTION_FORGOT_PASSWORD_REQUEST,
  ACTION_FORGOT_PASSWORD_FAILURE,
  ACTION_RESET_PASSWORD_REQUEST,
  ACTION_RESET_PASSWORD_FAILURE,
} from '../../actions/api/account';

const KEY_ACCOUNT_TOKEN = 'account.token';

export const ACCOUNT_STATUS_READY = 'ready';
export const ACCOUNT_STATUS_FETCHING = 'fetching_user';
export const ACCOUNT_STATUS_UPDATING = 'updating_user';
export const ACCOUNT_STATUS_LOGIN = 'logging_in';
export const ACCOUNT_STATUS_LOGOUT = 'logging_out';
export const ACCOUNT_STATUS_REGISTRATION = 'registering';
export const ACCOUNT_STATUS_PASSCHANGE = 'changing_password';
export const ACCOUNT_STATUS_FORGOT_PASS = 'sending_forgot_pass';
export const ACCOUNT_STATUS_RESET_PASS = 'reseting_pass';

function getStoredToken() {
  try {
    return JSON.parse(localStorage.getItem(KEY_ACCOUNT_TOKEN));
  } catch (ignore) {
    return null;
  }
}

function storeToken(token) {
  try {
    if (token) {
      localStorage.setItem(KEY_ACCOUNT_TOKEN, JSON.stringify(token));
    } else {
      localStorage.removeItem(KEY_ACCOUNT_TOKEN);
    }
    return getStoredToken();
  } catch (ignore) {
    return null;
  }
}


const initialState = {
  user: null,
  token: getStoredToken(),
  status: ACCOUNT_STATUS_READY,
  messages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_FETCH_USER_REQUEST:
      return { ...state, status: ACCOUNT_STATUS_FETCHING };

    case ACTION_FETCH_USER:
      return { ...state, status: ACCOUNT_STATUS_READY, user: action.result };

    case ACTION_FETCH_USER_FAILURE:
      return {
        ...state,
        status: ACCOUNT_STATUS_READY,
        messages: action.silent ? [] : [action.message],
      };

    case ACTION_LOGIN_REQUEST:
      return { ...state, status: ACCOUNT_STATUS_LOGIN, messages: [] };

    case ACTION_LOGIN:
      storeToken(action.result);
      return {
        ...state,
        status: ACCOUNT_STATUS_READY,
        token: action.result,
        messages: [],
      };

    case ACTION_LOGIN_FAILURE:
      return {
        ...state,
        status: ACCOUNT_STATUS_READY,
        messages: action.silent ? [] : [action.message],
      };

    case ACTION_LOGOUT_REQUEST:
      return { ...state, status: ACCOUNT_STATUS_LOGOUT, messages: [] };

    case ACTION_LOGOUT:
      storeToken(null);
      localStorage.clear();
      return {
        ...state,
        status: ACCOUNT_STATUS_READY,
        token: null,
        user: null,
      };

    case ACTION_LOGOUT_FAILURE:
      return { ...state, status: ACCOUNT_STATUS_READY, messages: [action.message] };

    case ACTION_CLEAR_MESSAGES:
      return { ...state, messages: [] };

    case ACTION_CHANGE_PASSWORD:
      return {
        ...state,
        status: ACCOUNT_STATUS_READY,
        messages: [{
          type: 'success',
          body: t('change_password_success'),
        }],
      };

    case ACTION_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        status: ACCOUNT_STATUS_PASSCHANGE,
      };

    case ACTION_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        status: ACCOUNT_STATUS_READY,
        messages: [action.message],
      };

    case ACTION_FORGOT_PASSWORD:
      return {
        ...state,
        status: ACCOUNT_STATUS_READY,
        messages: [{
          type: 'success',
          body: t('forgot_password_success'),
        }],
      };

    case ACTION_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        status: ACCOUNT_STATUS_FORGOT_PASS,
      };

    case ACTION_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        status: ACCOUNT_STATUS_READY,
        messages: [action.message],
      };

    case ACTION_RESET_PASSWORD:
      return {
        ...state,
        status: ACCOUNT_STATUS_READY,
        messages: [{
          type: 'success',
          body: t('reset_pass_success'),
        }],
      };

    case ACTION_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        status: ACCOUNT_STATUS_RESET_PASS,
      };

    case ACTION_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        status: ACCOUNT_STATUS_READY,
        messages: [action.message],
      };
    default:
      return state;
  }
};
