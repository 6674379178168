import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Dropdown, Loader, Form } from 'semantic-ui-react';
import DataTable, { DEFAULT_PAGE_SIZE } from '../generic/DataTable';
import DataTableActions from '../generic/DataTableActions';
import { COMMENTS_STATUS_READY } from '../../reducers/api/comments';
import { fetchComments, deleteComment } from '../../actions/api/comments';
import { VERSIONS_STATUS_READY } from '../../reducers/api/drawingversions';
import { fetchVersions } from '../../actions/api/drawingversions';

import CommentEditor from './CommentEditor';

class CommentsList extends Component {
  // Component Methods --------------------
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      versionId: this.props.drawing ? this.props.drawing.ActiveVersionId : null,
    };
  }

  componentDidMount() {
    this.loadList();
    if (!this.props.userId) {
      this.props.fetchVersions({ DrawingId: this.props.drawing.Id });
    }
  }

  componentDidUpdate(props) {
    if (props.userId !== this.props.userId) {
      this.loadList();
      if (!this.props.userId) {
        this.props.fetchVersions({ DrawingId: this.props.drawing.Id });
      }
    }
  }

  // Ui Callbacks --------------------
  onPagingChanged(newPage, pageSize) {
    this.setState({
      pageSize,
      currentPage: newPage,
    }, this.loadList.bind(this));
  }

  onSearchChanged(searchTerm) {
    this.setState({ searchTerm, currentPage: 1 }, this.loadList.bind(this));
  }

  onVersionChanged(event, data) {
    this.setState({ versionId: data.value }, this.loadList.bind(this));
  }

  // Implementation --------------------
  loadList() {
    this.props.fetchComments({
      Filter: this.state.searchTerm,
      PageSize: this.state.pageSize,
      PageNumber: this.state.currentPage,
      ApplicationUser_Id: this.props.userId || undefined,
      VersionId: this.state.versionId || undefined,
    });
  }

  onEditComment(comment) {
    this.setState({ editing: comment.Id });
  }

  onStopEdit() {
    this.setState({ editing: undefined });
  }

  // Ui --------------------
  render() {
    return (
    <div className="CommentsList">
      {this.renderVersionSelection()}
    <DataTable
      items={this.props.comments}
      totalItems={this.props.totalItems}
      status={this.props.listStatus}
      statusReady={COMMENTS_STATUS_READY}
      onSearchChanged = {this.onSearchChanged.bind(this)}
      onPagingChanged={this.onPagingChanged.bind(this)}
      pageSize={this.state.pageSize}
      currentPage={this.state.currentPage}
      idField="Id"
      messages={this.props.listMessages}
      fields={{
        title: 'Title',
        category: 'Category',
        added_by: 'CreatorName',
        added_on: 'CreatedAt',
        modified_by: 'ModifierName',
        modified_on: 'ModifiedAt',
        actions: comment => ([
          <CommentEditor key="editor"
            open={this.state.editing === comment.Id}
            onCancel={this.onStopEdit.bind(this)}
            onClose={this.onStopEdit.bind(this)}
            comment={comment}
          />,
          <DataTableActions
            key="actions"
            item={comment}
            onDelete={this.props.deleteComment}
            onEdit={this.onEditComment.bind(this, comment)}
            deletionLabel="comment_deletion"
            deletionMessage="confirm_comment_deletion"
          />,
        ]),
      }}
    />
  </div>);
  }

  renderVersionSelection() {
    if (this.props.userId) {
      return null;
    }
    return (
      <Form onSubmit={e => e.preventDefault()} className="form-selector">
        <Form.Group inline>
          <Form.Field>
            {
            this.props.versionsStatus !== VERSIONS_STATUS_READY ?
              <Loader active inline size="small" /> :
              <Dropdown selection
                multiple={false}
                placeholder={t('select_version')}
                options={this.props.versions.map(c => ({
                  key: c.Id,
                  value: c.Id,
                  text: `${t('version')} ${c.VersionNumber} (${t(`drawing_parser_status_${c.Status}`)})`,
                }))}
                value={this.state.versionId}
                onChange={this.onVersionChanged.bind(this)}
              />
            }
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

CommentsList.propTypes = {
  // whose comments we are listing
  userId: PropTypes.string,
  drawing: PropTypes.object,
  // general
  listStatus: PropTypes.string.isRequired,
  listMessages: PropTypes.array,
  // Versions
  versions: PropTypes.array,
  versionsStatus: PropTypes.string,
  fetchVersions: PropTypes.func,
};

const mapStateToProps = state => ({
  listStatus: state.api.comments.status,
  listMessages: state.api.comments.messages,
  comments: state.api.comments.list.items.map(id => (state.api.comments.index[id])),
  totalItems: state.api.comments.list.total,
  // Versions
  versionsStatus: state.api.drawingversions.status,
  versions: state.api.drawingversions.list.items
    .map(id => (state.api.drawingversions.index[id])),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchComments,
  fetchVersions,
  deleteComment,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CommentsList);
