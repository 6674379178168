import React, { Component } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { NavLink, withRouter } from 'react-router-dom';
import ContainedLayout from '../components/layout/ContainedLayout';
import DrawingEditor from '../components/drawings/DrawingEditor';

class DrawingEdit extends Component {
  render() {
    const drawing = this.props.drawing || { Id: this.props.match.params.drawingId };
    const { parentId } = this.props.match.params;
    const { parent } = this.props;

    if (!drawing.Id) {
      return null;
    }
    return (
      <ContainedLayout>
        <div className="DrawingEdit">
          <Container>
            <Segment vertical>
              {
                this.props.match.params && this.props.match.params.drawingId ?
                  <h1>
                    <NavLink to="/drawings">{t('drawings')}</NavLink>
                    {' / '}
                    {parent && (<NavLink to={`/drawings/${parentId}`}>{parent.Name || parent.Id}</NavLink>)}
                    {parent && ' / '}
                    {drawing.Id === 'create' ? t('add') : (drawing.Name || drawing.Id)}
                  </h1>
                :
                  <h1>{t('drawing')}</h1>
              }
              <DrawingEditor
                drawingId={String(drawing.Id)}
                parentId={parentId && parseInt(parentId, 10)}
              />
            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

DrawingEdit.propTypes = {
  match: PropTypes.object.isRequired,
  drawing: PropTypes.object,
  parent: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  drawing: state.api.drawings.index[props.match.params.drawingId],
  parent: state.api.drawings.index[props.match.params.parentId],
});

export default withRouter(connect(mapStateToProps, null)(DrawingEdit));
