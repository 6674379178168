import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateTag } from '../../actions/api/tags';
import PermissionsCheck, { ENTITY_NAMES, PERMISSION_VALUES } from '../generic/PermissionsCheck';
import MessageList from '../generic/MessageList';
import ViewerComments from '../drawings/sidebar/ViewerComments';


class TagEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      description: this.props.tag.Description,
    };
    this.canEdit = PermissionsCheck.userHasPermission(
      this.props.user,
      ENTITY_NAMES.DRAWING,
      PERMISSION_VALUES.CONTRIBUTE,
      this.props.drawingId,
    );
  }

  getUrl() {
    return `${window.location.origin}/view/${this.props.drawingId}/${this.props.drawingVersionId}?tagId=${this.props.tag.Id}`;
  }

  getType() {
    return (this.props.tagTypes || []).find(type =>
      this.props.tag.Name &&
      this.props.tag.Name.trim().match(new RegExp(type.Regex)));
  }

  copyToClipboard() {
    const el = document.createElement('textarea');
    el.value = this.getUrl();
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  static openURL(url) {
    if (
      window.webkit && window.webkit.messageHandlers
        && window.webkit.messageHandlers.native
        && typeof window.webkit.messageHandlers.native.postMessage === 'function'
    ) {
      window.webkit.messageHandlers.native.postMessage(JSON.stringify({
        method: 'openUrl',
        param: url,
      }));
    } else if (window.Android && window.Android.OpenUrl) {
      window.Android.OpenUrl(url);
    }
  }

  sendMail() {
    window.open(`mailto:?body=${encodeURIComponent(this.getUrl())}&subject=${encodeURIComponent(this.props.tag.Name)}`);
  }

  renderTagLink() {
    const tagType = this.getType();
    if (!tagType || !tagType.Url) return null;

    const href = tagType.Url.replace('{0}', this.props.tag.Name);
    const title = (tagType.UrlTitle || tagType.Url).replace('{0}', this.props.tag.Name);
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => TagEditor.openURL(href)}
        href={href}
      >
        {title}
      </a>
    );
  }

  render() {
    const tagType = this.getType();
    return (
      <Modal
        trigger={this.props.trigger}
        open={this.props.open}
        onClose={this.props.onClose}
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <Modal.Header>
          {this.props.tag.Name}
          <Button
            basic compact icon
            onClick={this.copyToClipboard.bind(this)}
            style={{ float: 'right' }}
            data-inverted
            data-tooltip={t('copy_to_clipboard')}
          >
            <Icon name="clipboard" />
          </Button>
          <Button
            basic compact icon
            style={{ float: 'right' }}
            onClick={this.sendMail.bind(this)}
            data-inverted
            data-tooltip={t('send_by_email')}
          >
            <Icon name="mail" />
          </Button>
        </Modal.Header>
        <Modal.Content>
          <MessageList messages={this.props.messages} />
          {this.renderTagLink()}
          {this.props.comments && this.props.comments.length > 0 && (
            <ViewerComments
              comments={this.props.comments}
              isAddingComment={false}
            />
          )}
          {tagType && (
            <div className="info">
              <span className="info-item">
                {t('link_type')}: {tagType.Name}
              </span>
            </div>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

TagEditor.propTypes = {
  tag: PropTypes.object,
  tagTypes: PropTypes.array,
  comments: PropTypes.array,
  drawingId: PropTypes.number,
  drawingVersionId: PropTypes.number,
  trigger: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
  status: PropTypes.string,
  messages: PropTypes.array,
  updateTag: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  status: state.api.tags.status,
  messages: state.api.tags.messages,
  user: state.api.account.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateTag,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TagEditor);
