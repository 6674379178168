import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown, Input, Table, Dimmer, Loader, Menu, Icon, Button, Checkbox,
} from 'semantic-ui-react';
import i18next, { t } from 'i18next';
import MessageList from './MessageList';
import '../../styles/DataTable.css';

export const DEFAULT_PAGE_SIZE = 10;
export const MEDIUM_PAGE_SIZE = 20;
export const LARGE_PAGE_SIZE = 50;
export const PAGE_SIZES = [
  DEFAULT_PAGE_SIZE,
  MEDIUM_PAGE_SIZE,
  LARGE_PAGE_SIZE,
];

const SEARCH_TIMEOUT = 750;
const DATE_FORMAT = 'yyyy/mm/dd';

export default class DataTable extends Component {
  getTotalPages() {
    return Math.ceil(this.props.totalItems / this.props.pageSize);
  }

  onPageChanged(offset) {
    const newPage = this.props.currentPage + offset;
    if (this.props.onPagingChanged) this.props.onPagingChanged(newPage, this.props.pageSize);
  }

  onPageSizeChanged(event, data) {
    const newPageSize = parseInt(data.value, 10);
    if (this.props.onPagingChanged) {
      this.props.onPagingChanged(1, newPageSize); // return to first page
    }
  }

  onSearchChanged(event) {
    clearTimeout(this.searchTimeout);
    const term = event.target.value;
    this.searchTimeout = setTimeout(() => {
      this.props.onSearchChanged(term);
    }, SEARCH_TIMEOUT);
  }

  onFilterChanged(event, data) {
    clearTimeout(this.filterTimeout);
    const { value } = data;
    this.filterTimeout = setTimeout(() => {
      this.props.onFilterDropdownChanged(value);
    }, SEARCH_TIMEOUT);
  }


  onRowChecked(itemId, event, data) {
    this.props.onRowChecked(itemId, data.checked);
  }

  static format(value) {
    if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T/)) {
      return i18next.format(new Date(value), DATE_FORMAT);
    }
    if (value instanceof Date) {
      return i18next.format(value, DATE_FORMAT);
    }
    return value;
  }

  renderTable() {
    if (!this.props.items || !this.props.fields) {
      return null;
    }

    return (
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            {this.props.onRowChecked ? <Table.HeaderCell/> : null}
            {Object.keys(this.props.fields).map(field => (
              <Table.HeaderCell key={field} className={`data-column ${field}`}>
                {t(field)}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.items.map((item, index) => (
            <Table.Row key={item[this.props.idField] || `idx-${index}`}>
              {this.renderCheckbox(item[this.props.idField])}
              {Object.keys(this.props.fields).map((field, idx) => (
                <Table.Cell key={field}>
                  {
                    typeof this.props.fields[field] === 'function' ?
                      this.props.fields[field](item, field, idx) :
                      DataTable.format(item[this.props.fields[field]])
                  }
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell
              className="pagination"
              colSpan={Object.keys(this.props.fields).length + (this.props.onRowChecked ? 1 : 0)}
            >
              {this.renderPaging()}
              {this.renderPageSelection()}
              {this.renderPagingInfo()}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }

  // Renders row's checkbox if there is an onRowCheck event
  renderCheckbox(key) {
    if (!this.props.onRowChecked) {
      return null;
    }
    return (
      <Table.Cell collapsing>
        <Checkbox
          checked={this.props.selectedItemIds && this.props.selectedItemIds.includes(key)}
          onChange={this.onRowChecked.bind(this, key)}
        />
      </Table.Cell>
    );
  }

  // Page selection Menu if more than one page
  renderPaging() {
    if (
      !this.props.items
      || this.props.totalItems === undefined
      || this.props.pageSize === undefined
    ) {
      return null;
    }

    return (
      <Menu floated='right' pagination size="tiny">
        {
          this.props.onPagingChanged && this.props.currentPage > 1 ?
            <Menu.Item as='a' icon onClick={this.onPageChanged.bind(this, -1)}>
              <Icon name='left chevron' />
            </Menu.Item>
          : null
        }

        <Menu.Item as='a'>{this.props.currentPage}</Menu.Item>

        {
          this.props.onPagingChanged && this.props.currentPage < this.getTotalPages() ?
            <Menu.Item as='a' icon onClick={this.onPageChanged.bind(this, 1)}>
              <Icon name='right chevron' />
            </Menu.Item>
          : null
        }
      </Menu>
    );
  }

  renderPagingInfo() {
    if (
      this.props.disablePagingInfo
      || this.props.pageSize === undefined
      || this.props.totalItems === undefined
    ) {
      return null;
    }

    return (
      <span className="paging-info">
        {Math.min(((this.props.currentPage - 1) * this.props.pageSize) + 1, this.props.totalItems)}
        {' - '}
        {Math.min(this.props.currentPage * this.props.pageSize, this.props.totalItems)}
        {` ${t('of').toLowerCase()} `}
        {this.props.totalItems}
      </span>
    );
  }

  // PageSize Dropdown
  renderPageSelection() {
    if (this.props.disablePageSizeSelection || this.props.pageSize === undefined) {
      return null;
    }
    return (
      <Dropdown compact selection
        onChange={this.onPageSizeChanged.bind(this)}
        value={this.props.pageSize}
        options={PAGE_SIZES.map(size => ({ key: size, value: size, text: size }))}
      />
    );
  }


  renderFilterDropdown() {
    if (!this.props.filterDropdownOptions) {
      return null;
    }

    return (
      <Dropdown placeholder={this.props.filterDropdownName}
        className='filter-dropdown'
        fluid
        multiple
        search
        selection
        options={this.props.filterDropdownOptions}
        onChange={this.onFilterChanged.bind(this)} />
    );
  }


  render() {
    return (
      <div className="DataTable">
        <MessageList messages={this.props.messages} />
        <div className="datatable-controls">
          {
            this.props.onSearchChanged ?
              <Input icon="search"
                placeholder={t('search')}
                onChange={this.onSearchChanged.bind(this)}
              />
            : null
          }
          { this.renderFilterDropdown() }
          {
            this.props.addNewItem ?
              <Button primary className="add-button" icon="plus"
                onClick={this.props.addNewItem}
                content={t(this.props.addItemLabel || 'add_item')}
              />
            : null
          }
          {(this.props.buttons || []).map((button, idx) => (
            <Button primary
              key={idx}
              className="action-button"
              icon={button.icon}
              onClick={button.onClick}
              content={t(button.content)}
              disabled={button.disabled}
            />
          ))}
        </div>
        {this.renderTable()}
        {
          this.props.statusReady && (this.props.status !== this.props.statusReady) ?
            <Dimmer active inverted>
              <Loader inverted>{t(this.props.status)}</Loader>
            </Dimmer>
          : null
        }
      </div>);
  }
}

DataTable.propTypes = {
  fields: PropTypes.object,
  idField: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  totalItems: PropTypes.number,
  status: PropTypes.string,
  statusReady: PropTypes.string,
  messages: PropTypes.array,
  onSearchChanged: PropTypes.func,
  filterDropdownName: PropTypes.string,
  filterDropdownOptions: PropTypes.array,
  onFilterDropdownChanged: PropTypes.func,
  addNewItem: PropTypes.func,
  addItemLabel: PropTypes.string,
  buttons: PropTypes.array,
  // Paging
  onPagingChanged: PropTypes.func,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  disablePageSizeSelection: PropTypes.bool,
  disablePagingInfo: PropTypes.bool,
  onRowChecked: PropTypes.func,
  selectedItemIds: PropTypes.array,
};
