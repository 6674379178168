import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import dateFormat from 'dateformat';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import Home from './pages/Home';
import Users from './pages/Users';
import UserProfile from './pages/UserProfile';
import Systems from './pages/Systems';
import SystemEdit from './pages/SystemEdit';
import Companies from './pages/Companies';
import CompanyEdit from './pages/CompanyEdit';
import Drawings from './pages/Drawings';
import DrawingEdit from './pages/DrawingEdit';
import DrawingView from './pages/DrawingView';
import Login from './pages/Login';
import Logout from './pages/Logout';
import ChangePassword from './pages/ChangePassword';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Permissions from './pages/Permissions';
import Support from './pages/Support';
import { fetchUser } from './actions/api/account';
import { fetchConfiguration } from './actions/api/configuration';
import en from './i18n/en.json';

import './styles/App.css';


/**
 * Application entry point.
 * Hosts the default layout & all routing configutation.
 */
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { ready: false };
  }

  /**
   * Runs when the component mounts.
   * Initiates current user's retrieval.
   */
  componentDidMount() {
    i18next.init(
      {
        fallbackLng: 'en',
        debug: false,
        resources: { en: { translation: en } },
        interpolation: {
          format: (value, format) => {
            if (value instanceof Date) {
              return dateFormat(value, format);
            }
            return value;
          },
        },
      },
      () => Promise.all([this.props.fetchCurrentUser(), this.props.fetchConfiguration()])
        .then(([, { result: { Information = {} } = {} } = {}]) => {
          this.setState({ ready: true });
          const title = document.createElement('title');
          title.textContent = Information.Name || '';
          document.head.append(title);
        }),
    );
  }

  /**
   * Renders the component.
   *
   * @return {Object} the React element to be rendered.
   */
  render() {
    if (!this.state.ready) {
      return (
        <Dimmer active inverted>
          <Loader active inverted />
        </Dimmer>
      );
    }

    return (
      <div className="App">
        {this.props.currentUser && (
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/users/:userId" component={UserProfile} />
            <Route exact path="/profile" component={UserProfile} />
            {this.props.features.Companies && <Route exact path="/companies" component={Companies} />}
            {this.props.features.Companies && <Route exact path="/companies/:companyId" component={CompanyEdit} />}
            <Route exact path="/drawings" component={Drawings} />
            <Route exact path="/drawings/:parentId?/:drawingId" component={DrawingEdit} />
            <Route exact path="/view/:drawingId/:drawingVersionId" component={DrawingView} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/change-password" component={ChangePassword} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/permissions" component={Permissions} />
            <Route exact path="/support" component={Support} />
            {this.props.features.DrawingSystems && <Route exact path="/systems" component={Systems} />}
            {this.props.features.DrawingSystems && <Route exact path="/systems/:systemId" component={SystemEdit} />}
          </Switch>
        )}
        {!this.props.currentUser && (
          <Switch>
            <Route exact path="/support" component={Support} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Login />
          </Switch>
        )}
      </div>
    );
  }
}

App.propTypes = {
  /** {Object} the current user */
  currentUser: PropTypes.object,
  features: PropTypes.object,
  /** {Function} action dispatcher that retrieves the current user from the API */
  fetchCurrentUser: PropTypes.func.isRequired,
};

/**
 * Maps the global `state` to component properties
 */
const mapStateToProps = state => ({
  currentUser: state.api.account.user,
  features: (state.api.configuration.index || {}).Features || {},
});

/**
 * Maps `dispatch` to component properties
 */
const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCurrentUser: () => fetchUser(true),
  fetchConfiguration: () => fetchConfiguration(),
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
