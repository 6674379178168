import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import PermissionsCheck, { ENTITY_NAMES, PERMISSION_VALUES } from '../generic/PermissionsCheck';
import DataTable, { DEFAULT_PAGE_SIZE } from '../generic/DataTable';
import DataTableActions from '../generic/DataTableActions';
import { USER_STATUS_READY } from '../../reducers/api/users';
import { fetchUsers, updateUser, deleteUser } from '../../actions/api/users';

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      pageSize: this.props.pageSize || DEFAULT_PAGE_SIZE,
      currentPage: 1,
    };
  }

  componentDidMount() {
    this.fetchUsers();
    this.setField();
  }

  setField() {
    this.fields = {
      first_name: 'FirstName',
      last_name: 'LastName',
      email: 'Email',
      groups: user => ((user.Groups || []).map(g => g.Name).join(', ')),
      actions: user => (
        <DataTableActions
          item={user}
          itemViewPath="/users"
          onDelete={
            this.props.currentUser &&
            this.props.currentUser.Id !== user.Id &&
            PermissionsCheck.userHasPermission(
              this.props.currentUser,
              ENTITY_NAMES.USER,
              PERMISSION_VALUES.CONTRIBUTE,
            ) ?
            this.deleteUser.bind(this) :
            null
          }
          deletionLabel="user_deletion"
          deletionMessage="confirm_user_deletion"
        />
      ),
    };

    if (this.props.onRowChecked) {
      delete this.fields.actions;
      delete this.fields.groups;
    }
  }

  fetchUsers() {
    this.props.fetchUsers({
      Filter: this.state.searchTerm,
      PageSize: this.state.pageSize,
      PageNumber: this.state.currentPage,
    });
  }

  addUser() {
    this.props.goto('/users/create');
  }

  editUser(user) {
    this.props.goto(`/users/${user.Id}`);
  }

  deleteUser(user) {
    this.props.deleteUser(user);
  }

  onSearchChanged(searchTerm) {
    this.setState({ searchTerm, currentPage: 1 }, this.fetchUsers.bind(this));
  }

  onPagingChanged(newPage, pageSize) {
    this.setState({ currentPage: newPage, pageSize }, this.fetchUsers.bind(this));
  }

  render() {
    return (
      <div className="UsersList">
        <PermissionsCheck
        entityName={ENTITY_NAMES.USER}
        requiredPermission={PERMISSION_VALUES.VIEW}
        anyPropertyValue={true}
        showUnauthorized={true}
        >
        <DataTable
          items={this.props.users}
          totalItems={this.props.totalUsers}
          status={this.props.listStatus}
          statusReady={USER_STATUS_READY}
          addNewItem = {
            !this.props.onRowChecked &&
            PermissionsCheck.userHasPermission(
              this.props.currentUser,
              ENTITY_NAMES.USER,
              PERMISSION_VALUES.CONTRIBUTE,
            )
            ? this.addUser.bind(this)
            : null
          }
          addItemLabel = {t('add_user')}
          onPagingChanged={this.onPagingChanged.bind(this)}
          disablePageSizeSelection={!!this.props.pageSize}
          pageSize={this.state.pageSize}
          currentPage={this.state.currentPage}
          idField="Id"
          messages={this.props.listMessages}
          onSearchChanged={this.onSearchChanged.bind(this)}
          onRowChecked = {this.props.onRowChecked ?
            this.props.onRowChecked.bind(this) :
            null
          }
          selectedItemIds = {this.props.selectedUserIds}
          fields={this.fields}
        />
        </PermissionsCheck>
      </div>
    );
  }
}

UsersList.propTypes = {
  goto: PropTypes.func.isRequired,
  listStatus: PropTypes.string.isRequired,
  listMessages: PropTypes.array,
  currentUser: PropTypes.object,
  onRowChecked: PropTypes.func,
  selectedUserIds: PropTypes.array,
  pageSize: PropTypes.number,
};

const mapStateToProps = state => ({
  listStatus: state.api.users.status,
  listMessages: state.api.users.messages,
  users: state.api.users.list.items.map(id => (state.api.users.index[id])),
  totalUsers: state.api.users.list.total,
  currentUser: state.api.account.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  goto: page => push(page),
  fetchUsers,
  deleteUser,
  updateUser,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);

