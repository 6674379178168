import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormGrid from "../../generic/FormGrid";
import { COMPANIES_STATUS_READY } from "../../../reducers/api/companies";
import { fetchCompanies } from "../../../actions/api/companies";
import PermissionsCheck, {
  ENTITY_NAMES,
  PERMISSION_VALUES,
} from "../../generic/PermissionsCheck";
import configData from "../../../config.json";
class UserInfoPanel extends Component {
  componentDidMount() {
    this.props.fetchCompanies();
  }

  onResetPasswordClicked(event) {
    event.preventDefault();
    if (this.props.resetPassword) {
      this.props.resetPassword();
    }
  }

  getEditableFields() {
    const fields = {
      FirstName: { label: "first_name", type: "text" },
      LastName: { label: "last_name", type: "text" },
      UserName: {
        label: "username",
        type: "text",
        required: true,
        readOnly: this.props.user && this.props.user.Id,
      },
      Email: {
        label: "email",
        type: "email",
        required: true,
      },
      PhoneNumber: { label: "phone", type: "text" },
    };

    // Check permissions for changing user's company
    if (
      (!this.props.user ||
        !this.props.user.Id || // Creating User
        PermissionsCheck.userHasPermission(
          // updating user's company
          this.props.loggedUser,
          ENTITY_NAMES.COMPANY,
          PERMISSION_VALUES.CONTRIBUTE,
          this.props.user.CompanyId
        )) &&
      this.props.features.Companies
    ) {
      fields.CompanyId = {
        label: "company",
        type: "select",
        required: true,
        options: this.props.companies.map((c) => ({
          key: c.Id,
          value: c.Id,
          text: c.Name,
        })),
        ready: this.props.companiesStatus === COMPANIES_STATUS_READY,
      };
    }

    // Password / Reset Password
    if (!this.props.user || !this.props.user.Id) {
      // if user doesn't exist, show password
      fields.Password = {
        label: "password",
        type: "password",
        required: true,
      };
      fields.ConfirmPassword = {
        label: "confirm_password",
        type: "password",
        required: true,
      };
    } else {
      fields.ResetPassword = {
        label: "reset_password",
        type: "button",
        onClick: this.onResetPasswordClicked.bind(this),
      };
    }

    return [fields, { Image: { label: "image", type: "image" } }];
  }

  renderForm() {
    return (
      <FormGrid
        item={
          this.props.user &&
          this.props.user.Id && {
            ...this.props.user,
            Image:
              this.props.user && this.props.user.Image
                ? this.props.user.Image
                : `${configData.BackendUrl}/api/users/${this.props.user.Id}/image`,
          }
        }
        onSubmit={this.props.onSubmit}
        onCancel={this.props.onCancel}
        messages={this.props.messages}
        status={this.props.status}
        statusReady={this.props.statusReady}
        fields={this.getEditableFields()}
      />
    );
  }

  render() {
    return <div className="UserInfoPanel">{this.renderForm()}</div>;
  }
}

UserInfoPanel.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  resetPassword: PropTypes.func,
  user: PropTypes.object,
  features: PropTypes.object,
  status: PropTypes.string,
  statusReady: PropTypes.string.isRequired,
  messages: PropTypes.array,
  onCancel: PropTypes.func,
  // From State
  loggedUser: PropTypes.object,
  companies: PropTypes.array.isRequired,
  companiesStatus: PropTypes.string,
  fetchCompanies: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedUser: state.api.account.user,
  companies: state.api.companies.list.items.map(
    (id) => state.api.companies.index[id]
  ),
  companiesStatus: state.api.companies.status,
  features: (state.api.configuration.index || {}).Features || {},
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCompanies,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoPanel);
