import {
  API_STATUS_READY,
  API_STATUS_CREATING,
  createAPIReducer,
} from '../../lib/redux';

const reducer = createAPIReducer('drawingtags');

export default reducer;

export const TAGS_STATUS_READY = API_STATUS_READY;
export const TAGS_STATUS_CREATING = API_STATUS_CREATING;

