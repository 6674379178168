import {
  createAPIActions,
  createAPIActionDispatcher,
} from '../../lib/redux';

const {
  fetchListDispatcher,
  fetchItemDispatcher,
  createItemDispatcher,
  updateItemDispatcher,
  deleteItemDispatcher,
} = createAPIActions('comments');

export const fetchComments = fetchListDispatcher;
export const fetchComment = fetchItemDispatcher;
export const updateComment = updateItemDispatcher;
export const deleteComment = deleteItemDispatcher;
export const createComment = createItemDispatcher;

export const ACTION_COMMENT_HISTORY = 'comment/HISTORY';

export function fetchCommentHistory(commentId) {
  return createAPIActionDispatcher(ACTION_COMMENT_HISTORY, { }, {
    uri: `/api/Comments/${commentId}/history`,
  });
}
