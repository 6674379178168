import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import FormGrid from '../generic/FormGrid';
import { ACCOUNT_STATUS_READY } from '../../reducers/api/account';
import { forgotPassword } from '../../actions/api/account';


class ForgotPasswordForm extends Component {
  onCancel() {
    this.props.goto('/login');
  }

  onSubmit(state) {
    const emailProvided = state.username && state.username.includes('@');
    const requestData = {
      email: emailProvided ? state.username : undefined,
      username: emailProvided ? undefined : state.api.username,
      callbackUrl: `${window.location.origin}/reset-password`,
    };
    this.props.forgotPassword(requestData);
  }

  render() {
    return (
      <FormGrid
      onSubmit={this.onSubmit.bind(this)}
      onCancel={this.onCancel.bind(this)}
      submitLabel={t('send_email')}
      fields={[{
        username: { label: 'email_username', type: 'text', required: true },
      }]}
      status={this.props.status}
      statusReady={ACCOUNT_STATUS_READY}
      messages={this.props.messages}
      />
    );
  }
}

ForgotPasswordForm.propTypes = {
  // System
  goto: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  // State
  status: PropTypes.string,
  messages: PropTypes.array,
};

const mapStateToProps = state => ({
  goto: page => push(page),
  status: state.api.account.status,
  messages: state.api.account.messages,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  goto: page => push(page),
  forgotPassword,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
