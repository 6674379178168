import React, { Component } from "react";
import PropTypes from "prop-types";
import { t } from "i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Icon, Modal } from "semantic-ui-react";
import ViewerSidebar from "./sidebar/ViewerSidebar";
import TagEditor from "../tags/TagEditor";
import {
  fetchComments,
  updateComment,
  createComment,
  deleteComment,
} from "../../actions/api/comments";
import {
  updateViewer,
  zoomTo,
  zoomIn,
  zoomOut,
  resetZoom,
} from "../../actions/ui/viewer";
import ViewerToolbar from "./toolbar/ViewerToolbar";
import zoomOutImage from "../../styles/images/zoom-out.png";
import CommentEditor from "../comments/CommentEditor";
import { getStartPoint, getEndPoint, formatDate } from "../../lib/helpers";
import DataTableActions from "../generic/DataTableActions";

import "../../styles/DrawingPanels.css";

const ITEM_TYPES = {
  COMMENT: "COMMENT",
  TAG: "TAG",
  LINE: "LINE",
  NODE: "NODE",
};

const RECTANGLE_OFFSET = 50;
const RECTANGLE_AREA_OFFSET = 5;

class DrawingPanels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarVisible: false,
    };
  }

  componentWillUnmount() {
    if (this.props.addingItem.type) {
      this.onAddItemCancel();
    }
  }

  toggleSidebar() {
    this.setState({ isSidebarVisible: !this.state.isSidebarVisible });
  }

  updateComment(comment) {
    this.props.updateComment(comment).then(() => this.props.fetchComments());
  }

  onCommentSubmit(comment) {
    const cmt = this.props.freeComment
      ? {
          ...comment,
          PointX: 0,
          PointY: 0,
          EndPointX: ((this.props.commentRect || {}).endPoint || {}).x,
          EndPointY: ((this.props.commentRect || {}).endPoint || {}).y,
          DrawingVersionId: this.props.drawingVersionId,
          Points: this.props.freeComment || null,
        }
      : {
          ...comment,
          PointX: (this.props.commentRect || this.props.commentDot).startPoint
            .x,
          PointY: (this.props.commentRect || this.props.commentDot).startPoint
            .y,
          EndPointX: ((this.props.commentRect || {}).endPoint || {}).x,
          EndPointY: ((this.props.commentRect || {}).endPoint || {}).y,
          DrawingVersionId: this.props.drawingVersionId,
          color: (this.props.commentRect || {}).color
            ? this.props.commentRect.color
            : null,
        };
    this.props.createComment(cmt).then(() =>
      this.props
        .fetchComments()
        .then(() => this.props.updateViewer({ showComments: true }))
        .then(() => this.onAddItemCancel())
    );
  }

  onCommentBtnClicked(maxPoints, commentColor) {
    if (!maxPoints || this.props.addingItem.type !== ITEM_TYPES.COMMENT) {
      this.props.updateViewer({
        addingItem: {
          type: ITEM_TYPES.COMMENT,
          points: [],
          maxPoints,
        },
      });
    }
    switch (maxPoints) {
      case 1:
        this.props.updateViewer({ addingCommentDot: true, commentColor });
        break;
      case 2:
        this.props.updateViewer({ addingCommentRect: true, commentColor });
        break;
      case 3:
        this.props.updateViewer({ addingCommentFree: true, commentColor });
        break;
      default:
        this.onAddItemCancel();
        break;
    }
  }

  onShowCommentsChanged(e, data) {
    this.props.updateViewer({ showComments: data.checked });
  }

  onAddItemCancel() {
    this.props.updateViewer({
      addingCommentFree: false,
      addingItem: { type: null, points: [] },
      addingCommentRect: false,
      addingCommentDot: false,
      commentRect: undefined,
      commentDot: undefined,
      freeComment: undefined,
    });
  }

  getClosestTags(x, y) {
    const distances = [];
    (this.props.tags || []).forEach((tag) => {
      const distance = Math.sqrt((x - tag.PointX) ** 2 + (y - tag.PointY) ** 2);
      distances.push({ distance, tag });
    });
    distances.sort((a, b) => a.distance - b.distance);
    return distances.slice(0, 5).map((d) => d.tag);
  }

  zoomToItem(item, focus = true) {
    if (!item || item.PointX == null || item.PointY == null) {
      return;
    }

    const isComment = item.CreatorId && !item.EndPointX && !item.EndPointY;
    const { x: startPointX, y: startPointY } = getStartPoint(item);
    const { x: endPointX, y: endPointY } = getEndPoint(item);

    this.props.updateViewer({
      showComments: !!isComment || this.props.showComments,
    });
    this.props.zoomTo({
      a: startPointX - RECTANGLE_AREA_OFFSET,
      b: startPointY - RECTANGLE_AREA_OFFSET,
      c: endPointX + RECTANGLE_AREA_OFFSET,
      d: endPointY + RECTANGLE_AREA_OFFSET,
      focus,
      offset: RECTANGLE_OFFSET,
    });
  }

  render() {
    return (
      <div>
        <ViewerSidebar
          isVisible={this.state.isSidebarVisible}
          drawing={this.props.drawing || {}}
          drawingVersion={this.props.drawingVersion || {}}
          // Ui callbacks
          onToggleClicked={this.toggleSidebar.bind(this)}
          // Comments
          comments={this.props.comments}
          onCommentClicked={this.zoomToItem.bind(this)}
          updateComment={this.updateComment.bind(this)}
          onCommentBtnClicked={this.onCommentBtnClicked.bind(this)}
          // Tags
          tags={this.props.tags}
          onTagClicked={this.zoomToItem.bind(this)}
        />
        <ViewerToolbar
          drawing={this.props.drawing || {}}
          drawingVersion={this.props.drawingVersion || {}}
          toggleSidebar={this.toggleSidebar.bind(this)}
          onCommentBtnClicked={this.onCommentBtnClicked.bind(this)}
          isAddingComment={
            this.props.addingCommentRect ||
            this.props.addingCommentDot ||
            this.props.addingCommentFree
          }
          showComments={this.props.showComments}
          onSearchResultSelected={this.zoomToItem.bind(this)}
          onShowCommentsChanged={this.onShowCommentsChanged}
          comments={this.props.comments}
          tags={this.props.tags}
        />
        {this.renderZoomControls()}
        {this.renderComment()}
        {this.renderInfoDialog()}
      </div>
    );
  }

  renderInfoDialog() {
    const { clickedItem } = this.props;
    if (!clickedItem) {
      return null;
    }

    if (clickedItem.type === ITEM_TYPES.TAG) {
      return (
        <TagEditor
          open={!!clickedItem}
          tag={clickedItem}
          comments={this.props.comments.filter(
            (c) => c.DrawingTagName === clickedItem.Name
          )}
          tagTypes={(this.props.drawing || {}).TagTypes}
          drawingId={this.props.drawingId}
          drawingVersionId={this.props.drawingVersionId}
          onClose={() => this.props.updateViewer({ clickedItem: null })}
        />
      );
    }

    if (clickedItem.type === ITEM_TYPES.COMMENT) {
      return (
        <Modal
          open={!!this.props.clickedItem}
          closeIcon={true}
          onClose={() => this.props.updateViewer({ clickedItem: null })}
        >
          <Modal.Header>{clickedItem.Title}</Modal.Header>
          <Modal.Content>
            {clickedItem.Body}
            <div className="info">
              {clickedItem.DrawingTagName && (
                <span className="info-item">
                  <Icon name="tag" />
                  {clickedItem.DrawingTagName}
                </span>
              )}
              {clickedItem.CreatorName && (
                <span className="info-item">
                  <Icon name="user" />
                  {clickedItem.CreatorName}
                </span>
              )}
              {clickedItem.CreatedAt && (
                <span className="info-item">
                  <Icon name="calendar" />
                  {formatDate(clickedItem.CreatedAt)}
                </span>
              )}
              {
                <span className="info-item delete-item">
                  <DataTableActions
                    key="actions"
                    item={clickedItem}
                    close={this.props.updateViewer}
                    isParent={true}
                    onDelete={this.props.deleteComment}
                    deletionLabel="comment_deletion"
                    deletionMessage="confirm_comment_deletion"
                  />
                </span>
              }
            </div>
          </Modal.Content>
        </Modal>
      );
    }

    return null;
  }

  renderZoomControls() {
    return (
      <div className="zoom-controls">
        <Icon name="zoom in" onClick={() => this.props.zoomIn()} />
        <Icon name="zoom out" onClick={() => this.props.zoomOut()} />
        <img
          className="reset-zoom"
          src={zoomOutImage}
          alt={t("reset_zoom")}
          onClick={() => this.props.resetZoom()}
        />
      </div>
    );
  }

  renderComment() {
    if (
      !(
        this.props.commentRect ||
        this.props.commentDot ||
        this.props.freeComment
      )
    ) {
      return null;
    }
    return (
      <CommentEditor
        open={
          !!(
            this.props.addingItem.type === ITEM_TYPES.COMMENT &&
            this.props.addingItem.points.length
          )
        }
        handleSave={this.onCommentSubmit.bind(this)}
        onCancel={this.onAddItemCancel.bind(this)}
        comment={{ Title: "", Body: "" }}
        nearbyTags={this.getClosestTags(
          this.props.freeComment
            ? this.props.freeComment[0].x
            : (this.props.commentRect || this.props.commentDot).startPoint.x,
          this.props.freeComment
            ? this.props.freeComment[0].y
            : (this.props.commentRect || this.props.commentDot).startPoint.y
        )}
      />
    );
  }
}

DrawingPanels.propTypes = {
  drawingId: PropTypes.number,
  drawingVersionId: PropTypes.number,
  drawing: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  ...state.ui.viewer,
  drawingVersion: state.api.drawingversions.index[props.drawingVersionId],
  comments: state.api.comments.list.items.map(
    (id) => state.api.comments.index[id]
  ),
});

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      fetchComments: () => fetchComments({ VersionId: props.drawingVersionId }),
      updateComment,
      createComment,
      deleteComment,
      updateViewer,
      zoomTo,
      zoomIn,
      zoomOut,
      resetZoom,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DrawingPanels);
