import configData from "../config.json";
export const ACTION_REQUEST_SUFFIX = "_REQUEST";
export const ACTION_FAILURE_SUFFIX = "_ERROR";

export const API_STATUS_READY = "ready";
export const API_STATUS_FETCHING = "fetching_item";
export const API_STATUS_FETCHING_LIST = "fetching_list";
export const API_STATUS_UPDATING = "updating_item";
export const API_STATUS_CREATING = "creating_item";
export const API_STATUS_DELETING = "deleting_item";
export const API_STATUS_INVOKING_ACTION = "invoking_item_action";

const API_URI = "/api";

const POLL_TIMEOUT = 4000;

export function createURLSearchParams(params) {
  if (params == null) return "";
  return Object.keys(params)
    .filter((p) => params[p] !== undefined)
    .map((p) => `${p}=${encodeURIComponent(params[p])}`)
    .join("&");
}

export function createAPIActionDispatcher(
  action,
  payload,
  { uri, body, then, method, poll }
) {
  const actionDispatcher = (dispatch, getState) => {
    let methodName = method;
    const headers = new Headers({
      Accept: "application/json",
    });
    const { token } = getState().api.account;
    if (token) {
      headers.append(
        "Authorization",
        `${token.token_type} ${token.access_token}`
      );
    }
    if (body !== undefined) {
      methodName = methodName || "POST";
      if (typeof body === "string") {
        headers.append("Content-Type", "application/json; encoding=utf8");
      }
    }

    methodName = methodName || "GET";
    let Base_Url = "";
    dispatch({ ...payload, type: `${action}${ACTION_REQUEST_SUFFIX}` });
    switch (window.location.hostname) {
      case "localhost":
        Base_Url = "http://localhost:3003"; // configData.BackendUrl;
        break;
      case "icy-meadow-02b978503.azurestaticapps.net":
        Base_Url = configData.BackendUrl; //"https://maxpid-internal.azurewebsites.net";
        break;
      default:
        Base_Url = configData.BackendUrl; //"https://maxpid-internal.azurewebsites.net";
        break;
    }
    const promise = fetch(Base_Url + uri, {
      headers,
      body,
      method: methodName,
    })
      .then((res) =>
        res.json().catch((error) => ({
          Success: false,
          Message: res.status !== 200 ? res.statusText : error,
        }))
      )
      .then((response) => {
        if (
          !response ||
          response.Message ||
          response.error ||
          response.Success === false
        ) {
          return dispatch({
            ...payload,
            type: `${action}${ACTION_FAILURE_SUFFIX}`,
            message: {
              type: "error",
              body: (response || {}).Message,
              for: { method, payload, uri },
            },
            result: response,
          });
        }
        if (poll && !poll(response)) {
          setTimeout(() => actionDispatcher(dispatch, getState), POLL_TIMEOUT);
        }
        return dispatch({
          type: action,
          result: response,
          ...payload,
        });
      })
      .catch((error) =>
        dispatch({
          ...payload,
          type: `${action}${ACTION_FAILURE_SUFFIX}`,
          message: {
            type: "error",
            body: error.message,
            for: { method, payload, uri },
          },
        })
      );

    return then ? promise.then(() => then(dispatch, getState)) : promise;
  };

  return actionDispatcher;
}

const toObject = (arr) => {
  const obj = {};
  for (let i = 0; i < arr.length; i += 1) {
    const item = arr[i];
    obj[item.Id] = item;
  }
  return obj;
};

export function createAPIActionTypes(path) {
  const FETCH_LIST = `${path}/FETCH_LIST`;
  const FETCH_LIST_REQUEST = `${FETCH_LIST}${ACTION_REQUEST_SUFFIX}`;
  const FETCH_LIST_FAILURE = `${FETCH_LIST}${ACTION_FAILURE_SUFFIX}`;
  const FETCH_ITEM = `${path}/FETCH_ITEM`;
  const FETCH_ITEM_REQUEST = `${FETCH_ITEM}${ACTION_REQUEST_SUFFIX}`;
  const FETCH_ITEM_FAILURE = `${FETCH_ITEM}${ACTION_FAILURE_SUFFIX}`;
  const FETCH_RESOURCE = `${path}/FETCH_RESOURCE`;
  const FETCH_RESOURCE_REQUEST = `${FETCH_RESOURCE}${ACTION_REQUEST_SUFFIX}`;
  const FETCH_RESOURCE_FAILURE = `${FETCH_RESOURCE}${ACTION_FAILURE_SUFFIX}`;
  const UPDATE_ITEM = `${path}/UPDATE_ITEM`;
  const UPDATE_ITEM_REQUEST = `${UPDATE_ITEM}${ACTION_REQUEST_SUFFIX}`;
  const UPDATE_ITEM_FAILURE = `${UPDATE_ITEM}${ACTION_FAILURE_SUFFIX}`;
  const INVOKE_ITEM_ACTION = `${path}/INVOKE_ITEM_ACTION`;
  const INVOKE_ITEM_ACTION_REQUEST = `${INVOKE_ITEM_ACTION}${ACTION_REQUEST_SUFFIX}`;
  const INVOKE_ITEM_ACTION_FAILURE = `${INVOKE_ITEM_ACTION}${ACTION_FAILURE_SUFFIX}`;
  const CREATE_ITEM = `${path}/CREATE_ITEM`;
  const CREATE_ITEM_REQUEST = `${CREATE_ITEM}${ACTION_REQUEST_SUFFIX}`;
  const CREATE_ITEM_FAILURE = `${CREATE_ITEM}${ACTION_FAILURE_SUFFIX}`;
  const DELETE_ITEM = `${path}/DELETE_ITEM`;
  const DELETE_ITEM_REQUEST = `${DELETE_ITEM}${ACTION_REQUEST_SUFFIX}`;
  const DELETE_ITEM_FAILURE = `${DELETE_ITEM}${ACTION_FAILURE_SUFFIX}`;

  return {
    ACTION_FETCH_LIST: FETCH_LIST,
    ACTION_FETCH_LIST_REQUEST: FETCH_LIST_REQUEST,
    ACTION_FETCH_LIST_FAILURE: FETCH_LIST_FAILURE,
    ACTION_FETCH_ITEM: FETCH_ITEM,
    ACTION_FETCH_ITEM_REQUEST: FETCH_ITEM_REQUEST,
    ACTION_FETCH_ITEM_FAILURE: FETCH_ITEM_FAILURE,
    ACTION_FETCH_RESOURCE: FETCH_RESOURCE,
    ACTION_FETCH_RESOURCE_REQUEST: FETCH_RESOURCE_REQUEST,
    ACTION_FETCH_RESOURCE_FAILURE: FETCH_RESOURCE_FAILURE,
    ACTION_UPDATE_ITEM: UPDATE_ITEM,
    ACTION_UPDATE_ITEM_REQUEST: UPDATE_ITEM_REQUEST,
    ACTION_UPDATE_ITEM_FAILURE: UPDATE_ITEM_FAILURE,
    ACTION_CREATE_ITEM: CREATE_ITEM,
    ACTION_CREATE_ITEM_REQUEST: CREATE_ITEM_REQUEST,
    ACTION_CREATE_ITEM_FAILURE: CREATE_ITEM_FAILURE,
    ACTION_DELETE_ITEM: DELETE_ITEM,
    ACTION_DELETE_ITEM_REQUEST: DELETE_ITEM_REQUEST,
    ACTION_DELETE_ITEM_FAILURE: DELETE_ITEM_FAILURE,
    ACTION_INVOKE_ITEM_ACTION: INVOKE_ITEM_ACTION,
    ACTION_INVOKE_ITEM_ACTION_REQUEST: INVOKE_ITEM_ACTION_REQUEST,
    ACTION_INVOKE_ITEM_ACTION_FAILURE: INVOKE_ITEM_ACTION_FAILURE,
  };
}

export function createAPIActions(path) {
  const {
    ACTION_FETCH_LIST,
    ACTION_FETCH_LIST_REQUEST,
    ACTION_FETCH_LIST_FAILURE,
    ACTION_FETCH_ITEM,
    ACTION_FETCH_ITEM_REQUEST,
    ACTION_FETCH_ITEM_FAILURE,
    ACTION_FETCH_RESOURCE,
    ACTION_FETCH_RESOURCE_REQUEST,
    ACTION_FETCH_RESOURCE_FAILURE,
    ACTION_UPDATE_ITEM,
    ACTION_UPDATE_ITEM_REQUEST,
    ACTION_UPDATE_ITEM_FAILURE,
    ACTION_CREATE_ITEM,
    ACTION_CREATE_ITEM_REQUEST,
    ACTION_CREATE_ITEM_FAILURE,
    ACTION_DELETE_ITEM,
    ACTION_DELETE_ITEM_REQUEST,
    ACTION_DELETE_ITEM_FAILURE,
    ACTION_INVOKE_ITEM_ACTION,
    ACTION_INVOKE_ITEM_ACTION_REQUEST,
    ACTION_INVOKE_ITEM_ACTION_FAILURE,
  } = createAPIActionTypes(path);

  return {
    ACTION_FETCH_LIST,
    ACTION_FETCH_LIST_REQUEST,
    ACTION_FETCH_LIST_FAILURE,
    ACTION_FETCH_ITEM,
    ACTION_FETCH_ITEM_REQUEST,
    ACTION_FETCH_ITEM_FAILURE,
    ACTION_FETCH_RESOURCE,
    ACTION_FETCH_RESOURCE_REQUEST,
    ACTION_FETCH_RESOURCE_FAILURE,
    ACTION_UPDATE_ITEM,
    ACTION_UPDATE_ITEM_REQUEST,
    ACTION_UPDATE_ITEM_FAILURE,
    ACTION_CREATE_ITEM,
    ACTION_CREATE_ITEM_REQUEST,
    ACTION_CREATE_ITEM_FAILURE,
    ACTION_DELETE_ITEM,
    ACTION_DELETE_ITEM_REQUEST,
    ACTION_DELETE_ITEM_FAILURE,
    ACTION_INVOKE_ITEM_ACTION,
    ACTION_INVOKE_ITEM_ACTION_REQUEST,
    ACTION_INVOKE_ITEM_ACTION_FAILURE,

    fetchListDispatcher: (criteria) =>
      createAPIActionDispatcher(
        ACTION_FETCH_LIST,
        { criteria },
        {
          uri: `${API_URI}/${path}?${createURLSearchParams(
            criteria
          ).toString()}`,
        }
      ),

    fetchItemDispatcher: (id, opts) =>
      createAPIActionDispatcher(
        ACTION_FETCH_ITEM,
        { id, opts },
        {
          uri: `${API_URI}/${path}/${id}`,
          ...opts,
        }
      ),

    fetchResourceDispatcher: () =>
      createAPIActionDispatcher(
        ACTION_FETCH_RESOURCE,
        {},
        {
          uri: `${API_URI}/${path}`,
        }
      ),

    updateItemDispatcher: (item, opts) =>
      createAPIActionDispatcher(
        ACTION_UPDATE_ITEM,
        { item, opts },
        {
          uri: `${API_URI}/${path}/${item.Id}`,
          body: JSON.stringify(item),
          ...opts,
        }
      ),

    invokeItemActionDispatcher: (id, action, payload, opts) =>
      createAPIActionDispatcher(
        ACTION_INVOKE_ITEM_ACTION,
        { id, action, opts },
        {
          uri: `${API_URI}/${path}/${id}/${action}`,
          body: JSON.stringify(payload),
          ...opts,
        }
      ),

    deleteItemDispatcher: (item, opts) =>
      createAPIActionDispatcher(
        ACTION_DELETE_ITEM,
        { item, opts },
        {
          uri: `${API_URI}/${path}/${item.Id}`,
          method: "DELETE",
          ...opts,
        }
      ),

    createItemDispatcher: (item, opts) =>
      createAPIActionDispatcher(
        ACTION_CREATE_ITEM,
        { item, opts },
        {
          uri: `${API_URI}/${path}`,
          method: "POST",
          body: JSON.stringify(item),
          ...opts,
        }
      ),
  };
}

export function createAPIReducer(path) {
  const {
    ACTION_FETCH_LIST,
    ACTION_FETCH_LIST_REQUEST,
    ACTION_FETCH_LIST_FAILURE,
    ACTION_FETCH_ITEM,
    ACTION_FETCH_ITEM_REQUEST,
    ACTION_FETCH_ITEM_FAILURE,
    ACTION_FETCH_RESOURCE,
    ACTION_FETCH_RESOURCE_REQUEST,
    ACTION_FETCH_RESOURCE_FAILURE,
    ACTION_UPDATE_ITEM,
    ACTION_UPDATE_ITEM_REQUEST,
    ACTION_UPDATE_ITEM_FAILURE,
    ACTION_CREATE_ITEM,
    ACTION_CREATE_ITEM_REQUEST,
    ACTION_CREATE_ITEM_FAILURE,
    ACTION_DELETE_ITEM,
    ACTION_DELETE_ITEM_REQUEST,
    ACTION_DELETE_ITEM_FAILURE,
    ACTION_INVOKE_ITEM_ACTION,
    ACTION_INVOKE_ITEM_ACTION_REQUEST,
    ACTION_INVOKE_ITEM_ACTION_FAILURE,
  } = createAPIActionTypes(path);

  const initialState = {
    index: {},
    status: API_STATUS_READY,
    list: {
      criteria: {},
      items: [],
      total: 0,
    },
    messages: [],
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case ACTION_FETCH_LIST_REQUEST: {
        return {
          ...state,
          list: {
            ...state.list,
            criteria: action.criteria,
            items: [],
            total: 0,
          },
          status:
            action.opts && action.opts.poll
              ? state.status
              : API_STATUS_FETCHING_LIST,
          messages: action.opts && action.opts.poll ? state.messages : [],
        };
      }
      case ACTION_FETCH_LIST: {
        const items = Array.isArray(action.result)
          ? action.result
          : action.result.Items;
        return {
          ...state,
          index: {
            ...state.index,
            ...toObject(items),
          },
          list: {
            ...state.list,
            items: items.map((item) => item.Id),
            total: action.result.TotalItems || 0,
          },
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
          messages: action.opts && action.opts.poll ? state.messages : [],
        };
      }
      case ACTION_FETCH_LIST_FAILURE: {
        return {
          ...state,
          messages: [
            {
              ...action.message,
              title: action.message.title || "retrieval_error",
            },
          ],
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
        };
      }
      case ACTION_FETCH_ITEM_REQUEST: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll
              ? state.status
              : API_STATUS_FETCHING,
          messages: action.opts && action.opts.poll ? state.messages : [],
        };
      }
      case ACTION_FETCH_ITEM: {
        return {
          ...state,
          index: {
            ...state.index,
            [action.result.Id]: { ...action.result },
          },
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
        };
      }
      case ACTION_FETCH_ITEM_FAILURE: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
          messages: [
            {
              ...action.message,
              title: action.message.title || "retrieval_error",
              item: { Id: action.Id },
            },
          ],
        };
      }
      case ACTION_FETCH_RESOURCE_REQUEST: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll
              ? state.status
              : API_STATUS_FETCHING,
          messages: action.opts && action.opts.poll ? state.messages : [],
        };
      }
      case ACTION_FETCH_RESOURCE: {
        return {
          ...state,
          index: { ...action.result },
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
        };
      }
      case ACTION_FETCH_RESOURCE_FAILURE: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
          messages: [
            {
              ...action.message,
              title: action.message.title || "retrieval_error",
              item: { Id: action.Id },
            },
          ],
        };
      }
      case ACTION_UPDATE_ITEM_REQUEST: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll
              ? state.status
              : API_STATUS_UPDATING,
          messages: action.opts && action.opts.poll ? state.messages : [],
        };
      }
      case ACTION_UPDATE_ITEM: {
        return {
          ...state,
          index: {
            ...state.index,
            [action.item.Id]: {
              ...action.item,
            },
          },
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
          messages:
            action.opts && action.opts.poll
              ? state.messages
              : [
                  {
                    title: "update_success",
                    type: "success",
                    body: "update_success_body",
                    item: action.item,
                  },
                ],
        };
      }
      case ACTION_UPDATE_ITEM_FAILURE: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
          messages: [
            {
              ...action.message,
              title: action.message.title || "update_error",
              item: { Id: action.Id },
            },
          ],
        };
      }
      case ACTION_CREATE_ITEM_REQUEST: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll
              ? state.status
              : API_STATUS_CREATING,
          messages: action.opts && action.opts.poll ? state.messages : [],
        };
      }
      case ACTION_CREATE_ITEM: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
          messages:
            action.opts && action.opts.poll
              ? state.messages
              : [
                  {
                    title: "creation_success",
                    type: "success",
                    body: "creation_success_body",
                    item: action.item,
                  },
                ],
        };
      }
      case ACTION_CREATE_ITEM_FAILURE: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
          messages: [
            {
              ...action.message,
              title: action.message.title || "creation_error",
              item: { Id: action.Id },
            },
          ],
        };
      }
      case ACTION_DELETE_ITEM_REQUEST: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll
              ? state.status
              : API_STATUS_DELETING,
          messages: action.opts && action.opts.poll ? state.messages : [],
        };
      }
      case ACTION_DELETE_ITEM: {
        return {
          ...state,
          index: {
            ...state.index,
            [action.item.Id]: undefined,
          },
          list: {
            ...state.list,
            items: state.list.items.filter((id) => id !== action.item.Id),
          },
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
          messages:
            action.opts && action.opts.poll
              ? state.messages
              : [
                  {
                    title: "deletion_success",
                    type: "info",
                    body: "deletion_success_body",
                    item: action.item,
                  },
                ],
        };
      }
      case ACTION_DELETE_ITEM_FAILURE: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
          messages: [
            {
              ...action.message,
              title: action.message.title || "deletion_error",
              item: { Id: action.Id },
            },
          ],
        };
      }
      case ACTION_INVOKE_ITEM_ACTION_REQUEST: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll
              ? state.status
              : API_STATUS_INVOKING_ACTION,
        };
      }
      case ACTION_INVOKE_ITEM_ACTION: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
        };
      }
      case ACTION_INVOKE_ITEM_ACTION_FAILURE: {
        return {
          ...state,
          status:
            action.opts && action.opts.poll ? state.status : API_STATUS_READY,
          messages: [
            {
              ...action.message,
              title: action.message.title || "invoking_action_error",
              item: { Id: action.Id },
            },
          ],
        };
      }
      default: {
        return state;
      }
    }
  };
}
