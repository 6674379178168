import {
  API_STATUS_READY,
  API_STATUS_CREATING,
  ACTION_REQUEST_SUFFIX,
  ACTION_FAILURE_SUFFIX,
  createAPIReducer,
} from '../../lib/redux';
import { ACTION_COMMENT_HISTORY } from '../../actions/api/comments';

const reducer = createAPIReducer('comments');

export const COMMENTS_STATUS_READY = API_STATUS_READY;
export const COMMENTS_STATUS_CREATING = API_STATUS_CREATING;
export const COMMENTS_STATUS_HISTORY = 'getting_comment_history';

const ACTION_COMMENT_HISTORY_REQUEST = `${ACTION_COMMENT_HISTORY}${ACTION_REQUEST_SUFFIX}`;
const ACTION_COMMENT_HISTORY_FAILURE = `${ACTION_COMMENT_HISTORY}${ACTION_FAILURE_SUFFIX}`;

const extendedReducer = (state, action) => {
  switch (action.type) {
    case ACTION_COMMENT_HISTORY_REQUEST:
      return { ...state, status: COMMENTS_STATUS_HISTORY, messages: [] };

    case ACTION_COMMENT_HISTORY:
      return { ...state, status: COMMENTS_STATUS_READY, messages: [] };

    case ACTION_COMMENT_HISTORY_FAILURE:
      return { ...state, status: COMMENTS_STATUS_READY, messages: [action.message] };

    default:
      return reducer(state, action);
  }
};

export default extendedReducer;
