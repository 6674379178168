import {
  API_STATUS_READY,
  API_STATUS_CREATING,
  createAPIReducer,
} from '../../lib/redux';

const reducer = createAPIReducer('drawingSystems');

export default reducer;

export const SYSTEMS_STATUS_READY = API_STATUS_READY;
export const SYSTEMS_STATUS_CREATING = API_STATUS_CREATING;
