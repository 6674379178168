import React, { Component } from 'react';
import { t } from 'i18next';
import { Segment, Container } from 'semantic-ui-react';
import ContainedLayout from '../components/layout/ContainedLayout';
import ChangePasswordForm from '../components/users/ChangePasswordForm';

class ChangePassword extends Component {
  render() {
    return (
      <ContainedLayout>
        <div className="ChangePassword">
          <Container text>
            <Segment vertical>
              <h1>{t('change_password')}</h1>
              <ChangePasswordForm />
            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

export default ChangePassword;
