import i18next from "i18next";
import configData from "../config.json";
const DATE_FORMAT = "yyyy/mm/dd";

export function getStartPoint(item) {
  if (item.Points && item.Points.length) {
    return (
      [...item.Points]
        .filter((point) => point.x !== null && point.y !== null)
        .sort((a, b) => b.x - a.x || b.y - a.y)
        .pop() || {}
    );
  }
  if (item.PointX !== null && item.PointY !== null) {
    return { x: item.PointX, y: item.PointY };
  }
  return {};
}

export function getEndPoint(item) {
  if (item.Points && item.Points.length) {
    return (
      [...item.Points]
        .filter((point) => point.x !== null && point.y !== null)
        .sort((a, b) => a.x - b.x || a.y - b.y)
        .pop() || {}
    );
  }
  if (item.EndPointX !== null && item.EndPointY !== null) {
    return { x: item.EndPointX, y: item.EndPointY };
  }
  if (item.PointX !== null || item.PointY !== null) {
    return { x: item.PointX, y: item.PointY };
  }
  return {};
}

export function formatDate(value) {
  if (typeof value === "string" && value.match(/^\d{4}-\d{2}-\d{2}T/)) {
    return i18next.format(new Date(value), DATE_FORMAT);
  }
  if (value instanceof Date) {
    return i18next.format(value, DATE_FORMAT);
  }
  return value;
}

export function getDrawingWithImage(drawing, drawingSystems) {
  if (drawing.HasImage) {
    return {
      ...drawing,
      Image: `${configData.BackendUrl}/api/drawings/${drawing.Id}/image`,
    };
  }
  if (drawing.SystemNumber) {
    const system = drawingSystems.find(
      (s) => s.Number === drawing.SystemNumber
    );
    if (system && system.HasImage) {
      return {
        ...drawing,
        Image: `${configData.BackendUrl}/api/drawingSystems/${system.Id}/image`,
      };
    }
  }
  return drawing;
}

export const CommentPermissions = {
  Public: 1,
  Private: 2,
  Shared: 3,
};
