import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Tab, Modal } from 'semantic-ui-react';
import { GROUP_STATUS_READY } from '../../reducers/api/groups';
import { updateGroup, createGroup } from '../../actions/api/groups';

import GroupInfoPanel from './panels/GroupInfoPanel';
import UserList from '../users/UsersList';
import MessageList from '../generic/MessageList';

const DEFAULT_PAGE_SIZE = 4;

class GroupEditor extends Component {
  // WS -------------------------
  upsert(group) {
    if (group.Id) {
      this.props.updateGroup(group);
    } else {
      group.CompanyId = this.props.companyId;
      this.props.createGroup(group).then((response) => {
        if (response && response.result && response.result.Success) {
          this.props.onModalClose();
        }
      });
    }
  }

  // User Selection -------------------------
  addUserToGroup(userId) {
    const usersList = (this.props.group.UsersIds || []).concat([userId]);
    this.props.updateGroup({ ...this.props.group, UsersIds: usersList });
  }

  removeUserFromGroup(userId) {
    if (!this.props.group.UsersIds || !this.props.group.UsersIds.includes(userId)) return;
    const usersList = this.props.group.UsersIds.filter(uId => uId !== userId);
    this.props.updateGroup({ ...this.props.group, UsersIds: usersList });
  }

  onRowChecked(userId, isChecked) {
    if (isChecked) {
      this.addUserToGroup(userId);
    } else {
      this.removeUserFromGroup(userId);
    }
  }

  // Ui -------------------------
  render() {
    return (
      <Modal
        closeOnDimmerClick={false}
        onClose={this.props.onModalClose}
        open={!!this.props.isCreate || !!this.props.group}
        closeIcon={true}
      >
        <Modal.Header>{this.props.isCreate || this.props.group == null ? t('add_group') : `${t('group')} ${this.props.group.Name}`}</Modal.Header>
        <Modal.Content>
          <MessageList messages={this.props.groupMessages} />
          {this.renderPanes()}
        </Modal.Content>
      </Modal>
    );
  }

  renderPanes() {
    const panes = [
      {
        menuItem: { key: 'info', icon: 'info', content: t('info') },
        render: () => (
          <Tab.Pane>
            <GroupInfoPanel
              group={this.props.group || {}}
              updateGroup={this.upsert.bind(this)}
              status={this.props.groupStatus}
              statusReady={GROUP_STATUS_READY}
            />
          </Tab.Pane>
        ),
      }];
    if (!this.props.isCreate) {
      panes.push({
        menuItem: { key: 'users', icon: 'user', content: t('users') },
        render: () => (
          <Tab.Pane>
            <UserList
              onRowChecked={this.onRowChecked.bind(this)}
              selectedUserIds={this.props.group.UsersIds}
              pageSize={DEFAULT_PAGE_SIZE}
            />
          </Tab.Pane>
        ),
      });
    }

    return (
      <Tab panes={panes} menu={{
        stackable: true,
        attached: true,
        tabular: true,
        widths: panes.length,
      }} />
    );
  }
}

GroupEditor.propTypes = {
  // Params
  isCreate: PropTypes.bool.isRequired,
  groupId: PropTypes.number,
  companyId: PropTypes.number, // needed for Group Creation
  onModalClose: PropTypes.func,
  // From System
  group: PropTypes.object,
  groupStatus: PropTypes.string,
  groupMessages: PropTypes.array,
  createGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  groupStatus: state.api.groups.status,
  groupMessages: state.api.groups.messages,
  group: state.api.groups.index[props.groupId],
});

const mapDispatchToProps = dispatch => bindActionCreators({
  createGroup,
  updateGroup,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GroupEditor);
