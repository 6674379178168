export const ACTION_UPDATE_VIEWER = 'viewer/UPDATE';
export const ACTION_ZOOM_TO = 'viewer/ZOOM_TO';
export const ACTION_ZOOM_IN = 'viewer/ZOOM_IN';
export const ACTION_ZOOM_OUT = 'viewer/ZOOM_OUT';
export const ACTION_RESET_ZOOM = 'viewer/RESET_ZOOM';

export function updateViewer(props) {
  return (dispatch) => {
    dispatch({ type: ACTION_UPDATE_VIEWER, props });
  };
}

export function zoomTo(zoomCords) {
  return (dispatch) => {
    dispatch({ type: ACTION_ZOOM_TO, zoomCords });
  };
}

export function zoomIn() {
  return (dispatch) => {
    dispatch({ type: ACTION_ZOOM_IN });
  };
}

export function zoomOut() {
  return (dispatch) => {
    dispatch({ type: ACTION_ZOOM_OUT });
  };
}

export function resetZoom() {
  return (dispatch) => {
    dispatch({ type: ACTION_RESET_ZOOM });
  };
}
