import React, { Component } from 'react';
import { t } from 'i18next';
import { Segment, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import url from 'url';
import ContainedLayout from '../components/layout/ContainedLayout';
import ResetPasswordForm from '../components/users/ResetPasswordForm';

class ResetPassword extends Component {
  render() {
    const { query } = url.parse(this.props.location.search, true);

    return (
      <ContainedLayout>
        <div className="ResetPassword">
          <Container>
            <Segment vertical>
              <h1>{t('reset_password')}</h1>
              <ResetPasswordForm
                username={query.username}
                code={query.code}
              />
            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

ResetPassword.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ResetPassword;
