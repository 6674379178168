import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Segment, Dimmer, Loader, Grid, Card, Button, Image, Modal, Input, Icon, Dropdown, Pagination } from 'semantic-ui-react';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18next, { t } from 'i18next';

import placeholderImg from '../../styles/images/placeholder.png';
import MessageList from '../generic/MessageList';
import DataTable from '../generic/DataTable';
import { DRAWINGS_STATUS_READY } from '../../reducers/api/drawings';
import { fetchDrawings } from '../../actions/api/drawings';
import { USER_STATUS_READY } from '../../reducers/api/users';
import { updateUser } from '../../actions/api/users';
import { fetchSystems } from '../../actions/api/systems';
import { getDrawingWithImage } from '../../lib/helpers';

import '../../styles/DrawingsGrid.css';

const DATE_FORMAT = 'yyyy/mm/dd';
const SEARCH_TIMEOUT = 750;
const DEFAULT_PAGE_SIZE = 8;

class DrawingsGrid extends Component {
  // Component methods -------------------
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      showFavorites: false,
      dropdownFilter: [],
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    };
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.fetchDrawings();
      this.props.fetchSystems();
    }
  }

  componentDidUpdate() {
    if (!this.props.currentUser || this.hasLoadDrawings) {
      return;
    }

    this.hasLoadDrawings = true;
  }

  fetchDrawings() {
    this.props.fetchDrawings({
      Filter: this.state.filter,
      SystemNumbers: this.state.dropdownFilter,
      PageSize: this.state.pageSize,
      PageNumber: this.state.currentPage,
      HasActiveVersionId: true,
      Tags: true,
    });
  }

  // Implementatioon -------------------
  onSearchChanged(event) {
    clearTimeout(this.searchTimeout);
    const term = event.target.value;
    this.searchTimeout = setTimeout(() => {
      this.setState({
        filter: term,
        currentPage: 1,
      }, this.fetchDrawings);
    }, SEARCH_TIMEOUT);
  }

  onPagingChanged(event, data) {
    this.setState({
      currentPage: data.activePage,
    }, this.fetchDrawings);
  }

  onFilterChanged(event, data) {
    this.setState({ dropdownFilter: data.value }, () => this.fetchDrawings());
  }

  toggleFavorite(drawing) {
    const favIds = this.props.currentUser.FavoriteDrawingIds;
    const index = favIds.indexOf(drawing.Id);
    if (index >= 0) {
      favIds.splice(index, 1);
    } else {
      favIds.push(drawing.Id);
    }

    this.props.updateUser({ ...this.props.currentUser, FavoriteDrawingIds: favIds });
  }

  toggleShowFavorites(showFavorites) {
    this.setState({ showFavorites });
  }

  // Ui -------------------
  render() {
    const totalPages = Math.ceil(this.props.totalItems / this.state.pageSize);
    return (
      <div className="DrawingsGrid">
        <MessageList messages={this.props.messages} />
        <div className="filters">
          <Input icon="search"
            placeholder={t('search')}
            onChange={this.onSearchChanged.bind(this)}
          />
          {this.props.features.DrawingSystems && this.renderFilterDropdown()}
          <div className="buttons">
            <Button style={{ float: 'right' }}
              onClick={this.toggleShowFavorites.bind(this, false)}
              content={t('all')}
              active={!this.state.showFavorites}
            />
            <Button style={{ float: 'right' }}
              onClick={this.toggleShowFavorites.bind(this, true)}
              content={t('favorites')}
              active={this.state.showFavorites}
            />
          </div>
        </div>
        <Segment vertical style={{ clear: 'both' }}>
          {this.renderGrid()}
        </Segment>
        <Segment vertical>
          {totalPages > 1 && (
            <Pagination
              firstItem={null}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
              lastItem={null}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              activePage={this.state.currentPage}
              totalPages={totalPages}
              onPageChange={this.onPagingChanged.bind(this)}
              style={{ float: 'right' }}
            />
          )}
        </Segment>
        {this.props.status === DRAWINGS_STATUS_READY ? null
        : <Dimmer active inverted>
          <Loader inverted>{t(this.props.status)}</Loader>
        </Dimmer>}
        {this.props.userStatus === USER_STATUS_READY ? null
        : <Dimmer active inverted>
          <Loader inverted>{t(this.props.userStatus)}</Loader>
        </Dimmer>}
      </div>
    );
  }

  renderGrid() {
    return (
      <Card.Group stackable itemsPerRow={4}>
        {
          this.props.drawings.filter(d => d.ActiveVersionId && (
            !this.state.showFavorites ||
            this.props.currentUser.FavoriteDrawingIds.indexOf(d.Id) >= 0
          )).map(drawing => (
            <Card key={drawing.Id}>
              <div>
                <NavLink to={`/view/${drawing.Id}/${drawing.ActiveVersionId}`}>
                  <Image
                    size="medium"
                    src={(getDrawingWithImage(drawing, this.props.drawingSystems) || {}).Image
                    || placeholderImg}
                  />
                </NavLink>
                <Icon style={{ position: 'absolute', left: '0.5em', top: '0.5em' }}
                  color="grey"
                  name={this.props.currentUser.FavoriteDrawingIds.indexOf(drawing.Id) >= 0 ? 'star' : 'star outline'}
                  size='large'
                  onClick={this.toggleFavorite.bind(this, drawing)}
                />
              </div>
              <Card.Content>
                <Card.Header>
                  <NavLink to={`/view/${drawing.Id}/${drawing.ActiveVersionId}`}>
                    {drawing.Name}
                  </NavLink>
                </Card.Header>
                <Card.Description>
                  <label><Icon name="clone" /> {t('version')}: </label>
                  {drawing.ActiveVersionNumber} <br/>
                  <label><Icon name="user" /> {t('added_by')}: </label>
                  {drawing.CreatorName} <br/>
                  <label><Icon name="calendar outline" /> {t('added_on')}: </label>
                  {DataTable.format(drawing.CreatedAt)} <br/>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Icon name="calendar" /> {DrawingsGrid.formatDate(drawing.ModifiedAt)}
              </Card.Content>
            </Card>
          ))
        }
      </Card.Group>
    );
  }

  renderDrawingModal(drawing) {
    return (
      <Modal
        trigger={<Button compact size="small" icon="info" content={t('info')} />}
        size='small'
        closeIcon={true}
      >
        <Modal.Header>{drawing.Name}</Modal.Header>
        <Modal.Content>
          <Grid columns={2} stackable={false}>
            <Grid.Row>
              <Grid.Column>
                <label>{t('name')}</label>
              </Grid.Column>
              <Grid.Column>
                <label>{drawing.Name}</label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <label>{t('version')}</label>
              </Grid.Column>
              <Grid.Column>
                <label>{t('version')} {drawing.ActiveVersionNumber}</label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <label>{t('added_by')}</label>
              </Grid.Column>
              <Grid.Column>
                <label>{drawing.CreatorName}</label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <label>{t('added_on')}</label>
              </Grid.Column>
              <Grid.Column>
                <label>{DataTable.format(drawing.CreatedAt)}</label>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <NavLink to={`/view/${drawing.Id}/${drawing.ActiveVersionId}`}>
            <Icon name="share" /> {t('view')}
          </NavLink>
          {
            this.props.currentUser.FavoriteDrawingIds.indexOf(drawing.Id) >= 0 ? null :
              <Button size="small" primary compact icon="emtpy star"
                onClick={this.toggleFavorite.bind(this, drawing)}
                content={t('mark_favorite')}
              />
          }
        </Modal.Actions>
      </Modal>
    );
  }

  renderFilterDropdown() {
    return (
      <Dropdown placeholder={t('systems')}
        className='filter-dropdown'
        fluid
        multiple
        search
        selection
        options={
          this.props.drawingSystems &&
          this.props.drawingSystems.map(s => ({
                key: s.Number,
                value: s.Number,
                text: `${s.Number}: ${s.Name}`,
            }))
        }
        onChange={this.onFilterChanged.bind(this)} />
    );
  }

  static formatDate(value) {
    if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T/)) {
      return i18next.format(new Date(value), DATE_FORMAT);
    }
    if (value instanceof Date) {
      return i18next.format(value, DATE_FORMAT);
    }
    return value;
  }
}

DrawingsGrid.propTypes = {
  drawings: PropTypes.array,
  totalItems: PropTypes.number,
  status: PropTypes.string,
  messages: PropTypes.array,
  currentUser: PropTypes.object,
  drawingSystems: PropTypes.array,
  // Update user favorites
  updateUser: PropTypes.func,
  userStatus: PropTypes.string,

  goto: PropTypes.func,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchDrawings,
  updateUser,
  fetchSystems,
  goto: page => push(page),
}, dispatch);

const mapStateToProps = state => ({
  drawings: state.api.drawings.list.items.map(id => (state.api.drawings.index[id])),
  totalItems: state.api.drawings.list.total,
  status: state.api.drawings.status,
  messages: state.api.drawings.messages,
  currentUser: state.api.account.user,
  userStatus: state.api.users.status,
  drawingSystems: state.api.drawingSystems.list.items
    .map(id => (state.api.drawingSystems.index[id])),
  features: (state.api.configuration.index || {}).Features || {},
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawingsGrid);
