import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ACCOUNT_STATUS_READY } from '../reducers/api/account';
import { logout } from '../actions/api/account';

class Home extends Component {
  componentDidMount() {
    if (!this.props.currentUser && this.props.accountStatus === ACCOUNT_STATUS_READY) {
      this.props.goto('/');
      return;
    }

    this.props.logout().then(() => {
      if (
        window.webkit && window.webkit.messageHandlers
          && window.webkit.messageHandlers.native
          && typeof window.webkit.messageHandlers.native.postMessage === 'function'
      ) {
        window.webkit.messageHandlers.native.postMessage(JSON.stringify({ method: 'logout' }));
      }
      this.props.goto('/Login');
    });
  }

  render() {
    return (this.props.isLoggingOut ? <Loader /> : null);
  }
}

Home.propTypes = {
  goto: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  accountStatus: PropTypes.string,
  currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
  accountStatus: state.api.account.status,
  currentUser: state.api.account.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
  goto: page => push(page),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
