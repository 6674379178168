import React, { Component } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { t } from 'i18next';
import ContainedLayout from '../components/layout/ContainedLayout';
import SystemsList from '../components/systems/SystemsList';

class Systems extends Component {
  render() {
    return (
      <ContainedLayout>
        <div className="Systems">
          <Container>
            <Segment vertical>
              <h1>{t('systems')}</h1>
              <SystemsList />
            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

export default Systems;
