import React, { Component } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Container, Segment, Icon } from 'semantic-ui-react';
import ContainedLayout from '../components/layout/ContainedLayout';

class Home extends Component {
  render() {
    return (
      <ContainedLayout>
        <div className="Support">
          <Container text>
            <Segment vertical>
              <h1>{t('support')}</h1>
              {this.props.config.Contact && (
                <div className="support-item">
                  <Icon name="mail" /> {t('email')}:
                  &nbsp;&nbsp;
                  <a href={`mailto:${this.props.config.Contact}`}>{this.props.config.Contact}</a>
                  <br/>
                  <br/>
                </div>
              )}
              {(this.props.config.Files || []).map(file => (
                <div className="support-item" key={file.Name}>
                  <Icon name={file.Icon} /> {t(file.Name)}:
                  &nbsp;&nbsp;
                  <a
                    target="_blank"
                    href={file.Path}
                    rel="noopener noreferrer"
                  >
                    {file.FileName}
                  </a>
                  <br/>
                  <br/>
                </div>
              ))}
            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

const mapStateToProps = state => ({
  config: (state.api.configuration.index || {}).Information || {},
});

export default connect(mapStateToProps)(Home);
