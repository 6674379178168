import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Modal } from 'semantic-ui-react';
import DataTable from '../../generic/DataTable';
import DataTableActions from '../../generic/DataTableActions';
import MutableInput from '../../generic/MutableInput';
import MessageList from '../../generic/MessageList';
import FormGrid from '../../generic/FormGrid';
import { ZOOMLEVEL_STATUS_READY } from '../../../reducers/api/zoomlevels';
import {
  fetchZoomLevels,
  createZoomLevel,
  updateZoomLevel,
  deleteZoomLevel,
} from '../../../actions/api/zoomlevels';

export const MAX_ZOOM_LEVEL = 20000;
export const ZOOM_STEP = 12.5;

class ZoomLevels extends Component {
  // Component Methods --------------------
  constructor(props) {
    super(props);

    this.state = {
      newItem: null,
    };
  }

  componentDidMount() {
    this.loadList();
  }

  componentDidUpdate(props) {
    if (this.props.drawingId !== props.drawingId) {
      this.loadList();
    }
  }

  // Implementation --------------------
  loadList() {
    this.props.fetchZoomLevels({
      DrawingId: this.props.drawing.Id,
    });
  }

  // Ui Callbacks --------------------
  addNewItem() {
    if (this.state.newItem) return;
    this.setState({ newItem: { DrawingId: this.props.drawing.Id } });
  }

  createZoomLevel(zoomLevel) {
    this.props.createZoomLevel(zoomLevel).then(() => {
      this.setState({ newItem: null });
      this.loadList();
    });
  }

  onItemChanged(zoomLevel, newItem) {
    this.props.updateZoomLevel({ ...zoomLevel, ...newItem });
  }


  // Ui --------------------
  render() {
    return (
    <div className="ZoomLevels">

      <DataTable
        items={this.props.items}
        totalItems={this.props.totalItems}
        pageSize={this.props.totalItems}
        currentPage={1}
        disablePageSizeSelection={true}
        status={this.props.status}
        statusReady={ZOOMLEVEL_STATUS_READY}
        idField="Id"
        messages={this.props.listMessages}
        addNewItem={this.addNewItem.bind(this)}
        fields={{
          name: zl => (
            <MutableInput item={zl}
              property={'Name'}
              onChange={this.onItemChanged.bind(this, zl)}
            />
          ),
          zoom_from: zl => (
            <MutableInput item={zl} property={'ZoomFrom'}
              inputType={'number'}
              defaultValue="0"
              inputProps={{ step: ZOOM_STEP, min: 0, max: MAX_ZOOM_LEVEL }}
              onChange={this.onItemChanged.bind(this, zl)}
            />
          ),
          zoom_to: zl => (
            <MutableInput item={zl} property={'ZoomTo'}
              inputType={'number'}
              defaultValue={String(MAX_ZOOM_LEVEL)}
              inputProps={{ step: ZOOM_STEP, min: 0, max: MAX_ZOOM_LEVEL }}
              onChange={this.onItemChanged.bind(this, zl)}
            />
          ),
          actions: zl => (
            <DataTableActions
              item={zl}
              onDelete={this.props.deleteZoomLevel}
              deletionLabel="delete"
              deletionMessage="confirm_zoomlevel_deletion"
            />
            ),
        }}
      />

      {this.renderAddModal()}

  </div>
    );
  }


  renderAddModal() {
    return (
      <Modal
        open={!!this.state.newItem}
        onClose={() => this.setState({ newItem: null })}
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <Modal.Header>
          {t('add_item')} {t('zoom_level')}
        </Modal.Header>
        <Modal.Content>
          <MessageList messages={this.props.messages} />
          <FormGrid
            item={this.state.newItem}
            onSubmit={this.createZoomLevel.bind(this)}
            onCancel={() => this.setState({ newItem: null })}
            status={this.props.status}
            statusReady={ZOOMLEVEL_STATUS_READY}
            fields={[
              {
                Name: {
                  label: 'name', type: 'text', required: true,
                },
                ZoomFrom: {
                  label: 'zoom_from',
                  type: 'number',
                  required: true,
                  defaultValue: '0',
                  inputProps: { step: ZOOM_STEP, min: 0, max: MAX_ZOOM_LEVEL },
                },
                ZoomTo: {
                  label: 'zoom_to',
                  type: 'number',
                  required: true,
                  defaultValue: String(MAX_ZOOM_LEVEL),
                  inputProps: { step: ZOOM_STEP, min: 0, max: MAX_ZOOM_LEVEL },
                },
              },
            ]}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

ZoomLevels.propTypes = {
  drawing: PropTypes.object.isRequired,
  // State Props
  status: PropTypes.string.isRequired,
  listMessages: PropTypes.array,
  items: PropTypes.array,
  totalItems: PropTypes.number,
  // State Methods
  fetchZoomLevels: PropTypes.func,
  updateZoomLevel: PropTypes.func,
  createZoomLevel: PropTypes.func,
  deleteZoomLevel: PropTypes.func,
};

const mapStateToProps = state => ({
  status: state.api.zoomlevels.status,
  listMessages: state.api.zoomlevels.messages,
  items: state.api.zoomlevels.list.items.map(id => (state.api.zoomlevels.index[id])),
  totalItems: state.api.zoomlevels.list.total,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchZoomLevels,
  updateZoomLevel,
  createZoomLevel,
  deleteZoomLevel,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ZoomLevels);
