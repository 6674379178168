import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import DataTable, { DEFAULT_PAGE_SIZE } from '../generic/DataTable';
import DataTableActions from '../generic/DataTableActions';
import { DRAWINGS_STATUS_READY } from '../../reducers/api/drawings';
import { fetchDrawings, deleteDrawing } from '../../actions/api/drawings';
import { fetchSystems } from '../../actions/api/systems';

class DrawingsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      filterValues: [],
    };
  }

  fetchDrawings() {
    this.props.fetchDrawings({
      Filter: this.state.searchTerm,
      SystemNumbers: this.state.filterValues,
      PageSize: this.state.pageSize,
      PageNumber: this.state.currentPage,
      ParentDrawingId: this.props.parentId,
    });
  }

  componentDidMount() {
    this.fetchDrawings();
    if (this.props.features.DrawingSystems) {
      this.props.fetchSystems();
    }
  }

  editDrawing(drawing) {
    this.props.goto(`/drawings${this.props.parentId ? `/${this.props.parentId}` : ''}/${drawing.Id}`);
  }

  deleteDrawing(drawing) {
    this.props.deleteDrawing(drawing);
  }

  addDrawing(event) {
    event.preventDefault();
    this.props.goto(`/drawings${this.props.parentId ? `/${this.props.parentId}` : ''}/create`);
  }

  onPagingChanged(newPage, pageSize) {
    this.setState({
      pageSize,
      currentPage: newPage,
    }, this.fetchDrawings.bind(this));
  }

  onSearchChanged(searchTerm) {
    this.setState({ searchTerm, currentPage: 1 }, this.fetchDrawings.bind(this));
  }

  onFilterChanged(filterValues) {
    this.setState({ filterValues, currentPage: 1 }, this.fetchDrawings.bind(this));
  }


  render() {
    return (
      <div className="DrawingsList">
        <DataTable
          items={this.props.drawings}
          totalItems={this.props.totalItems}
          status={this.props.listStatus}
          statusReady={DRAWINGS_STATUS_READY}
          onSearchChanged={this.onSearchChanged.bind(this)}
          addNewItem = {this.addDrawing.bind(this)}
          filterDropdownName={t('systems')}
          filterDropdownOptions={this.props.features.DrawingSystems
            ? this.props.drawingSystems.map(s => ({
                key: s.Number,
                value: s.Number,
                text: `${s.Number}: ${s.Name}`,
              }))
            : undefined
          }
          onFilterDropdownChanged = {this.onFilterChanged.bind(this)}
          onPagingChanged={this.onPagingChanged.bind(this)}
          pageSize={this.state.pageSize}
          currentPage={this.state.currentPage}
          addItemLabel = {t(this.props.parentId ? 'add_system' : 'add_drawing')}
          idField="Id"
          messages={this.props.listMessages}
          fields={{
            name: 'Name',
            added_by: drawing => (<NavLink to={`/users/${drawing.CreatorId}`}>{drawing.CreatorName}</NavLink>),
            added_on: 'CreatedAt',
            current_version: 'ActiveVersionNumber',
            modified_by: 'ModifierName',
            modified_on: 'ModifiedAt',
            actions: drawing => (
              <DataTableActions
                item={drawing}
                itemViewPath={`/drawings${this.props.parentId ? `/${this.props.parentId}` : ''}`}
                onDelete={this.deleteDrawing.bind(this)}
                deletionLabel="drawing_deletion"
                deletionMessage="confirm_drawing_deletion"
              >
                {
                  drawing.ActiveVersionNumber ?
                    <NavLink to={`/view/${drawing.Id}/${drawing.ActiveVersionId}`}>
                      <Icon name="share" /> {t('view')}
                    </NavLink>
                  : null
                }
              </DataTableActions>
            ),
          }}
        />
      </div>
    );
  }
}

DrawingsList.propTypes = {
  goto: PropTypes.func.isRequired,
  listStatus: PropTypes.string.isRequired,
  listMessages: PropTypes.array,
  currentUser: PropTypes.object,
  submitLabel: PropTypes.string,
  parentId: PropTypes.number,
};

const mapStateToProps = state => ({
  listStatus: state.api.drawings.status,
  listMessages: state.api.drawings.messages,
  drawings: state.api.drawings.list.items.map(id => (state.api.drawings.index[id])),
  totalItems: state.api.drawings.list.total,
  currentUser: state.api.account.user,
  drawingSystems: state.api.drawingSystems.list.items
    .map(id => (state.api.drawingSystems.index[id])),
  features: (state.api.configuration.index || {}).Features || {},
});

const mapDispatchToProps = dispatch => bindActionCreators({
  goto: page => push(page),
  fetchSystems,
  fetchDrawings,
  deleteDrawing,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DrawingsList);
