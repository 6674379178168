import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { t } from "i18next";
import ConfirmationModal from "./ConfirmationModal";

export default class DataTableActions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openModal(item) {
    this.setState({ toBeDeleted: item });
  }

  closeModal() {
    this.setState({ toBeDeleted: false });

    this.props.isParent && this.props.close({ clickedItem: null });
  }

  getItemString() {
    if (this.props.item.FirstName) {
      return `${this.props.item.FirstName} ${this.props.item.LastName || ""}`;
    }

    if (this.props.item.Title) {
      return this.props.item.Title;
    }

    if (this.props.item.Name) {
      return this.props.item.Name;
    }

    return String(this.props.item.Id);
  }

  render() {
    return (
      <div className="button-group actions">
        {this.props.children}
        {this.renderEditIcon()}
        {this.props.onDelete ? (
          <Button
            className="delete-action"
            size="small"
            compact
            basic
            icon="delete"
            content={this.props.isIcon ? "" : t("delete")}
            onClick={this.openModal.bind(this, this.props.item)}
          />
        ) : null}
        <ConfirmationModal
          open={!!this.state.toBeDeleted}
          onClose={this.closeModal.bind(this)}
          onConfirm={() => {
            this.props.onDelete(this.state.toBeDeleted);
          }}
          label={this.props.deletionLabel}
          message={this.props.deletionMessage}
          messageOptions={{ title: this.getItemString() }}
        />
      </div>
    );
  }

  renderEditIcon() {
    if (this.props.itemViewPath) {
      return (
        <NavLink
          className="edit-action"
          to={`${this.props.itemViewPath}/${this.props.item.Id}`}
        >
          <Icon name="edit" /> {t("edit")}
        </NavLink>
      );
    }
    if (this.props.onEdit) {
      return (
        <Button
          className="edit-action"
          onClick={this.props.onEdit.bind(this, this.props.item)}
          style={{ cursor: "pointer" }}
        >
          <Icon name="edit" /> {t("edit")}
        </Button>
      );
    }

    return null;
  }
}

DataTableActions.propTypes = {
  itemViewPath: PropTypes.string,
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  deletionLabel: PropTypes.string,
  deletionMessage: PropTypes.string,
  children: PropTypes.node,
};
