import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Dropdown, Loader, Form } from 'semantic-ui-react';
import DataTable, { DEFAULT_PAGE_SIZE } from '../../generic/DataTable';
import TagEditor from '../../tags/TagEditor';
import { TAGS_STATUS_READY } from '../../../reducers/api/tags';
import { fetchTags } from '../../../actions/api/tags';
import { VERSIONS_STATUS_READY } from '../../../reducers/api/drawingversions';
import { fetchVersions } from '../../../actions/api/drawingversions';

class DrawingTagsPanel extends Component {
  // Component Methods --------------------
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      versionId: this.props.drawing ? this.props.drawing.ActiveVersionId : null,
    };
  }

  componentDidMount() {
    this.loadList();
    this.props.fetchVersions({ DrawingId: this.props.drawing.Id });
  }

  componentDidUpdate(props) {
    if (this.props.drawingId !== props.drawingId) {
      this.setState({ versionId: this.props.drawing.ActiveVersionId, currentPage: 1 }, () => {
        this.loadList();
        this.props.fetchVersions({ DrawingId: this.props.drawing.Id });
      });
    }
  }

  getType(tag) {
    return ((this.props.drawing || {}).TagTypes || [])
      .find(type => tag.Name && tag.Name.trim().match(new RegExp(type.Regex)));
  }

  // Ui Callbacks --------------------
  onPagingChanged(newPage, pageSize) {
    this.setState({
      pageSize,
      currentPage: newPage,
    }, this.loadList.bind(this));
  }

  onSearchChanged(searchTerm) {
    this.setState({ searchTerm, currentPage: 1 }, this.loadList.bind(this));
  }

  onVersionChanged(event, data) {
    this.setState({ versionId: data.value }, this.loadList.bind(this));
  }

  // Implementation --------------------
  loadList() {
    if (!this.state.versionId) {
      return;
    }
    this.props.fetchTags({
      Filter: this.state.searchTerm,
      PageSize: this.state.pageSize,
      PageNumber: this.state.currentPage,
      VersionId: this.state.versionId,
    });
  }

  // Ui --------------------
  render() {
    return (
      <div className="DrawingTagsPanel">
        {this.renderVersionSelection()}
        <DataTable
          items={this.props.tags}
          totalItems={this.props.totalItems}
          status={this.props.listStatus}
          statusReady={TAGS_STATUS_READY}
          onSearchChanged = {this.onSearchChanged.bind(this)}
          onPagingChanged={this.onPagingChanged.bind(this)}
          pageSize={this.state.pageSize}
          currentPage={this.state.currentPage}
          idField="Id"
          messages={this.props.listMessages}
          fields={{
            name: tag => (
              <TagEditor
                trigger={<a href={`?tag=${tag.Name}`} onClick={e => e.preventDefault()}>{tag.Name}</a>}
                tag={tag}
                drawingId={this.props.drawing.Id}
                tagTypes={this.props.drawing.TagTypes}
              />
            ),
            x: 'PointX',
            y: 'PointY',
            type: tag => (this.getType(tag) || {}).Name,
          }}
        />
      </div>
    );
  }

  renderVersionSelection() {
    return (
      <Form onSubmit={e => e.preventDefault()}>
        <Form.Group inline>
          <Form.Field>
            {
            this.props.versionsStatus !== VERSIONS_STATUS_READY ?
              <Loader active inline size="small" /> :
              <Dropdown selection
                multiple={false}
                placeholder={t('select_version')}
                options={this.props.versions.map(c => ({ key: c.Id, value: c.Id, text: `${t('version')} ${c.VersionNumber}` }))}
                value={this.state.versionId}
                onChange={this.onVersionChanged.bind(this)}
              />
            }
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

DrawingTagsPanel.propTypes = {
  drawing: PropTypes.object,
  // general
  listStatus: PropTypes.string.isRequired,
  listMessages: PropTypes.array,
  // Versions
  versions: PropTypes.array,
  versionsStatus: PropTypes.string,
  fetchVersions: PropTypes.func,
};

const mapStateToProps = state => ({
  listStatus: state.api.tags.status,
  listMessages: state.api.tags.messages,
  tags: state.api.tags.list.items.map(id => (state.api.tags.index[id])),
  totalItems: state.api.tags.list.total,
  // Versions
  versionsStatus: state.api.drawingversions.status,
  versions: state.api.drawingversions.list.items
    .map(id => (state.api.drawingversions.index[id])),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchTags,
  fetchVersions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DrawingTagsPanel);
