import { createAPIActions } from '../../lib/redux';

const {
  fetchListDispatcher,
  fetchItemDispatcher,
  createItemDispatcher,
  updateItemDispatcher,
  deleteItemDispatcher,
} = createAPIActions('drawings');

export const fetchDrawings = fetchListDispatcher;
export const fetchDrawing = fetchItemDispatcher;
export const updateDrawing = updateItemDispatcher;
export const createDrawing = createItemDispatcher;
export const deleteDrawing = deleteItemDispatcher;
