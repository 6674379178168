import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { t } from 'i18next';

const ICONS = {
  info: 'info',
  error: 'x',
  warning: 'warning',
  success: 'check',
};

export class MessageItem extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: true };
  }

  render() {
    if (!this.state.visible) {
      return false;
    }
    return (
      <Message
        onDismiss={() => this.setState({ visible: false })}
        info={this.props.type === 'info'}
        success={this.props.type === 'success'}
        negative={this.props.type === 'error'}
        icon={ICONS[this.props.type]}
        header={t(this.props.title, this.props.item)}
        content={t(this.props.body, this.props.item)}
      />
    );
  }
}

MessageItem.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  item: PropTypes.object,
};

export default class MessageList extends Component {
  render() {
    if (!this.props.messages) {
      return null;
    }

    return this.props.messages.map((message, idx) => (
      <MessageItem key={idx}
        type={message.type}
        title={message.title}
        body={message.body}
        item={message.item}
      />
    ));
  }
}

MessageList.propTypes = {
  messages: PropTypes.array,
};
