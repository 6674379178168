import React, { Component } from "react";
import PropTypes from "prop-types";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { t } from "i18next";
import { Image } from "semantic-ui-react";
import DataTable, { DEFAULT_PAGE_SIZE } from "../generic/DataTable";
import DataTableActions from "../generic/DataTableActions";
import { COMPANIES_STATUS_READY } from "../../reducers/api/companies";
import { fetchCompanies, deleteCompany } from "../../actions/api/companies";
import configData from "../../config.json";
class CompanyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    };
  }

  fetchCompanies() {
    this.props.fetchCompanies({
      Filter: this.state.searchTerm,
      PageSize: this.state.pageSize,
      PageNumber: this.state.currentPage,
    });
  }

  componentDidMount() {
    this.fetchCompanies();
  }

  editCompany(company) {
    this.props.goto(`/companies/${company.Id}`);
  }

  deleteCompany(company) {
    this.props.deleteCompany(company);
  }

  addCompany(event) {
    event.preventDefault();
    this.props.goto("/companies/create");
  }

  onPagingChanged(newPage, pageSize) {
    this.setState(
      {
        pageSize,
        currentPage: newPage,
      },
      this.fetchCompanies.bind(this)
    );
  }

  onSearchChanged(searchTerm) {
    this.setState(
      { searchTerm, currentPage: 1 },
      this.fetchCompanies.bind(this)
    );
  }

  render() {
    return (
      <div className="CompanyList">
        <DataTable
          items={this.props.companies}
          totalItems={this.props.totalItems}
          status={this.props.listStatus}
          statusReady={COMPANIES_STATUS_READY}
          onSearchChanged={this.onSearchChanged.bind(this)}
          onPagingChanged={this.onPagingChanged.bind(this)}
          pageSize={this.state.pageSize}
          currentPage={this.state.currentPage}
          addNewItem={this.addCompany.bind(this)}
          addItemLabel={t("add_company")}
          idField="Id"
          messages={this.props.listMessages}
          fields={{
            name: "Name",
            logo: (company) =>
              !company.HasLogo ? null : (
                <Image
                  src={`${configData.BackendUrl}/api/companies/${company.Id}/image`}
                  size="mini"
                />
              ),
            description: "Description",
            actions: (company) => (
              <DataTableActions
                item={company}
                itemViewPath="/companies"
                onDelete={this.deleteCompany.bind(this)}
                deletionLabel="company_deletion"
                deletionMessage="confirm_company_deletion"
              />
            ),
          }}
        />
      </div>
    );
  }
}

CompanyList.propTypes = {
  goto: PropTypes.func.isRequired,
  listStatus: PropTypes.string.isRequired,
  listMessages: PropTypes.array,
  currentUser: PropTypes.object,
  submitLabel: PropTypes.string,
};

const mapStateToProps = (state) => ({
  listStatus: state.api.companies.status,
  listMessages: state.api.companies.messages,
  companies: state.api.companies.list.items.map(
    (id) => state.api.companies.index[id]
  ),
  totalItems: state.api.companies.list.total,
  currentUser: state.api.account.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goto: (page) => push(page),
      fetchCompanies,
      deleteCompany,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);
