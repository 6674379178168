import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { t } from 'i18next';

class SubsystemsSelector extends Component {
  render() {
    const drawing = (this.props.drawing || {}).ParentDrawing || this.props.drawing;
    if (!drawing || !drawing.SubSystems || drawing.SubSystems.length === 0) {
      return null;
    }

    return (
      <Dropdown
        placeholder={t('select_system')}
        fluid
        search
        selection
        closeOnChange
        onChange={(event, { value }) => {
          const system = [drawing, ...drawing.SubSystems].find(s => s.Id === value);
          if (system && system.Id && system.Id !== this.props.drawing.Id) {
            this.props.goto(`/view/${system.Id}/${system.ActiveVersionId}`);
          }
        }}
        options={
          [drawing, ...drawing.SubSystems]
            .filter(system => system.ActiveVersionId)
            .map(system => ({
              key: system.Id,
              value: system.Id,
              text: system.Name,
            }))
        }
        value={this.props.drawing.Id}
      />
    );
  }
}

SubsystemsSelector.propTypes = {
  drawing: PropTypes.object,
  goto: PropTypes.func,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  goto: page => push(page),
}, dispatch);

export default connect(null, mapDispatchToProps)(SubsystemsSelector);
