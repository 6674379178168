import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import api from './api';
import ui from './ui';

export default combineReducers({
  routing: routerReducer,
  api,
  ui,
});
