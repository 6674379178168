import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { t } from "i18next";
import {
  Form,
  TextArea,
  Button,
  Segment,
  Comment,
  Icon
} from "semantic-ui-react";
import { deleteComment } from "../../../actions/api/comments";

import DataTableActions from "../../generic/DataTableActions";
import DataTable from "../../generic/DataTable";

import '../../../styles/ViewerComments.css';

const PX1TRANSPARENTGIF =
  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

class ViewerComments extends Component {
  // Component Methods --------------------------
  constructor(props) {
    super(props);

    this.state = { selectedComment: { Body: "" } };
  }

  // Ui callbacks --------------------------
  onCommentClicked(comment) {
    /*
    if (this.props.updateComment) {
      this.setState({ selectedComment: comment });
    }
    */
    if (this.props.onCommentClicked) {
      this.props.onCommentClicked(comment);
    }
  }

  onBodyChanged(event) {
    this.setState({
      selectedComment: {
        ...this.state.selectedComment,
        Body: event.target.value
      }
    });
  }

  clearComment() {
    this.setState({ selectedComment: { Body: "" } });
  }

  saveComment() {
    if (this.state.selectedComment.Id) {
      this.props.updateComment(this.state.selectedComment);
    }
  }

  onBtnClicked() {
    this.clearComment();
    this.props.onCommentBtnClicked();
  }

  onEditComment(comment) {
    this.setState({ editing: comment.Id });
  }

  // Ui --------------------------
  render() {
    return (
      <Segment vertical className="ViewerComments">
        <h2>{t("comments")}</h2>
        <Comment.Group>
          {this.props.comments.map(c => (
            <Comment key={c.Id}>
              <Comment.Avatar
                src={`/api/users/${c.CreatorId}/image`}
                onError={e => {
                  e.target.src = PX1TRANSPARENTGIF;
                }}
              />
              <Comment.Content>
                <Comment.Author
                  onClick={this.onCommentClicked.bind(this, c)}
                  style={{ cursor: "pointer" }}
                >
                  {c.Title || "-"}
                </Comment.Author>
                <Comment.Text>{c.Body}</Comment.Text>
                <Comment.Metadata>
                  <span>
                    <Icon name="user" /> {c.CreatorName}{" "}
                  </span>
                  <span>
                    <Icon name="calendar" /> {DataTable.format(c.CreatedAt)}
                  </span>
                  <span>
                    <DataTableActions
                      isIcon={true}
                      key="actions"
                      item={c}
                      onDelete={this.props.deleteComment}
                      deletionLabel="comment_deletion"
                      deletionMessage="confirm_comment_deletion"
                    />
                  </span>
                </Comment.Metadata>
              </Comment.Content>
            </Comment>
          ))}
        </Comment.Group>
        {/*
            this.props.onCommentBtnClicked ?
            <Segment vertical style={{ textAlign: 'right' }}>
              <Button compact primary
                onClick={this.onBtnClicked.bind(this)}>
                {t(this.props.isAddingComment ? 'cancel' : 'add_comment')}
              </Button>
            </Segment> :
            null
          */}

        {!this.state.selectedComment.Id ? null : (
          <Form>
            <TextArea
              value={this.state.selectedComment.Body}
              onChange={this.onBodyChanged.bind(this)}
            />
            <Segment vertical>
              <Button primary onClick={this.saveComment.bind(this)}>
                {t("save")}
              </Button>
              <Button secondary onClick={this.clearComment.bind(this)}>
                {t("cancel")}
              </Button>
            </Segment>
          </Form>
        )}
      </Segment>
    );
  }
}

ViewerComments.propTypes = {
  // Params
  comments: PropTypes.array,
  onCommentClicked: PropTypes.func,
  updateComment: PropTypes.func,
  onCommentBtnClicked: PropTypes.func,
  isAddingComment: PropTypes.bool,
  // State
  commentsMessages: PropTypes.array,
  commentsStatus: PropTypes.string
};

const mapStateToProps = state => ({
  // Comments
  commentsMessages: state.api.comments.messages,
  commentsStatus: state.api.comments.status
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteComment
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewerComments);
