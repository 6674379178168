import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormGrid from '../../generic/FormGrid';
import { COMPANIES_STATUS_READY } from '../../../reducers/api/companies';
import { fetchCompanies } from '../../../actions/api/companies';
import { VERSIONS_STATUS_READY } from '../../../reducers/api/drawingversions';
import { fetchVersions } from '../../../actions/api/drawingversions';
import { fetchSystems } from '../../../actions/api/systems';
import { getDrawingWithImage } from '../../../lib/helpers';

const PARSER_STATUS_READY = 1;

class DrawingInfoPanel extends Component {
  componentDidMount() {
    if (this.props.features.Companies) {
      this.props.fetchCompanies();
    }
    this.props.fetchVersions({ DrawingId: this.props.drawing.Id });
    this.props.fetchSystems();
  }

  componentDidUpdate(props) {
    if (this.props.drawing.Id !== props.drawing.Id) {
      this.props.fetchVersions({ DrawingId: this.props.drawing.Id });
    }
  }

  renderForm() {
    return (
      <FormGrid
        item={getDrawingWithImage(this.props.drawing, this.props.drawingSystems)}
        onSubmit={drawing => (this.props.upsertDrawing({
          ...drawing,
          CreatorId: drawing.CreatorId || (this.props.currentUser || {}).Id,
          ParentDrawingId: this.props.parentId,
        }))}
        onCancel={this.props.onCancel}
        messages={this.props.messages}
        status={this.props.status}
        statusReady={this.props.statusReady}
        fields={[
          {
            Name: { label: 'name', type: 'text', required: true },
            Description: { label: 'description', type: 'html' },
            ...(this.props.parentId || !this.props.features.Companies ? {} : {
              CompanyId: {
                label: 'company',
                type: 'select',
                required: true,
                options: this.props.companies.map(c => ({ key: c.Id, value: c.Id, text: c.Name })),
                ready: this.props.companiesStatus === COMPANIES_STATUS_READY,
              },
              ...(!this.props.features || !this.props.features.DrawingSystems ? {} : {
                SystemNumber: {
                  label: 'System',
                  type: 'select',
                  required: false,
                  options: [
                    { key: '', value: '', text: '' },
                    ...this.props.drawingSystems.map(s => ({
                      key: s.Number,
                      value: s.Number,
                      text: `${s.Number}: ${s.Name}`,
                    })),
                  ],
                  ready: 1,
                },
              }),
            }),
          },
          {
            Image: { label: 'image', type: 'image' },
            ActiveVersionId: {
              label: 'active_version',
              type: 'select',
              required: false,
              options: this.props.versions.filter(v => (
                v.Status === PARSER_STATUS_READY
              )).map(v => ({
                key: v.Id,
                value: v.Id,
                text: v.VersionNumber,
              })),
              ready: this.props.versionsStatus === VERSIONS_STATUS_READY,
            },
          },
        ]}
      />
    );
  }

  render() {
    return (
      <div className="DrawingInfoPanel">
        {this.renderForm()}
      </div>
    );
  }
}

DrawingInfoPanel.propTypes = {
  upsertDrawing: PropTypes.func.isRequired,
  drawing: PropTypes.object,
  features: PropTypes.object,
  status: PropTypes.string,
  statusReady: PropTypes.string.isRequired,
  messages: PropTypes.array,
  companiesStatus: PropTypes.string,
  accountStatus: PropTypes.string,
  versionsStatus: PropTypes.string,
  onCancel: PropTypes.func,
  parentId: PropTypes.number,
};

const mapStateToProps = state => ({
  companies: state.api.companies.list.items.map(id => state.api.companies.index[id]),
  companiesStatus: state.api.companies.status,
  currentUser: state.api.account.user,
  accountStatus: state.api.account.status,
  versions: state.api.drawingversions.list.items.map(id => state.api.drawingversions.index[id]),
  versionsStatus: state.api.drawingversions.status,
  drawingSystems: state.api.drawingSystems.list.items
    .map(id => (state.api.drawingSystems.index[id])),
  features: (state.api.configuration.index || {}).Features || {},
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCompanies,
  fetchVersions,
  fetchSystems,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DrawingInfoPanel);
