import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { AzureAD } from 'react-aad-msal';
import { ACCOUNT_STATUS_READY } from '../../reducers/api/account';
import { login, clearMessages, azureLogin } from '../../actions/api/account';
import FormGrid from '../generic/FormGrid';
import instantiateProvider from '../../lib/azureAuthProvider';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.onAzureLogin.bind(this);
  }

  componentDidMount() {
    this.props.clearMessages();
  }

  componentDidUpdate(props) {
    if (props.currentUser && !this.props.currentUser) {
      this.props.goto('/');
    }
  }

  onSubmit(form) {
    this.props.login(form.username, form.password).then(({ result }) => {
      if (result && result.Id) {
        this.props.goto('/');
      }
    });
  }

  onAzureLogin(accountInfo) {
    if (!accountInfo || !localStorage.getItem('msal.idtoken')) return;
    const { userName } = accountInfo.account;
    const { oid } = accountInfo.account.idTokenClaims;
    const { jwtAccessToken } = accountInfo;
    console.log(accountInfo)
    const user = {
      UserName: userName,
      Email: userName,
      AzureUserId: oid,
      Token: jwtAccessToken,
    };
    this.props.azureLogin(user).then(({ result }) => {
      if (result && result.Id) {
        this.props.goto('/');
      }
    });
  }

  renderAzureLoginButton(login) {
    return (
      <div style={{ paddingTop: '5px' }}>
        <Button
          color='blue'
          onClick={login}
        >
          <Icon name='microsoft' /> Login with Azure AD
        </Button>
      </div>
    );
  } 

  render() {
    return (
      <div className="LoginForm">
        <FormGrid
          fields={[{
            username: { label: 'username', type: 'text' },
            password: { label: 'password', type: 'password' },
          }]}
          messages={this.props.accountMessages}
          onSubmit={this.onSubmit.bind(this)}
          status={this.props.accountStatus}
          statusReady={ACCOUNT_STATUS_READY}
          submitLabel="submit"
          cancelLabel={null}
        />
        <NavLink to={'/forgot-password'} >
          {t('forgot_password')}
        </NavLink>
        {this.props.features.AzureLogin && 
        <AzureAD provider={instantiateProvider} forceLogin={false} accountInfoCallback={accountInfo => this.onAzureLogin(accountInfo)}>
          {({ login: lgin }) => this.renderAzureLoginButton(lgin)}
        </AzureAD>
        }
      </div>
    );
  }
}

LoginForm.propTypes = {
  goto: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  accountStatus: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
  accountMessages: PropTypes.array,
  azureLogin: PropTypes.func.isRequired,
  features: PropTypes.object,  
};

const mapStateToProps = state => ({
  accountStatus: state.api.account.status,
  currentUser: state.api.account.user,
  accountMessages: state.api.account.messages,
  azure: state.api.account.azure,
  features: (state.api.configuration.index || {}).Features || {},
});

const mapDispatchToProps = dispatch => bindActionCreators({
  goto: page => push(page),
  login,
  azureLogin,
  clearMessages,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
