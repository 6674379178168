import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';

export default class ContainedLayout extends Component {
  render() {
    return (
      <div className="ContainedLayout">
        <Header />
        <main>{this.props.children}</main>
        <Footer />
      </div>
    );
  }
}

ContainedLayout.propTypes = {
  children: PropTypes.node,
};
