import { createAPIActions } from '../../lib/redux';

const {
  fetchListDispatcher,
  fetchItemDispatcher,
  createItemDispatcher,
  updateItemDispatcher,
  deleteItemDispatcher,
} = createAPIActions('groups');

export const fetchGroups = fetchListDispatcher;
export const fetchGroup = fetchItemDispatcher;
export const updateGroup = updateItemDispatcher;
export const deleteGroup = deleteItemDispatcher;
export const createGroup = createItemDispatcher;
