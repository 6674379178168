import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import FullscreenLayout from '../components/layout/FullscreenLayout';
import DrawingViewer from '../components/drawings/DrawingViewer';

class DrawingView extends Component {
  render() {
    const drawingVersionId = this.props.match.params.drawingVersionId ||
      (this.props.currentDrawingVersion || {}).Id;
    const drawingId = this.props.match.params.drawingId || 0;
    const { tagId } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    if (!drawingVersionId) {
      return null;
    }
    return (
      <FullscreenLayout>
        <div className="DrawingView">
          <DrawingViewer
            drawingVersionId={parseInt(drawingVersionId, 10)}
            drawingId={parseInt(drawingId, 10)}
            tagId={parseInt(tagId, 10)}
          />
        </div>
      </FullscreenLayout>
    );
  }
}

DrawingView.propTypes = {
  match: PropTypes.object.isRequired,
  currentDrawingVersion: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  currentDrawingVersion: state.api.drawingversions[props.match.params.drawingVersionId],
});

export default withRouter(connect(mapStateToProps, null)(DrawingView));
