import {
  API_STATUS_READY,
  API_STATUS_CREATING,
  createAPIReducer,
} from '../../lib/redux';

const reducer = createAPIReducer('companies');

export default reducer;

export const COMPANIES_STATUS_READY = API_STATUS_READY;
export const COMPANIES_STATUS_CREATING = API_STATUS_CREATING;

