import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Modal, Button, Dimmer, Loader, Icon } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import DataTable, { DEFAULT_PAGE_SIZE } from '../../generic/DataTable';
import MessageList from '../../generic/MessageList';
import { VERSIONS_STATUS_READY } from '../../../reducers/api/drawingversions';
import {
  fetchVersions,
  fetchVersion,
  createVersion,
} from '../../../actions/api/drawingversions';
import { updateDrawing } from '../../../actions/api/drawings';

const PARSER_STATUS_PENDING = 0;
const PARSER_STATUS_READY = 1;
const PARSER_STATUS_ERROR = 2;
const PARSER_STATUS_CONVERTING = 7;
const CHUNK_SIZE = 128 * 1024;

class DrawingVersions extends Component {
  // Component Methods --------------------
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      uploading: false,
      fileSize: 0,
      uploadedFileSize: 0,
    };
  }

  componentDidMount() {
    this.loadList();
  }


  componentDidUpdate(props) {
    if (this.props.drawingId !== props.drawingId) {
      this.setState({ currentPage: 1 }, () => this.loadList());
    }
  }

  // Ui Callbacks --------------------
  onPagingChanged(newPage, pageSize) {
    this.setState({
      pageSize,
      currentPage: newPage,
    }, this.loadList.bind(this));
  }

  onSearchChanged(searchTerm) {
    this.setState({ searchTerm, currentPage: 1 }, this.loadList.bind(this));
  }

  onAddPressed(event) {
    event.preventDefault();
    this.modal.handleOpen();
  }

  closeModal() {
    this.modal.handleClose();
  }

  onFileDrop(files) {
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ versionFile: reader.result, versionFileName: files[0].name });
    };
    reader.readAsDataURL(files[0]);
  }


  // Implementation --------------------

  loadList() {
    this.props.fetchVersions({
      Filter: this.state.searchTerm,
      PageSize: this.state.pageSize,
      PageNumber: this.state.currentPage,
      DrawingId: this.props.drawing.Id,
    });
  }

  saveVersion() {
    const fileSize = this.state.versionFile.length;
    this.setState({ uploading: true, uploadedFileSize: 0, fileSize }, () => {
      const chunks = this.state.versionFile.match(new RegExp(`.{1,${CHUNK_SIZE}}`, 'g'));
      this.uploadChunks(Date.now(), this.state.versionFile.length, chunks)
        .then((response) => {
          if (!!response && !!response.result && !!response.result.Success) {
            this.closeModal();
            this.loadList();
            this.props.fetchVersion(response.result.Result, {
              poll: item => [PARSER_STATUS_READY, PARSER_STATUS_ERROR].indexOf(item.Status) > -1,
            });
          }
        });
    });
  }

  uploadChunks(timestamp, length, [chunk, ...rest]) {
    const requestData = {
      DrawingId: this.props.drawing.Id,
      FileName: this.state.versionFileName,
      FileLength: length,
      File: chunk,
      FileId: `${this.props.drawing.Id}-${timestamp}`,
    };
    return this.props.createVersion(requestData)
      .then(response => new Promise(resolve => this.setState({
        uploadedFileSize: this.state.uploadedFileSize + chunk.length,
      }, () => (rest.length
        ? this.uploadChunks(timestamp, length, rest).then(r => resolve(r))
        : resolve(response)
      ))));
  }

  setActive(version) {
    this.props.updateDrawing({ ...this.props.drawing, ActiveVersionId: version.Id });
  }


  // Ui --------------------
  render() {
    return (
      <div className="DrawingVersionsList">
        <DataTable
          items={this.props.items}
          totalItems={this.props.totalItems}
          status={this.props.status}
          statusReady={VERSIONS_STATUS_READY}
          onSearchChanged = {this.onSearchChanged.bind(this)}
          onPagingChanged={this.onPagingChanged.bind(this)}
          pageSize={this.state.pageSize}
          currentPage={this.state.currentPage}
          idField="Id"
          messages={this.props.listMessages}
          addNewItem={this.onAddPressed.bind(this)}
          addItemLabel={'add_version'}
          fields={{
            version_num: 'VersionNumber',
            filename: version => (
              <a href={`/api/DrawingVersions/${version.Id}/File`}>
                {version.FileName}
              </a>
            ),
            size_mb: 'Size',
            added_by: 'CreatorName',
            added_on: 'CreatedAt',
            status: version => t(`drawing_parser_status_${version.Status}`),
            actions: version => (
              <div className="button-group">
                {version.Status !== PARSER_STATUS_PENDING
                  && version.Status !== PARSER_STATUS_CONVERTING
                  && (
                    <NavLink to={`/view/${version.DrawingId}/${version.Id}`}>
                      <Icon name="share" /> {t('view')}
                    </NavLink>
                  )
                }
                {version.Id !== this.props.drawing.ActiveVersionId
                  && version.Status !== PARSER_STATUS_PENDING
                  && version.Status !== PARSER_STATUS_ERROR
                  && (
                    <Button size="small" compact basic
                      content={t('set_active')}
                      onClick={this.setActive.bind(this, version)}
                    />
                  )
                }
              </div>
            ),
          }}
        />

        {this.renderAddModal()}
      </div>
    );
  }


  renderAddModal() {
    return (
      <Modal ref={(modal) => { this.modal = modal; } }>
        <Modal.Header>{t('add_version')}</Modal.Header>
        <Modal.Content>
          <MessageList messages={this.props.listMessages} />
          <Dropzone
            className={`dropzone ${this.state.versionFileName ? '' : 'empty'}`}
            onDrop={this.onFileDrop.bind(this)}
          >
            <label>{this.state.versionFileName}</label>
          </Dropzone>

        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={this.closeModal.bind(this)}>{t('cancel')}</Button>
          <Button primary
            onClick={this.saveVersion.bind(this)}
            disabled={!this.state.versionFile || !this.state.versionFileName}
          >
            {t('save')}
          </Button>
        </Modal.Actions>
        {
          this.props.status && (this.props.status !== VERSIONS_STATUS_READY) ?
            <Dimmer active inverted>
              <Loader inverted>
                {t(this.state.uploading ? 'uploading' : this.props.status)}
                {this.state.uploading && this.state.fileSize > 0 && (
                  ` (${(100 * (this.state.uploadedFileSize / this.state.fileSize)).toFixed(0)} %)`
                )}
              </Loader>
            </Dimmer>
          : null
        }
      </Modal>
    );
  }
}

DrawingVersions.propTypes = {
  drawing: PropTypes.object.isRequired,
  // general
  status: PropTypes.string.isRequired,
  listMessages: PropTypes.array,
  // Methods
  fetchVersions: PropTypes.func,
  fetchVersion: PropTypes.func,
  createVersion: PropTypes.func,
  updateDrawing: PropTypes.func,
};

const mapStateToProps = state => ({
  status: state.api.drawingversions.status,
  listMessages: state.api.drawingversions.messages,
  items: state.api.drawingversions.list.items.map(id => (state.api.drawingversions.index[id])),
  totalItems: state.api.drawingversions.list.total,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchVersions,
  fetchVersion,
  createVersion,
  updateDrawing,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DrawingVersions);
