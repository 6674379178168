import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormGrid from '../generic/FormGrid';
import { ACCOUNT_STATUS_READY } from '../../reducers/api/account';
import { changePassword } from '../../actions/api/account';

class ChangePasswordForm extends Component {
  onSubmit(state) {
    this.props.changePassword(state);
  }

  render() {
    return (
      <FormGrid item={{}}
        onSubmit={this.onSubmit.bind(this)}
        messages={this.props.accountMessages}
        status={this.props.accountStatus}
        statusReady={ACCOUNT_STATUS_READY}
        fields={[{
          oldPassword: { label: 'old_password', type: 'password', required: true },
          password: { label: 'password', type: 'password', required: true },
          confirmPassword: { label: 'confirm_password', type: 'password', required: true },
        }]}
      />
    );
  }
}

ChangePasswordForm.propTypes = {
  accountStatus: PropTypes.string,
  accountMessages: PropTypes.array,
  changePassword: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  accountStatus: state.api.account.status,
  accountMessages: state.api.account.messages,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changePassword,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
