import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export const ENTITY_NAMES = {
  USER: "ApplicationUser",
  COMPANY: "Company",
  DRAWING: "Drawing",
  SYSTEM: "Drawing",
};

export const PERMISSION_VALUES = {
  VIEW: 1,
  CONTRIBUTE: 2,
  MODERATE: 3,
};

class PermissionsCheck extends Component {
  /**
   * Check if user has the described permission
   * @param {*} user The user whose permissions we check
   * @param {*} entityName use ENTITY_NAMES
   * @param {*} requiredPermission use PERMISSION_VALUES
   * @param {*} propertyValue specify the property value this permission should have or
   */
  static userHasPermission(
    user,
    entityName,
    requiredPermission,
    propertyValue
  ) {
    if (!user) {
      // No Logged in user
      return false;
    }
    if (!requiredPermission) {
      // Permission is not specified
      return true;
    }
    return user.Permissions.find(
      (p) =>
        p.EntityType === entityName &&
        //(p.PropertyValue == null || p.PropertyValue === propertyValue) &&
        p.Grant >= requiredPermission
    );
  }

  /**
   * User has the specified permission for ANY PropertyValue
   */
  static userHasAnyPermission(user, entityName, requiredPermission) {
    if (!user) {
      // No Logged in user
      return false;
    }
    if (!requiredPermission) {
      // Permission is not specified
      return true;
    }
    return user.Permissions.find(
      (p) => p.EntityType === entityName && p.Grant >= requiredPermission
    );
  }

  // check permissions
  hasPermission() {
    if (!this.props.features.Permissions) {
      return true;
    }

    return this.props.anyPropertyValue
      ? PermissionsCheck.userHasAnyPermission(
          this.props.user,
          this.props.entityName,
          this.props.requiredPermission
        )
      : PermissionsCheck.userHasPermission(
          this.props.user,
          this.props.entityName,
          this.props.requiredPermission,
          this.props.propertyValue
        );
  }

  // Render Content
  render() {
    return this.hasPermission()
      ? this.props.children
      : this.renderUnauthorized();
  }

  renderUnauthorized() {
    if (!this.props.showUnauthorized) return null;
    return <h3>Unauthorized</h3>;
  }
}

PermissionsCheck.propTypes = {
  // Params
  entityName: PropTypes.string.isRequired,
  requiredPermission: PropTypes.number.isRequired,
  anyPropertyValue: PropTypes.bool, // if he has any property value
  propertyValue: PropTypes.number, // OR has this specific propertyValue (or null for admin)
  showUnauthorized: PropTypes.bool,
  // Children
  children: PropTypes.node.isRequired,
  // From State
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.api.account.user,
  features: (state.api.configuration.index || {}).Features || {},
});

export default connect(mapStateToProps, null)(PermissionsCheck);
