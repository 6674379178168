import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import DataTable, { DEFAULT_PAGE_SIZE } from '../generic/DataTable';
import DataTableActions from '../generic/DataTableActions';
import { SYSTEMS_STATUS_READY } from '../../reducers/api/systems';
import { fetchSystems, deleteSystem } from '../../actions/api/systems';

class SystemsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      filterValues: [],
    };
  }

  fetchSystems() {
    this.props.fetchSystems({
      Filter: this.state.searchTerm,
      PageSize: this.state.pageSize,
      PageNumber: this.state.currentPage,
    });
  }

  componentDidMount() {
    this.fetchSystems();
  }

  editSystem(system) {
    this.props.goto(`/systems/${system.Id}`);
  }

  deleteSystem(system) {
    this.props.deleteSystem(system);
  }

  addSystem(event) {
    event.preventDefault();
    this.props.goto('/systems/create');
  }

  onPagingChanged(newPage, pageSize) {
    this.setState({
      pageSize,
      currentPage: newPage,
    }, this.fetchSystems.bind(this));
  }

  onSearchChanged(searchTerm) {
    this.setState({ searchTerm, currentPage: 1 }, this.fetchSystems.bind(this));
  }

  render() {
    return (
      <div className="SystemsList">
        <DataTable
          items={this.props.systems}
          totalItems={this.props.totalItems}
          status={this.props.listStatus}
          statusReady={SYSTEMS_STATUS_READY}
          onSearchChanged={this.onSearchChanged.bind(this)}
          addNewItem={this.addSystem.bind(this)}
          onPagingChanged={this.onPagingChanged.bind(this)}
          pageSize={this.state.pageSize}
          currentPage={this.state.currentPage}
          addItemLabel={t('add_system')}
          idField="Id"
          messages={this.props.listMessages}
          fields={{
            number: 'Number',
            name: 'Name',
            actions: system => (
              <DataTableActions
                item={system}
                itemViewPath="/systems"
                onDelete={this.deleteSystem.bind(this)}
                deletionLabel={t('system_deletion')}
                deletionMessage={t('confirm_system_deletion')}
              >
              </DataTableActions>
            ),
          }}
        />
      </div>
    );
  }
}

SystemsList.propTypes = {
  goto: PropTypes.func.isRequired,
  listStatus: PropTypes.string.isRequired,
  listMessages: PropTypes.array,
  currentUser: PropTypes.object,
  submitLabel: PropTypes.string,
};

const mapStateToProps = state => ({
  listStatus: state.api.drawingSystems.status,
  listMessages: state.api.drawingSystems.messages,
  systems: state.api.drawingSystems.list.items.map(id => (state.api.drawingSystems.index[id])),
  totalItems: state.api.drawingSystems.list.total,
  currentUser: state.api.account.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  goto: page => push(page),
  fetchSystems,
  deleteSystem,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SystemsList);
