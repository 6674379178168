import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Checkbox, Dropdown, Button,
  Dimmer, Loader,
  Grid, Segment,
  Image, Icon,
  Radio,
} from 'semantic-ui-react';
import RichTextEditor, { EditorValue } from 'react-rte';
import Dropzone from 'react-dropzone';
import { t } from 'i18next';
import MessageList from './MessageList';
import '../../styles/FormGrid.css';

const PX1TRANSPARENTGIF = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

class FormGrid extends Component {
  constructor(props) {
    super(props);
    this.state = { ...(this.props.item || {}) };
  }

  cancel(event) {
    event.preventDefault();
    this.setState({ ...(this.props.item || {}) }, this.props.onCancel);
  }

  onInputChange(event, data) {
    const target = data || event.target;
    const value = target.type === 'checkbox' ? target.checked : (target.value || target.label || (data ? data.value : ''));
    this.setState({ [target.name]: value }, () => {
      if (this.props.onChange && data) {
        this.props.onChange(value, data.name);
      }
    });
  }

  onFileDrop(fieldName, files) {
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ [fieldName]: reader.result }, this.props.onChange);
    };
    reader.readAsDataURL(files[0]);
  }

  getFields() {
    return this.props.fields.reduce((fields, column) => ({ ...fields, ...column }), {});
  }

  getDefaultValues() {
    const fields = this.getFields();
    return Object.keys(fields)
      .filter(f => fields[f].defaultValue)
      .reduce((values, field) => ({ ...values, [field]: fields[field].defaultValue }), {});
  }

  onSubmit(event) {
    event.preventDefault();
    const values = Object.keys(this.state).reduce((state, key) => ({
      ...state,
      [key]: this.state[key] instanceof EditorValue
        ? this.state[key].toString('html')
        : this.state[key],
    }), {});
    this.props.onSubmit({ ...this.getDefaultValues(), ...values });
  }

  renderField(fieldName, fieldSchema) {
    if (fieldSchema.type === 'image') {
      return (
        <Dropzone
          accept="image/*"
          className={`dropzone ${this.state[fieldName] ? '' : 'empty'}`}
          onDrop={this.onFileDrop.bind(this, fieldName)}
        >
          {
            this.state[fieldName] ?
              <Image size="small" src={this.state[fieldName]} onError={(e) => {
                e.target.src = PX1TRANSPARENTGIF;
              }} />
            : null
          }
        </Dropzone>
      );
    }

    if (fieldSchema.type === 'checkbox') {
      return (
        <Checkbox toggle
          {...(fieldSchema.inputProps || {})}
          name={fieldName}
          checked={this.state[fieldName]}
          onChange={this.onInputChange.bind(this)}
        />
      );
    }

    if (fieldSchema.type === 'select') {
      if (fieldSchema.ready !== undefined && !fieldSchema.ready) {
        return (<Loader active inline size="small" />);
      }
      return (
        <Dropdown fluid search selection
          {...(fieldSchema.inputProps || {})}
          multiple={!!fieldSchema.multiple}
          name={fieldName}
          placeholder={t(fieldSchema.label || fieldName)}
          options={fieldSchema.options}
          value={this.state[fieldName] || fieldSchema.defaultValue || ''}
          onChange={this.onInputChange.bind(this)}
        />
      );
    }

    if (fieldSchema.type === 'textarea') {
      return (
        <textarea
          {...(fieldSchema.inputProps || {})}
          name={fieldName}
          placeholder={t(fieldSchema.label || fieldName)}
          onChange={this.onInputChange.bind(this)}
          value={this.state[fieldName] || fieldSchema.defaultValue || ''}
        />
      );
    }

    if (fieldSchema.type === 'html') {
      const fieldValue = this.state[fieldName] || fieldSchema.defaultValue || '';
      return (
        <RichTextEditor
          {...(fieldSchema.inputProps || {})}
          className="rich-text-editor"
          name={fieldName}
          placeholder={t(fieldSchema.label || fieldName)}
          onChange={value => this.onInputChange(undefined, { name: fieldName, value })}
          value={typeof fieldValue === 'string'
            ? RichTextEditor.createValueFromString(fieldValue, 'html')
            : fieldValue
          }
        />
      );
    }

    if (fieldSchema.type === 'button') {
      return (
        <Button secondary onClick={fieldSchema.onClick}>
          {t(fieldSchema.label || fieldName)}
        </Button>
      );
    }

    if (fieldSchema.type === 'radio') {
      return (
          <Form.Group inline={true}>
          {fieldSchema.options.map((option, idx) =>
            <Form.Field key={idx}>
              <Radio
                key={option.key}
                label={option.label}
                value={option.vale}
                name={fieldName}
                checked={option.label === this.state[fieldName]}
                onChange={this.onInputChange.bind(this)}
              />
            </Form.Field>)}
        </Form.Group>
      );
    }

    return (
      <input
        {...(fieldSchema.inputProps || {})}
        type={fieldSchema.type}
        name={fieldName}
        placeholder={t(fieldSchema.label || fieldName)}
        onChange={this.onInputChange.bind(this)}
        value={this.state[fieldName] || fieldSchema.defaultValue || ''}
        readOnly={!!fieldSchema.readOnly}
        disabled={fieldSchema.disabled}
      />
    );
  }

  renderGrid() {
    return (
      <Grid columns={this.props.fields.length}>
        {this.props.fields.map((fields, idx) => (
          <Grid.Column key={idx}>
            {Object.keys(fields).map(field => (
              <Form.Field key={field}
                required={!!fields[field].required}
                readOnly={!!fields[field].readOnly}
              >
                {fields[field].hideLabel ? null : (<label>{t(fields[field].label)}</label>)}
                {this.renderField(field, fields[field])}
              </Form.Field>
            ))}
          </Grid.Column>
        ))}
      </Grid>
    );
  }

  render() {
    return (
      <div className="FormGrid">
        <MessageList messages={this.props.messages} />
        <Form onSubmit={this.onSubmit.bind(this)}>
          {this.renderGrid()}
          <Segment vertical className="children">
            {this.props.children}
          </Segment>
          <Segment vertical className="form-buttons">
            <Button primary>
              <Icon name="save" />
              {t(this.props.submitLabel || 'save')}
            </Button>
            {
              this.props.cancelLabel === undefined || this.props.cancelLabel ?
                <Button secondary onClick={this.cancel.bind(this)}>
                  <Icon name={this.props.onCancel ? 'cancel' : 'undo'} />
                  {t(this.props.cancelLabel || (this.props.onCancel ? 'cancel' : 'revert'))}
                </Button>
              : null
            }
        </Segment>
        </Form>
        {
          this.props.statusReady && (this.props.status !== this.props.statusReady) ?
            <Dimmer active inverted>
              <Loader inverted>{t(this.props.status)}</Loader>
            </Dimmer>
          : null
        }
      </div>
    );
  }
}

FormGrid.propTypes = {
  fields: PropTypes.array.isRequired,
  item: PropTypes.object,
  status: PropTypes.string,
  statusReady: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  message: PropTypes.array,
  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  showButtons: PropTypes.bool,
};

export default FormGrid;
