import React, { Component } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { t } from 'i18next';
import ContainedLayout from '../components/layout/ContainedLayout';
import UsersList from '../components/users/UsersList';

class Users extends Component {
  render() {
    return (
      <ContainedLayout>
        <div className="Users">
          <Container>
            <Segment vertical>
              <h1>{t('users')}</h1>
              <UsersList />
            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

export default Users;
