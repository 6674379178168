import React, { Component } from 'react';
import { Segment, Container } from 'semantic-ui-react';
import { t } from 'i18next';
import ContainedLayout from '../components/layout/ContainedLayout';
import GroupPermissions from '../components/permissions/GroupPermissions';
import PermissionsCheck, { ENTITY_NAMES, PERMISSION_VALUES } from '../components/generic/PermissionsCheck';

class Permissions extends Component {
  render() {
    return (
      <ContainedLayout>
        <div className="Tags">
          <Container>
            <Segment vertical>
              <h1>{t('permissions')}</h1>
                <PermissionsCheck
                  entityName={ENTITY_NAMES.USER}
                  requiredPermission={PERMISSION_VALUES.MODERATE}
                  showUnauthorized={true}
                >
                <GroupPermissions showCompanyNames={true} />
              </PermissionsCheck>
            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

export default Permissions;
