import { createAPIActions } from '../../lib/redux';

const {
  fetchListDispatcher,
  createItemDispatcher,
  updateItemDispatcher,
  deleteItemDispatcher,
} = createAPIActions('zoomlevels');

export const fetchZoomLevels = fetchListDispatcher;
export const createZoomLevel = createItemDispatcher;
export const updateZoomLevel = updateItemDispatcher;
export const deleteZoomLevel = deleteItemDispatcher;
