import { createAPIActions } from '../../lib/redux';

const {
  fetchListDispatcher,
  fetchItemDispatcher,
  createItemDispatcher,
  updateItemDispatcher,
  deleteItemDispatcher,
  invokeItemActionDispatcher,
} = createAPIActions('drawingversions');

export const fetchVersions = fetchListDispatcher;
export const fetchVersion = fetchItemDispatcher;
export const updateVersion = updateItemDispatcher;
export const createVersion = createItemDispatcher;
export const deleteVersion = deleteItemDispatcher;
export const mergeVersionLayers = (id, payload) => invokeItemActionDispatcher(id, 'merge', payload);
export const tileVersionLayers = (id, payload) => invokeItemActionDispatcher(id, 'tile', payload);
