import {
  API_STATUS_READY,
  API_STATUS_CREATING,
  createAPIReducer,
} from '../../lib/redux';

const reducer = createAPIReducer('users');

export default reducer;

export const USER_STATUS_READY = API_STATUS_READY;
export const USER_STATUS_CREATING = API_STATUS_CREATING;
