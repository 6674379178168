import React, { Component } from 'react';
import { Container, Segment } from 'semantic-ui-react';
import ContainedLayout from '../components/layout/ContainedLayout';
import DrawingsGrid from '../components/drawings/DrawingsGrid';

class Home extends Component {
  render() {
    return (
      <ContainedLayout>
        <div className="Home">
          <Container>
            <Segment vertical>
              <DrawingsGrid/>
            </Segment>
          </Container>
        </div>
      </ContainedLayout>
    );
  }
}

export default Home;
