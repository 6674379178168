import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormGrid from '../../generic/FormGrid';
import { GROUP_STATUS_READY } from '../../../reducers/api/groups';

class CompanyInfoPanel extends Component {
  getVisibleFields() {
    const fields = {
      Name: { label: 'name', type: 'text', required: true },
    };

    if (this.props.company && this.props.company.Id) {
      fields.AdminId = {
        label: 'company_admin',
        type: 'select',
        ready: this.props.groupsStatus === GROUP_STATUS_READY,
        options: [{ key: 0, value: null, text: '-' }].concat((this.props.groups || []).map(c => ({ key: c.Id, value: c.Id, text: c.Name }))),
      };
    }
    fields.Description = { label: 'description', type: 'textarea' };

    return [fields, { Logo: { label: 'logo', type: 'image' } }];
  }

  renderForm() {
    return (
      <FormGrid
        item={this.props.company && this.props.company.HasLogo ? { ...this.props.company, Logo: `/api/companies/${this.props.company.Id}/image` } : this.props.company}
        onSubmit={this.props.updateCompany}
        onCancel={this.props.onCancel}
        messages={this.props.messages}
        status={this.props.status}
        statusReady={this.props.statusReady}
        fields={this.getVisibleFields()}
      />
    );
  }

  render() {
    return (
      <div className="CompanyInfoPanel">
        {this.renderForm()}
      </div>
    );
  }
}

CompanyInfoPanel.propTypes = {
  company: PropTypes.object,
  groups: PropTypes.array,
  groupStatus: PropTypes.string,
  updateCompany: PropTypes.func.isRequired,
  status: PropTypes.string,
  statusReady: PropTypes.string.isRequired,
  messages: PropTypes.array,
  onCancel: PropTypes.func,
};

export default CompanyInfoPanel;
