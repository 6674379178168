import { createAPIActions } from '../../lib/redux';

const {
  fetchListDispatcher,
  fetchItemDispatcher,
  createItemDispatcher,
  updateItemDispatcher,
  deleteItemDispatcher,
} = createAPIActions('drawingtags');

export const fetchTags = fetchListDispatcher;
export const fetchTag = fetchItemDispatcher;
export const updateTag = updateItemDispatcher;
export const deleteTag = deleteItemDispatcher;
export const createTag = createItemDispatcher;

