import {
  ACTION_REQUEST_SUFFIX,
  ACTION_FAILURE_SUFFIX,
  createAPIActionDispatcher,
} from "../../lib/redux";

export const ACTION_LOGIN = "account/LOGIN";
export const ACTION_AZURE_LOGIN = "account/AZURE_LOGIN";
export const ACTION_LOGOUT = "account/LOGOUT";
export const ACTION_FETCH_USER = "account/FETCH_USER";
export const ACTION_CLEAR_MESSAGES = "account/CLEAR_MESSAGES";
export const ACTION_CHANGE_PASSWORD = "users/CHANGE_PASSWORD";
export const ACTION_FORGOT_PASSWORD = "users/FORGOT_PASSWORD";
export const ACTION_RESET_PASSWORD = "users/RESET_PASSWORD";

export const ACTION_LOGIN_REQUEST = `${ACTION_LOGIN}${ACTION_REQUEST_SUFFIX}`;
export const ACTION_LOGIN_FAILURE = `${ACTION_LOGIN}${ACTION_FAILURE_SUFFIX}`;
export const ACTION_AZURE_LOGIN_REQUEST = `${ACTION_AZURE_LOGIN}${ACTION_REQUEST_SUFFIX}`;
export const ACTION_AZURE_LOGIN_FAILURE = `${ACTION_AZURE_LOGIN}${ACTION_FAILURE_SUFFIX}`;
export const ACTION_LOGOUT_REQUEST = `${ACTION_LOGOUT}${ACTION_REQUEST_SUFFIX}`;
export const ACTION_LOGOUT_FAILURE = `${ACTION_LOGOUT}${ACTION_FAILURE_SUFFIX}`;
export const ACTION_FETCH_USER_REQUEST = `${ACTION_FETCH_USER}${ACTION_REQUEST_SUFFIX}`;
export const ACTION_FETCH_USER_FAILURE = `${ACTION_FETCH_USER}${ACTION_FAILURE_SUFFIX}`;
export const ACTION_CHANGE_PASSWORD_REQUEST = `${ACTION_CHANGE_PASSWORD}${ACTION_REQUEST_SUFFIX}`;
export const ACTION_CHANGE_PASSWORD_FAILURE = `${ACTION_CHANGE_PASSWORD}${ACTION_FAILURE_SUFFIX}`;
export const ACTION_FORGOT_PASSWORD_REQUEST = `${ACTION_FORGOT_PASSWORD}${ACTION_REQUEST_SUFFIX}`;
export const ACTION_FORGOT_PASSWORD_FAILURE = `${ACTION_FORGOT_PASSWORD}${ACTION_FAILURE_SUFFIX}`;
export const ACTION_RESET_PASSWORD_REQUEST = `${ACTION_RESET_PASSWORD}${ACTION_REQUEST_SUFFIX}`;
export const ACTION_RESET_PASSWORD_FAILURE = `${ACTION_RESET_PASSWORD}${ACTION_FAILURE_SUFFIX}`;

const URI_ACCOUNT_LOGIN = "/api/Account/Login";
const URI_ACCOUNT_LOGOUT = "/api/Account/Logout";
const URI_ACCOUNT_CHANGEPASS = "/api/Account/Password";
const URI_ACCOUNT_FORGOTPASS = "/api/Account/Password/Recovery";
const URI_ACCOUNT_RESETPASS = "/api/Account/Password/Reset";
const URI_ACCOUNT_FETCH = "/api/Account/Profile";
const URI_ACCOUNT_AZURE_LOGIN = "/api/Account/AzureLogin";

export function fetchUser(silent) {
  return createAPIActionDispatcher(
    ACTION_FETCH_USER,
    { silent },
    {
      uri: URI_ACCOUNT_FETCH,
    }
  );
}

export function login(username, password) {
  return createAPIActionDispatcher(
    ACTION_LOGIN,
    {},
    {
      uri: URI_ACCOUNT_LOGIN,
      body: JSON.stringify({ username, password, grant_type: "password" }),
      then: (dispatch, getState) => fetchUser()(dispatch, getState),
    }
  );
}

export function azureLogin(user) {
  return createAPIActionDispatcher(
    ACTION_LOGIN,
    {},
    {
      uri: URI_ACCOUNT_AZURE_LOGIN,
      body: JSON.stringify(user),
      then: (dispatch, getState) => fetchUser()(dispatch, getState),
    }
  );
}

export function logout() {
  return createAPIActionDispatcher(
    ACTION_LOGOUT,
    {},
    {
      uri: URI_ACCOUNT_LOGOUT,
      body: null,
    }
  );
}

export function changePassword({ oldPassword, password, confirmPassword }) {
  return createAPIActionDispatcher(
    ACTION_CHANGE_PASSWORD,
    {},
    {
      uri: URI_ACCOUNT_CHANGEPASS,
      body: JSON.stringify({
        OldPassword: oldPassword,
        NewPassword: password,
        ConfirmPassword: confirmPassword,
      }),
    }
  );
}

export function forgotPassword({ username, email, callbackUrl }) {
  return createAPIActionDispatcher(
    ACTION_FORGOT_PASSWORD,
    {},
    {
      uri: URI_ACCOUNT_FORGOTPASS,
      body: JSON.stringify({
        Username: username,
        Email: email,
        CallbackUrl: callbackUrl,
      }),
    }
  );
}

export function resetPassword(requestData) {
  return createAPIActionDispatcher(
    ACTION_RESET_PASSWORD,
    {},
    {
      uri: URI_ACCOUNT_RESETPASS,
      body: JSON.stringify(requestData),
    }
  );
}

export function clearMessages() {
  return (dispatch) => {
    dispatch({ type: ACTION_CLEAR_MESSAGES });
  };
}
