import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { goBack } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'semantic-ui-react';

class FullscreenLayout extends Component {
  render() {
    return (
      <div className="FullscreenLayout">
        <header>
          <a
            href="/"
            className="back button"
          >
            <Icon name="home" size="large" />
          </a>
        </header>
        <main>{this.props.children}</main>
      </div>
    );
  }
}

FullscreenLayout.propTypes = {
  children: PropTypes.node,
  goBack: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({ goBack }, dispatch);

export default connect(null, mapDispatchToProps)(FullscreenLayout);
