import {
  ACTION_UPDATE_VIEWER,
  ACTION_ZOOM_IN,
  ACTION_ZOOM_OUT,
  ACTION_ZOOM_TO,
  ACTION_RESET_ZOOM,
} from '../../actions/ui/viewer';
import { CommentPermissions } from '../../lib/helpers';

const initialState = {
  showComments: { permissions: CommentPermissions.Public },
  clickedItem: null,
  addingItem: { type: null, points: [] },
  addingCommentRect: false,
  addingCommentDot: false,
  addingCommentFree: false,
  commentRect: undefined,
  commentDot: undefined,
  freeComment: undefined,
  commentColor: undefined,
  zoomCords: {},
  zoomIn: false,
  zoomOut: false,
  resetZoom: false,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_UPDATE_VIEWER:
      return {
        ...state,
        ...action.props,
      };
    case ACTION_ZOOM_TO:
      return {
        ...state,
        zoomCords: action.zoomCords,
      };
    case ACTION_ZOOM_IN:
      return {
        ...state,
        zoomIn: !state.zoomIn,
      };
    case ACTION_ZOOM_OUT:
      return {
        ...state,
        zoomOut: !state.zoomOut,
      };
    case ACTION_RESET_ZOOM:
      return {
        ...state,
        resetZoom: !state.resetZoom,
      };
    default:
      return state;
  }
};
