import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import { t } from 'i18next';

export default class ConfirmationModal extends Component {
  render() {
    return (
      <Modal size="mini"
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Modal.Header>{t(this.props.label)}</Modal.Header>
        <Modal.Content>
          <p>{t(this.props.message, this.props.messageOptions)}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.props.onClose}>
            {t('no')}
          </Button>
          <Button positive onClick={() => {
            this.props.onClose();
            this.props.onConfirm();
          }}>
            {t('yes')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  messageOptions: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
