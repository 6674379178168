import {
  API_STATUS_READY,
  API_STATUS_CREATING,
  createAPIReducer,
} from '../../lib/redux';

const reducer = createAPIReducer('permissions');

export default reducer;

export const PERMISSION_STATUS_READY = API_STATUS_READY;
export const PERMISSION_STATUS_CREATING = API_STATUS_CREATING;
