export default class Cancelable {
  constructor(...args) {
    this.promise = new Promise(...args);
    this.isCancelled = false;
    this.cancelables = [];
  }

  cancel() {
    this.isCancelled = true;
    this.cancelables.forEach(c => c.cancel());
  }

  then(resolve, ...args) {
    this.promise = this.promise.then((...values) => {
      if (this.isCancelled) {
        return null;
      }
      const result = resolve(...values);
      if (result instanceof Promise) {
        this.promise = result;
      }
      if (result instanceof Cancelable) {
        this.cancelables.push(result);
      }
      return result;
    }, ...args);

    return this;
  }

  catch(...args) {
    this.promise = this.promise.catch(...args);
    return this;
  }
}
