import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { Tab } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { DRAWINGS_STATUS_READY } from '../../reducers/api/drawings';
import { fetchDrawing, updateDrawing, createDrawing } from '../../actions/api/drawings';

import MessageList from '../generic/MessageList';
import DrawingInfoPanel from './panels/DrawingInfoPanel';
import DrawingVersions from './panels/DrawingVersionPanel';
import GroupPermissions from '../permissions/GroupPermissions';
import CommentsList from '../comments/CommentsList';
import DrawingLayers from './panels/DrawingLayerPanel';
import DrawingsList from './DrawingsList';
import ZoomLevels from './panels/DrawingZoomLevelPanel';
import DrawingTagsPanel from './panels/DrawingTagsPanel';
import DrawingTagTypes from './panels/DrawingTagTypesPanel';

class DrawingEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
  }

  componentDidMount() {
    this.fetchCurrentDrawing(this.props);
  }

  componentDidUpdate(props) {
    if (this.props.drawingId !== props.drawingId) {
      this.fetchCurrentDrawing(this.props);
      this.setState({ activeIndex: 0 });
    }
  }

  fetchCurrentDrawing(props) {
    if (!this.isCreate(props)) {
      props.fetchDrawing(props.drawingId);
    }
    if (this.props.parentId) {
      props.fetchDrawing(props.parentId);
    }
  }

  isCreate(props) {
    return (props || this.props).drawingId === 'create';
  }

  upsert(drawing) {
    if (!this.isCreate()) {
      this.props.updateDrawing(drawing);
    } else {
      this.props.createDrawing(drawing).then((response) => {
        if (response && response.result && response.result.Success && response.result.Result) {
          this.props.goto(`/drawings${this.props.parentId ? `/${this.props.parentId}` : ''}/${response.result.Result}`);
        }
      });
    }
  }

  renderForm() {
    const panes = [
      {
        menuItem: { key: 'info', icon: 'info', content: t('info') },
        render: () => (
          <Tab.Pane>
            <DrawingInfoPanel
              drawing={this.props.drawing || {}}
              upsertDrawing={this.upsert.bind(this)}
              parentId={this.props.parentId}
              status={this.props.drawingStatus}
              statusReady={DRAWINGS_STATUS_READY}
              onCancel={this.isCreate() ? () => this.props.goto('/drawings') : undefined}
            />
          </Tab.Pane>
        ),
      },
    ].concat(this.isCreate() ? [] : [
      {
        menuItem: { key: 'versions', icon: 'clone', content: t('versions') },
        render: () => (
          <Tab.Pane>
            <DrawingVersions drawing={this.props.drawing || {}} />
          </Tab.Pane>
        ),
      },
      ...(!this.props.features.Layers ? [] : [
        {
          menuItem: { key: 'layers', icon: 'numbered list', content: t('layers') },
          render: () => (
            <Tab.Pane>
              <DrawingLayers drawing={this.props.drawing || {}} />
            </Tab.Pane>
          ),
        },
        {
          menuItem: { key: 'zoom_levels', icon: 'zoom', content: t('zoom_levels') },
          render: () => (
            <Tab.Pane>
              <ZoomLevels drawing={this.props.drawing || {}} />
            </Tab.Pane>
          ),
        },
      ]),
      ...(!this.props.features.Comments ? [] : [{
        menuItem: { key: 'comments', icon: 'comments', content: t('comments') },
        render: () => (
          <Tab.Pane>
            <CommentsList drawing={this.props.drawing || {}} />
          </Tab.Pane>
        ),
      }]),
      {
        menuItem: { key: 'tags', icon: 'tag', content: t('tags') },
        render: () => (
          <Tab.Pane>
            <DrawingTagsPanel
            drawing={this.props.drawing || {}}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: { key: 'tag_types', icon: 'code', content: t('tag_types') },
        render: () => (
          <Tab.Pane>
            <DrawingTagTypes
            drawing={this.props.drawing || {}}
            />
          </Tab.Pane>
        ),
      },
    ].concat(this.props.parentId || !this.props.features.Subsystems ? [] : [
      {
        menuItem: { key: 'groups', icon: 'group', content: t('groups') },
        render: () => (
          <Tab.Pane>
            <GroupPermissions drawing={this.props.drawing || {}} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: { key: 'subsystems', icon: 'sitemap', content: t('subsystems') },
        render: () => (
          <Tab.Pane>
            <DrawingsList parentId={(this.props.drawing || {}).Id} />
          </Tab.Pane>
        ),
      },
    ]));

    return (
      <Tab
        panes={panes}
        activeIndex={this.state.activeIndex >= panes.length ? 0 : this.state.activeIndex}
        onTabChange={(event, { activeIndex }) => this.setState({ activeIndex })}
        menu={{
          stackable: true,
          attached: true,
          tabular: true,
          widths: panes.length,
        }}
      />
    );
  }

  render() {
    return (
      <div className="DrawingEditor">
        <MessageList messages={this.props.drawingMessages} />
        {this.renderForm()}
      </div>
    );
  }
}

DrawingEditor.propTypes = {
  drawing: PropTypes.object,
  parent: PropTypes.object,
  features: PropTypes.object,
  drawingStatus: PropTypes.string,
  drawingMessages: PropTypes.array,
  drawingId: PropTypes.string.isRequired,
  goto: PropTypes.func.isRequired,
  fetchDrawing: PropTypes.func.isRequired,
  updateDrawing: PropTypes.func.isRequired,
  createDrawing: PropTypes.func.isRequired,
  parentId: PropTypes.number,
};

const mapStateToProps = (state, props) => ({
  drawing: state.api.drawings.index[props.drawingId],
  parent: state.api.drawings.index[props.parentId],
  drawingStatus: state.api.drawings.status,
  drawingMessages: state.api.drawings.messages,
  features: (state.api.configuration.index || {}).Features || {},
});

const mapDispatchToProps = dispatch => bindActionCreators({
  goto: page => push(page),
  fetchDrawing,
  updateDrawing,
  createDrawing,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DrawingEditor);
