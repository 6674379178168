import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { t } from 'i18next';
import { Menu, Icon, Search, Dropdown, Checkbox, Button } from 'semantic-ui-react';

import SubsystemsSelector from './SubsystemsSelector';
import { updateViewer } from '../../../actions/ui/viewer';
import { fetchUsers } from '../../../actions/api/users';
import { CommentPermissions } from '../../../lib/helpers';

const MAX_RESULT_LENGTH = 50;

class ViewerToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      searchLoading: false,
      showMenu: true,
      showComments: false,
    };
  }

  componentDidMount() {
    this.props.fetchUsers();
  }

  getTagType(tag) {
    return ((this.props.drawing || {}).TagTypes || [])
      .find(type => tag.Name && tag.Name.trim().match(new RegExp(type.Regex)));
  }

  onSearchChanged(e, { value }) {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.setState({ searchLoading: true });

    this.searchTimeout = setTimeout(() => {
      const searchResults = {};

      const matchingComments = this.props.comments
        .filter(c => c.Title.toLowerCase().indexOf(value.toLowerCase()) >= 0)
        .map(ci => ({ title: ci.Title, key: `comments-${ci.Id}` }));
      if (matchingComments.length) {
        searchResults.comments = {
          name: t('comments'),
          results: matchingComments.slice(0, MAX_RESULT_LENGTH),
        };
      }
      const matchingTags = this.props.tags
        .filter(tag => this.getTagType(tag))
        .filter(tag => tag.Name.toLowerCase().indexOf(value.toLowerCase()) >= 0)
        .map(tag => ({ title: tag.Name, key: `tags-${tag.Id}` }));
      if (matchingTags.length) {
        searchResults.tags = {
          name: t('tags'),
          results: matchingTags.slice(0, MAX_RESULT_LENGTH),
        };
      }

      this.setState({ searchResults, searchLoading: false });
    }, 400);
  }

  onSearchResultSelected(event, data) {
    if (this.props.onSearchResultSelected) {
      const [collection, id] = data.result.key.split('-');
      this.props.onSearchResultSelected(this.props[collection].find(item => (
        item.Id === parseInt(id, 10)
      )));
    }
  }

  onShowCommentsChanged(e, data) {
    this.props.updateViewer({
      showComments:
      data.checked
        ? {
          permissions: CommentPermissions.Public,
        }
        : undefined,
    });
  }

  onCommentPermissionSelected(e, data) {
    this.props.updateViewer({
      showComments: {
        ...this.props.showComments,
        permissions: data.value,
      },
    });
  }

  onSharedUserSelected(append, e, data) {
    const sharedUsers = this.props.showComments.sharedUsers || [];
    this.props.updateViewer({
      showComments: {
        ...this.props.showComments,
        sharedUsers: append ? [...sharedUsers || [], data.value] : data.value,
      },
    });
  }

  onGraphReset() {
    this.props.updateViewer({ graphResetTimestamp: Date.now() });
  }

  render() {
    return (
      <div className="ViewerToolbar">
        <div className="toggle-buttons">
          <Icon
            onClick={() => this.setState({ showMenu: !this.state.showMenu })}
            name='content'
            size='large'
          />
          <Icon
            onClick={this.props.toggleSidebar}
            name={'info circle'}
            size={'large'}
          />
        </div>
        <Menu position="left" stackable compact size="mini" style={{
          opacity: this.state.showMenu ? 1 : 0,
          pointerEvents: this.state.showMenu ? 'auto' : 'none',
        }}>
          {this.renderSearch()}
          {this.renderLinksCheckbox()}
          {this.props.features.Comments && this.renderCommentsCheckbox()}
          {this.renderGraphControls()}
          {this.renderSubsystemsSelector()}
        </Menu>
      </div>
    );
  }

  renderGraphControls() {
    if (!this.props.drawingVersion.Lines || !this.props.drawingVersion.Lines.length) {
      return null;
    }

    return (
      <Menu.Item className="graph-controls" key="graph">
        <Button onClick={() => this.onGraphReset()} basic compact icon>
          <Icon name="undo" />{' '}{t('reset_graph')}
        </Button>
      </Menu.Item>
    );
  }

  renderSubsystemsSelector() {
    return (
      <Menu.Item className="subsystems-selector" key="subsystems">
        <SubsystemsSelector drawing={this.props.drawing} />
      </Menu.Item>
    );
  }

  renderSearch() {
    if (!this.state.showMenu) return null;

    return (
      <Menu.Item className="search-container" key="search">
        <Search
          category
          loading={this.state.searchLoading}
          onSearchChange={this.onSearchChanged.bind(this)}
          results={this.state.searchResults}
          onResultSelect={this.onSearchResultSelected.bind(this)}
          placeholder={this.props.features.Comments ? t('search_tags_comments') : t('search_tags')}
        />
      </Menu.Item>
    );
  }

  renderLinksCheckbox() {
    if (!this.state.showMenu) return null;
    if (!this.props.onShowLinksChanged) {
      return null;
    }
    return (
      <Menu.Item key="links">
        <Checkbox
          checked={this.props.showLinks}
          label={`${t('show')} ${t('links')}`}
          onChange={this.props.onShowLinksChanged}
        />
      </Menu.Item>
    );
  }

  renderCommentsCheckbox() {
    if (!this.state.showMenu) return null;
    if (!this.onShowCommentsChanged.bind(this)) {
      return null;
    }

    return [
      <Menu.Item className="comments-container" key="comments">
        <Checkbox
          checked={!!this.props.showComments}
          label={`${t('show')} ${t('comments')}`}
          onChange={this.onShowCommentsChanged.bind(this)}
        />
        {this.props.showComments && (
          <span className="buttons-container">
            <Dropdown
              options={Object.keys(CommentPermissions).map(p => ({
                key: CommentPermissions[p],
                text: p,
                value: CommentPermissions[p],
                onClick: this.onCommentPermissionSelected.bind(this),
              }))}
              value={this.props.showComments.permissions || CommentPermissions.Public}
            />
          </span>
        )}
        {this.props.showComments
          && (this.props.showComments.permissions === CommentPermissions.Shared)
          && (
          <span className="buttons-container">
            <Dropdown
              placeholder={t('users')}
              multiple
              options={this.props.users.reduce((acc, user) => (
                  this.props.comments.find(c => c.CreatorId === user.Id && c.Permissions === 3)
                  ? [...acc, {
                    key: user.Id,
                    text: `${user.FirstName} ${user.LastName}`,
                    value: user.Id,
                    onClick: this.onSharedUserSelected.bind(this, true),
                  }]
                : acc), [])}
              value={this.props.showComments.sharedUsers}
              onChange={this.onSharedUserSelected.bind(this, false)}
            />
          </span>
        )}
        {!this.props.isAddingComment && (
          <span className="buttons-container">
            <Button onClick={() => this.props.onCommentBtnClicked(1)} basic compact icon>
              <Icon name="circle" />
            </Button>
            <Dropdown trigger={
              <Button basic compact icon>
                <Icon name="expand" />
              </Button>
            } inline icon={null}>
              <Dropdown.Menu>
                <Dropdown.Item
                  label={{
                    color: 'red',
                    empty: true,
                    circular: false,
                    size: 'huge',
                  }}
                  onClick={() => this.props.onCommentBtnClicked(2, '#ff000050')}
                />
                <Dropdown.Item
                  label={{
                    color: 'yellow',
                    empty: true,
                    circular: false,
                    size: 'huge',
                  }}
                  onClick={() => this.props.onCommentBtnClicked(2, '#ffff0050')}
                />
                <Dropdown.Item
                  label={{
                    color: 'blue',
                    empty: true,
                    circular: false,
                    size: 'huge',
                  }}
                  onClick={() => this.props.onCommentBtnClicked(2, '#0000ff50')}
                />
                <Dropdown.Item
                  label={{
                    color: 'green',
                    empty: true,
                    circular: false,
                    size: 'huge',
                  }}
                  onClick={() => this.props.onCommentBtnClicked(2, '#00ff0050')}
                />
              </Dropdown.Menu>
            </Dropdown>
            <Button
              onClick={() => this.props.onCommentBtnClicked(3)}
              basic
              compact
              icon
            >
              <Icon name="pencil" />
            </Button>
          </span>
        )}
        {this.props.isAddingComment && (
          <Button onClick={() => this.props.onCommentBtnClicked()} basic compact>
            {t('cancel')}
          </Button>
        )}
      </Menu.Item>,
    ];
  }
}

ViewerToolbar.propTypes = {
  drawing: PropTypes.object,
  drawingVersion: PropTypes.object,
  toggleSidebar: PropTypes.func,
  showLinks: PropTypes.bool,
  onShowLinksChanged: PropTypes.func,
  showComments: PropTypes.object,
  onCommentBtnClicked: PropTypes.func,
  isAddingComment: PropTypes.bool,
  tags: PropTypes.array,
  onSearchResultSelected: PropTypes.func,
  features: PropTypes.object,
  comments: PropTypes.array,
  fetchUsers: PropTypes.func,
};


const mapStateToProps = state => ({
  showComments: state.ui.viewer.showComments,
  features: (state.api.configuration.index || {}).Features || {},
  users: state.api.users.list.items.map(id => state.api.users.index[id]),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateViewer,
  fetchUsers,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewerToolbar);
