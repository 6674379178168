import {
  API_STATUS_READY,
  API_STATUS_CREATING,
  createAPIReducer,
} from '../../lib/redux';

const reducer = createAPIReducer('groups');

export default reducer;

export const GROUP_STATUS_READY = API_STATUS_READY;
export const GROUP_STATUS_CREATING = API_STATUS_CREATING;
