import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { t } from 'i18next';
import { USER_STATUS_READY } from '../../reducers/api/users';
import { fetchUser, updateUser, createUser } from '../../actions/api/users';
import { ACCOUNT_STATUS_READY } from '../../reducers/api/account';
import { forgotPassword } from '../../actions/api/account';
import PermissionsCheck, { ENTITY_NAMES, PERMISSION_VALUES } from '../generic/PermissionsCheck';

import UserInfoPanel from './panels/UserInfoPanel';
import UsersExcelImport from './panels/UsersExcelImport';
import CommentsList from '../comments/CommentsList';
import GroupList from '../groups/GroupList';
import MessageList from '../generic/MessageList';

class UserEditor extends Component {
  componentDidMount() {
    this.fetchCurrentUser(this.props);
  }

  componentDidUpdate(props) {
    if (this.props.userId !== props.userId) {
      this.fetchCurrentUser(this.props);
    }
  }

  fetchCurrentUser(props) {
    if (!this.isCreate(props)) {
      props.fetchUser(props.userId);
    }
  }

  isCreate(props) {
    return (props || this.props).userId === 'create';
  }

  isUserProfile() {
    return this.props.user && this.props.user.Id === this.props.currentUser.Id;
  }

  upsert(user) {
    if (!this.isCreate()) {
      this.props.updateUser(user);
    } else {
      this.props.createUser(user).then((response) => {
        if (response && response.result && response.result.Success && response.result.Result) {
          this.props.goto(`/users/${response.result.Result}`);
        }
      });
    }
  }

  resetPassword() {
    const requestData = { username: this.props.user.UserName, callbackUrl: `${window.location.origin}/reset-password` };
    this.props.forgotPassword(requestData);
  }

  addGroupToUser(group) {
    const groupList = (this.props.user.GroupIds || []).concat([group.Id]);
    this.props.updateUser({ ...this.props.user, GroupIds: groupList });
  }

  removeGroupFromUser(group) {
    if (!this.props.user.GroupIds || !this.props.user.GroupIds.includes(group.Id)) return;
    const groupsList = this.props.user.GroupIds.filter(uId => uId !== group.Id);
    this.props.updateUser({ ...this.props.user, GroupIds: groupsList });
  }

  onGroupChecked(group, isChecked) {
    if (isChecked) {
      this.addGroupToUser(group);
    } else {
      this.removeGroupFromUser(group);
    }
  }


  getVisiblePanels() {
    if (this.isCreate()) {
      return [{
        menuItem: { key: 'import', icon: 'cloud upload', content: t('from_csv') },
        render: () => (
          <Tab.Pane>
            <UsersExcelImport />
          </Tab.Pane>
        ),
      }];
    }


    const groupsPane = {
      menuItem: { key: 'groups', icon: 'group', content: t('groups') },
      render: () => (
        <Tab.Pane>
          <GroupList
            onRowChecked = {this.onGroupChecked.bind(this)}
            selectedGroupIds = {
              this.props.user.GroupIds || null}
            companyId={this.props.user.CompanyId}
          />
        </Tab.Pane>
      ),
    };

    const commentsPane = {
      menuItem: { key: 'comments', icon: 'comments', content: t('comments') },
      render: () => (
        <Tab.Pane>
          <CommentsList userId={this.props.userId} />
        </Tab.Pane>
      ),
    };

    const hasPerm = this.props.user &&
      PermissionsCheck.userHasPermission(
        this.props.currentUser,
        ENTITY_NAMES.COMPANY, PERMISSION_VALUES.CONTRIBUTE, this.props.user.CompanyId,
      );

    return [
      ...(hasPerm ? [groupsPane] : []),
      ...(this.props.features.Comments ? [commentsPane] : []),
    ];
  }

  renderForm() {
    const panes = [
      {
        menuItem: { key: 'info', icon: 'info', content: t('info') },
        render: () => (
          <Tab.Pane>
            <UserInfoPanel
              user={this.isCreate() ? {} : this.props.user}
              onSubmit={this.upsert.bind(this)}
              resetPassword={this.resetPassword.bind(this)}
              status={
                this.props.userStatus !== USER_STATUS_READY
                  ? this.props.userStatus
                  : this.props.forgotPassStatus
              }
              statusReady={
                this.props.userStatus !== USER_STATUS_READY
                  ? USER_STATUS_READY
                  : ACCOUNT_STATUS_READY
              }
              onCancel={this.isCreate() ? () => this.props.goto('/users') : undefined}
            />
          </Tab.Pane>
        ),
      },
    ].concat(this.getVisiblePanels());

    return (
      <Tab panes={panes} menu={{
        stackable: true,
        attached: true,
        tabular: true,
        widths: panes.length,
      }} />
    );
  }

  render() {
    const editPermission = this.isUserProfile() ? 0 : PERMISSION_VALUES.VIEW;
    return (
      <div className="UserEditor">
        <PermissionsCheck
            entityName={ENTITY_NAMES.USER}
            requiredPermission={this.isCreate()
              ? PERMISSION_VALUES.CONTRIBUTE : editPermission
            }
            showUnauthorized={true}
            >
          <MessageList messages={this.props.userMessages.concat(this.props.forgotPassMessages)} />
          {this.renderForm()}
        </PermissionsCheck>
      </div>
    );
  }
}

UserEditor.propTypes = {
  user: PropTypes.object,
  currentUser: PropTypes.object, // Logged in user
  userStatus: PropTypes.string,
  forgotPassStatus: PropTypes.string,
  userMessages: PropTypes.array,
  forgotPassMessages: PropTypes.array,
  userId: PropTypes.string.isRequired,
  features: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  user: state.api.users.index[props.userId],
  currentUser: state.api.account.user,
  userStatus: state.api.users.status,
  forgotPassStatus: state.api.account.status,
  userMessages: state.api.users.messages,
  forgotPassMessages: state.api.account.messages,
  features: (state.api.configuration.index || {}).Features || {},
});

const mapDispatchToProps = dispatch => bindActionCreators({
  goto: page => push(page),
  fetchUser,
  updateUser,
  forgotPassword,
  createUser,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserEditor);
