import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { t } from 'i18next';
import { SYSTEMS_STATUS_READY } from '../../reducers/api/systems';
import { fetchSystem, updateSystem, createSystem } from '../../actions/api/systems';

import MessageList from '../generic/MessageList';
import SystemInfoPanel from './panels/SystemInfoPanel';

class SystemEditor extends Component {
  componentDidMount() {
    this.fetchCurrentSystem(this.props);
  }

  componentDidUpdate(props) {
    if (this.props.systemId !== props.systemId) {
      this.fetchCurrentSystem(props);
    }
  }

  fetchCurrentSystem(props) {
    if (!this.isCreate(props)) {
      props.fetchSystem(props.systemId);
    }
  }

  isCreate(props) {
    return (props || this.props).systemId === 'create';
  }

  upsert(system) {
    if (!this.isCreate()) {
      this.props.updateSystem(system);
    } else {
      this.props.createSystem(system).then((response) => {
        if (response && response.result && response.result.Success && response.result.Result) {
          this.props.goto(`/systems/${response.result.Result}`);
        }
      });
    }
  }

  renderForm() {
    const panes = [
      {
        menuItem: { key: 'info', icon: 'info', content: t('info') },
        render: () => (
          <Tab.Pane>
            <SystemInfoPanel
              system={this.props.system}
              upsertSystem={this.upsert.bind(this)}
              status={this.props.systemStatus}
              statusReady={SYSTEMS_STATUS_READY}
              onCancel={this.isCreate() ? () => this.props.goto('/systems') : undefined}
            />
          </Tab.Pane>
        ),
      },
    ];

    return (
      <Tab panes={panes} menu={{
        stackable: true,
        attached: true,
        tabular: true,
        widths: panes.length,
      }} />
    );
  }

  render() {
    return (
      <div className="SystemEditor">
      <MessageList messages={this.props.systemMessages}/>
          {this.renderForm()}
      </div>
    );
  }
}

SystemEditor.propTypes = {
  system: PropTypes.object,
  systemStatus: PropTypes.string,
  systemMessages: PropTypes.array,
  systemId: PropTypes.string.isRequired,
  goto: PropTypes.func.isRequired,
  fetchSystem: PropTypes.func.isRequired,
  updateSystem: PropTypes.func.isRequired,
  createSystem: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  system: state.api.drawingSystems.index[props.systemId],
  systemStatus: state.api.drawingSystems.status,
  systemMessages: state.api.drawingSystems.messages,

});

const mapDispatchToProps = dispatch => bindActionCreators({
  goto: page => push(page),
  fetchSystem,
  updateSystem,
  createSystem,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SystemEditor);
