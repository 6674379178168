import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormGrid from '../../generic/FormGrid';
import { fetchSystems } from '../../../actions/api/systems';

class SystemInfoPanel extends Component {
  componentDidMount() {
    this.fetchSystems();
    if (this.props.system != null) {
      this.props.fetchSystems({ Id: this.props.system.Id });
    }
  }

  fetchSystems() {
    this.props.fetchSystems();
  }

  renderForm() {
    return (
      <FormGrid
        item={this.props.system && this.props.system.HasImage ?
          {
            ...this.props.system,
            Image: `/api/drawingSystems/${this.props.system.Id}/image`,
          } :
          this.props.system
        }
        onSubmit={system => (this.props.upsertSystem({
          ...system,
          CreatorId: system.CreatorId || (this.props.currentUser || {}).Id,
        }))}
        onCancel={this.props.onCancel}
        messages={this.props.messages}
        status={this.props.status}
        statusReady={this.props.statusReady}
        fields={[
          {
            Name: { label: 'name', type: 'text', required: true },
            Number: { label: 'number', type: 'text', required: true },
          },
          { Image: { label: 'image', type: 'image', required: false } },
        ]}
      />
    );
  }

  render() {
    return (
      <div className="SystemInfoPanel">
        {this.renderForm()}
      </div>
    );
  }
}

SystemInfoPanel.propTypes = {
  upsertSystem: PropTypes.func.isRequired,
  system: PropTypes.object,
  status: PropTypes.string,
  statusReady: PropTypes.string.isRequired,
  messages: PropTypes.array,
  accountStatus: PropTypes.string,
  onCancel: PropTypes.func,
};

const mapStateToProps = state => ({
  currentUser: state.api.account.user,
  accountStatus: state.api.account.status,
  systems: state.api.drawingSystems.list.items.map(id => (state.api.drawingSystems.index[id])),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchSystems,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SystemInfoPanel);
