import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import {
  Modal, Dimmer, Loader,
} from 'semantic-ui-react';

import DataTable from '../generic/DataTable';
import FormGrid from '../generic/FormGrid';
import { COMMENTS_STATUS_READY } from '../../reducers/api/comments';
import { updateComment, fetchCommentHistory } from '../../actions/api/comments';
import { fetchUsers } from '../../actions/api/users';
import { CommentPermissions } from '../../lib/helpers';
import '../../styles/CommentEditor.css';

class CommentEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreate: !this.props.comment.Id,
      body: this.props.comment.Body,
      title: this.props.comment.Title,
      drawingTagName: this.props.comment.DrawingTagName,
      commentHistory: [],
      isShared: this.props.comment.Permissions === CommentPermissions.Shared,
      users: [],
      comment: {},
    };
  }

  componentDidMount() {
    this.props.fetchUsers();
    if (this.state.isCreate) return;

    this.props.fetchCommentHistory(this.props.comment.Id)
      .then((r) => { this.setState({ commentHistory: r.result }); });
  }


  // Implementation --------------------
  save(comment) {
    comment.SharedUsers = (comment.SharedUsers || [])
      .map(ApplicationUser_Id => ({ ApplicationUser_Id }));
    if (this.props.handleSave) {
      this.props.handleSave({
        ...comment,
      });
      return;
    }

    this.props.updateComment({ ...comment })
      .then((response) => {
        if (!response || !response.result || !response.result.Success) {
          this.setState({ body: this.props.comment.Body });
        }
      });
  }

  change(permissionValue, type) {
    if (type !== 'Permissions') {
      return;
    }
    this.setState({ isShared: permissionValue === 3 });
  }

  // Ui Callbacks --------------------
  onBodyChanged(event) {
    this.setState({ body: event.target.value });
  }

  onTitleChanged(event) {
    this.setState({ title: event.target.value });
  }

  onCancel() {
    if (this.props.onCancel) this.props.onCancel();
    this.modal.handleClose();
  }

  onTagClicked(e, { value }) {
    this.setState({ drawingTagName: value });
  }

  // Ui --------------------
  render() {
    return (
      <Modal ref={(modal) => { this.modal = modal; }}
        open={this.props.open}
        trigger={this.props.triggerHtml}
        closeIcon={!this.state.isCreate}
        closeOnDimmerClick={false}
        onClose={this.props.onClose}
      >
        <Modal.Header>
          {this.state.isCreate ? t('add_comment') : (this.props.comment.Title || '-')}
        </Modal.Header>
        <Modal.Content>
          <div className="CommentEditor">
            {this.renderCommentInfo()}
          </div>
        </Modal.Content>
        {
          this.props.status && (this.props.status !== COMMENTS_STATUS_READY) ?
            <Dimmer active inverted>
              <Loader inverted>{t(this.props.status)}</Loader>
            </Dimmer>
            : null
        }
      </Modal>
    );
  }


  renderCommentInfo() {
    const comment = {
      ...this.props.comment,
      SharedUsers: (this.props.comment.SharedUsers || []).map(user => user.ApplicationUser_Id),
    };

    return (
      <FormGrid
        item={comment}
        onSubmit={this.save.bind(this)}
        onChange={this.change.bind(this)}
        onCancel={this.props.onCancel}fform
        messages={this.props.messages}
        status={this.props.status}
        statusReady={this.props.statusReady}
        fields={this.renderFormFields()}
      >
        {this.renderHistory()}
      </FormGrid>
    );
  }

  renderHistory() {
    if (this.state.isCreate) return null;
    return (
      <div>
        <h3>{t('comment')} {t('history')}</h3>
        <DataTable
          items={this.state.commentHistory || []}
          totalItems={(this.state.commentHistory || []).length}
          status={this.props.status}
          statusReady={COMMENTS_STATUS_READY}
          idField="Id"
          messages={this.props.messages}
          fields={{
            action: 'Action',
            user: 'UserName',
            date: 'Date',
          }}
        />
      </div>
    );
  }

  renderFormFields() {
    const permissionOptions = [{
      key: CommentPermissions.Public,
      value: CommentPermissions.Public,
      text: t('public'),
    },
    {
      key: CommentPermissions.Private,
      value: CommentPermissions.Private,
      text: t('private'),
    },
    {
      key: CommentPermissions.Shared,
      value: CommentPermissions.Shared,
      text: t('shared'),
    }];
    if (this.state.isCreate) {
      return !this.state.isShared ? [{
        Title: { label: 'Title', type: 'text' },
        Body: { label: 'Text', type: 'textarea' },
        Permissions: {
          label: t('permissions'),
          type: 'select',
          options: permissionOptions,
          defaultValue: CommentPermissions.Public,
        },
        DrawingTagName: {
          label: t('related_tag'),
          type: 'radio',
          options: this.props.nearbyTags.map(tag => (
            { key: tag.Id, value: tag.Id, label: tag.Name }
          )),
        },
      }]
        : [{
          Title: { label: 'Title', type: 'text' },
          Body: { label: 'Text', type: 'textarea' },
          Permissions: {
            label: t('permissions'),
            type: 'select',
            options: permissionOptions,
          },
          SharedUsers: {
            label: t('users'),
            type: 'select',
            multiple: true,
            options: this.props.users.map(user => ({
              key: user.Id, value: user.Id, text: `${user.FirstName} ${user.LastName}`,
            })),
          },
          DrawingTagName: {
            label: t('related_tag'),
            type: 'radio',
            options: this.props.nearbyTags.map(tag => (
              { key: tag.Id, value: tag.Id, label: tag.Name }
            )),
          },
        }];
    }
    return !this.state.isShared
      ? [{
        CreatorName: { label: 'CreatorName', type: 'text', disabled: true },
        CreatedAt: { label: 'CreatedAt', type: 'text', disabled: true },
        DrawingTagName: { label: t('related_tag'), type: 'text', disabled: true },
        Body: { label: 'Text', type: 'textarea' },
        Permissions: {
          label: t('permissions'),
          type: 'select',
          mutliple: false,
          options: permissionOptions,
        },
      }]
      : [{
        CreatorName: { label: 'CreatorName', type: 'text', disabled: true },
        CreatedAt: { label: 'CreatedAt', type: 'text', disabled: true },
        DrawingTagName: { label: t('related_tag'), type: 'text', disabled: true },
        Body: { label: 'Text', type: 'textarea' },
        Permissions: {
          label: t('permissions'),
          type: 'select',
          mutliple: false,
          options: permissionOptions,
        },
        SharedUsers: {
          label: t('users'),
          type: 'select',
          multiple: true,
          options: this.props.users.map(user => ({
            key: user.Id, value: user.Id, text: `${user.FirstName} ${user.LastName}`,
          })),
        },
      }];
  }
}


CommentEditor.propTypes = {
  // Callback
  handleSave: PropTypes.func, // optional
  onCancel: PropTypes.func, // optional
  onClose: PropTypes.func, // optional
  // Params
  triggerHtml: PropTypes.node,
  open: PropTypes.bool,
  comment: PropTypes.object.isRequired,
  nearbyTags: PropTypes.array, // related tag selection
  // Dispatch
  updateComment: PropTypes.func.isRequired,
  fetchCommentHistory: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  // State
  status: PropTypes.string,
  messages: PropTypes.array,
};

const mapStateToProps = state => ({
  status: state.api.comments.status,
  messages: state.api.comments.messages,
  users: state.api.users.list.items.map(id => state.api.users.index[id]),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateComment,
  fetchCommentHistory,
  fetchUsers,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CommentEditor);
