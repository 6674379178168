import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { Tab } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { COMPANIES_STATUS_READY } from '../../reducers/api/companies';
import { fetchCompany, updateCompany, createCompany } from '../../actions/api/companies';
import { updateUser } from '../../actions/api/users';
import { fetchGroups } from '../../actions/api/groups';

import MessageList from '../generic/MessageList';
import CompanyInfoPanel from './panels/CompanyInfoPanel';
import UserList from '../users/UsersList';
import GroupsList from '../groups/GroupList';


class CompanyEditor extends Component {
  componentDidMount() {
    if (!this.isCreate(this.props)) {
      this.fetchCurrentCompany(this.props);
      this.props.fetchGroups({ CompanyId: this.props.companyId });
    }
  }

  componentDidUpdate(props) {
    if (this.props.companyId !== props.companyId) {
      if (!this.isCreate(this.props)) {
        this.fetchCurrentCompany(this.props);
        this.props.fetchGroups({ CompanyId: this.props.companyId });
      }
    }
  }

  fetchCurrentCompany(props) {
    if (!this.isCreate(props)) {
      props.fetchCompany(props.companyId);
    }
  }

  isCreate(props) {
    return (props || this.props).companyId === 'create';
  }

  upsert(company) {
    if (!this.isCreate()) {
      this.props.updateCompany(company);
    } else {
      this.props.createCompany(company).then((response) => {
        if (response && response.result && response.result.Success && response.result.Result) {
          this.props.goto(`/companies/${response.result.Result}`);
        }
      });
    }
  }

  onRowChecked(userId, isChecked) {
    const user = this.props.users.find(u => u.Id === userId);
    const CompanyId = isChecked ? this.props.companyId : null;

    this.props.updateUser({ ...user, CompanyId }).then((response) => {
      if (response && response.result && response.result.Success) {
        this.fetchCurrentCompany(this.props);
      }
    });
  }

  renderForm() {
    let panes = [{
      menuItem: { key: 'info', icon: 'info', content: t('info') },
      render: () => (
        <Tab.Pane>
          <CompanyInfoPanel
            company={this.isCreate() ? {} : this.props.company}
            groups={this.props.groups}
            groupsStatus={this.props.groupsStatus}
            updateCompany={this.upsert.bind(this)}
            status={this.props.companyStatus}
            statusReady={COMPANIES_STATUS_READY}
            onCancel={this.isCreate() ? () => this.props.goto('/companies') : undefined}
          />
        </Tab.Pane>
      ),
    }];

    if (!this.isCreate()) {
      panes = panes.concat([
        {
          menuItem: { key: 'users', icon: 'user', content: t('users') },
          render: () => (
            <Tab.Pane>
              <UserList
                onRowChecked={this.onRowChecked.bind(this)}
                selectedUserIds={this.props.company.UserIds}
              />
            </Tab.Pane>
          ),
        },
        {
          menuItem: { key: 'groups', icon: 'group', content: t('groups') },
          render: () => (
            <Tab.Pane>
              <GroupsList
                companyId={parseInt(this.props.companyId, 10)}
              />
            </Tab.Pane>
          ),
        },
      ]);
    }

    return (
      <Tab panes={panes} menu={{
        stackable: true,
        attached: true,
        tabular: true,
        widths: panes.length,
      }} />
    );
  }

  render() {
    return (
      <div className="CompanyEditor">
        <MessageList messages={this.props.companyMessages} />
        {this.renderForm()}
      </div>
    );
  }
}

CompanyEditor.propTypes = {
  company: PropTypes.object,
  groups: PropTypes.array,
  companyStatus: PropTypes.string,
  companyMessages: PropTypes.array,
  users: PropTypes.array,
  companyId: PropTypes.string.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  createCompany: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  groups: state.api.groups.list.items.map(id => (state.api.groups.index[id])),
  groupsStatus: state.api.groups.status,
  company: state.api.companies.index[props.companyId],
  companyStatus: state.api.companies.status,
  companyMessages: state.api.companies.messages,
  users: state.api.users.list.items.map(id => (state.api.users.index[id])),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  goto: push,
  fetchCompany,
  createCompany,
  updateCompany,
  fetchGroups,
  updateUser,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEditor);
