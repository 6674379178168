import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Menu, Container, Loader, Dropdown, Icon } from "semantic-ui-react";
import { t } from "i18next";
import { ACCOUNT_STATUS_READY } from "../../reducers/api/account";
import PermissionsCheck, {
  ENTITY_NAMES,
  PERMISSION_VALUES,
} from "../generic/PermissionsCheck";
import logo from "../../styles/images/applylogo.svg";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = { isMenuOpen: false };
  }

  getUserName() {
    const user = this.props.currentUser;
    return user.FirstName ? `${user.FirstName} ${user.LastName}` : user.Email;
  }

  renderUserMenu(inDropdown) {
    if (this.props.accountStatus !== ACCOUNT_STATUS_READY) {
      return <Loader inverted active inline size="small" />;
    }
    if (!this.props.currentUser) {
      return (
        <NavLink to="/login" className="item">
          {t("login")}
        </NavLink>
      );
    }

    const userMenu = [
      { to: "/profile", label: "profile" },
      { to: "/change-password", label: "change_password" },
      { to: "/logout", label: "logout" },
    ];

    if (inDropdown) {
      return (
        <Dropdown item text={this.getUserName()}>
          <Dropdown.Menu>
            {userMenu.map((i) => (
              <NavLink key={i.to} to={i.to} exact className="item">
                {" "}
                {t(i.label)}
              </NavLink>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <Menu vertical fluid>
        <Menu.Header className="item">{this.getUserName()}</Menu.Header>
        <Container>
          {userMenu.map((i) => (
            <NavLink key={i.to} to={i.to} exact className="item">
              {" "}
              {t(i.label)}
            </NavLink>
          ))}
        </Container>
      </Menu>
    );
  }

  renderAdminMenu() {
    if (!this.props.currentUser) {
      return null;
    }

    return [
      <PermissionsCheck
        key="users"
        entityName={ENTITY_NAMES.USER}
        requiredPermission={PERMISSION_VALUES.VIEW}
        showUnauthorized={false}
      >
        <NavLink to="/users" key="users" className="item">
          {t("users")}
        </NavLink>
      </PermissionsCheck>,
      this.props.features.Companies && (
        <PermissionsCheck
          key="companies"
          entityName={ENTITY_NAMES.COMPANY}
          requiredPermission={PERMISSION_VALUES.MODERATE}
          showUnauthorized={false}
        >
          <NavLink to="/companies" key="companies" className="item">
            {t("companies")}
          </NavLink>
        </PermissionsCheck>
      ),
      <PermissionsCheck
        key="drawings"
        entityName={ENTITY_NAMES.DRAWING}
        requiredPermission={PERMISSION_VALUES.MODERATE}
        showUnauthorized={false}
      >
        <NavLink to="/drawings" key="drawings" className="item">
          {t("drawings")}
        </NavLink>
      </PermissionsCheck>,
      this.props.features.DrawingSystems && (
        <PermissionsCheck
          key="systems"
          entityName={ENTITY_NAMES.SYSTEM}
          requiredPermission={PERMISSION_VALUES.MODERATE}
          showUnauthorized={false}
        >
          <NavLink to="/systems" key="systems" className="item">
            {t("systems")}
          </NavLink>
        </PermissionsCheck>
      ),
    ];
  }

  render() {
    return (
      <header>
        <div className="normalHeader">
          <Menu pointing secondary fixed="top" size="huge">
            <Container>
              <NavLink to="/" exact className="item logo">
                <img src={logo} alt="Apply" />
              </NavLink>
              {this.renderAdminMenu()}
              <NavLink to="/support" key="support" className="item">
                {t("support")}
              </NavLink>
              <Menu.Menu position="right" className="user-menu">
                {this.renderUserMenu(true)}
              </Menu.Menu>
            </Container>
          </Menu>
        </div>

        <div className="mobileHeader">
          <Menu pointing secondary fixed="top" size="huge">
            <Container>
              <NavLink to="/" exact className="item logo">
                <img src={logo} alt="Apply" />
              </NavLink>
              <Menu.Item position="right" className="item menu">
                <Icon
                  name="content"
                  size="large"
                  onClick={() =>
                    this.setState({ isMenuOpen: !this.state.isMenuOpen })
                  }
                />
              </Menu.Item>
            </Container>
          </Menu>
          <Menu
            fixed="top"
            style={{
              marginTop: "52px",
              display: this.state.isMenuOpen ? "block" : "none",
            }}
          >
            {this.renderAdminMenu()}
            <NavLink to="/support" key="support" className="item">
              {t("support")}
            </NavLink>
            <Menu.Menu position="right" className="user-menu">
              {this.renderUserMenu(false)}
            </Menu.Menu>
          </Menu>
        </div>
      </header>
    );
  }
}

NavBar.propTypes = {
  currentUser: PropTypes.object,
  features: PropTypes.object,
  accountStatus: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.api.account.user,
  accountStatus: state.api.account.status,
  features: (state.api.configuration.index || {}).Features || {},
});

export default withRouter(connect(mapStateToProps)(NavBar));
