import { createAPIActions } from '../../lib/redux';

const {
  fetchListDispatcher,
  createItemDispatcher,
  updateItemDispatcher,
  deleteItemDispatcher,
} = createAPIActions('permissions');

export const fetchPermissions = fetchListDispatcher;
export const createPermission = createItemDispatcher;
export const updatePermission = updateItemDispatcher;
export const deletePermission = deleteItemDispatcher;
