import { createAPIActions } from '../../lib/redux';

const {
  fetchListDispatcher,
  fetchItemDispatcher,
  createItemDispatcher,
  updateItemDispatcher,
  deleteItemDispatcher,
} = createAPIActions('drawinglayers');

export const fetchLayers = fetchListDispatcher;
export const fetchLayer = fetchItemDispatcher;
export const updateLayer = updateItemDispatcher;
export const createLayer = createItemDispatcher;
export const deleteLayer = deleteItemDispatcher;
